<template>
    <div class="l-container-content">
        <div class="row" v-show="role==2 || role==3 " >
            <div class="col-6">
                <div class="text-title">
                    <h2 class="text-medium">Resulting</h2>
                </div>
            </div>
            <div class="col-6 text-right">

                <div class="l-input inline">
                    <i class=" search icon"></i>
                    <input v-on:keyup.enter="searchMatches" type="text" placeholder="Search..." v-model="search_match">
                </div>
                <div class="l-input inline">
                    <i class="icon-calendar"></i>
                    <input name="dates" placeholder="Choose date" />
                </div>
            </div>
        </div>
        <div class="row">
            <div id="table-wrapper" style="position:relative">
                <loader v-show='loading' />

                <button  v-if="((role == 2 || role==3))" class="ui aligned-left green button" @click="reloadResulting">Refresh Resulting</button>


                <vuetable ref="vuetable"
                      :api-url="vueurl"
                      :fields="columns"
                      :detail-row-component="detailRow"
                      :sort-order="sortOrder"
                      track-by="parent_match_id"
                      :append-params="moreParams"
                      :per-page="10"
                      detail-row-component="my-detail-row"
                      @vuetable:cell-clicked="onCellClicked"
                      @vuetable:pagination-data="onPaginationData"
                      @vuetable:loading="onLoading"
                      @vuetable:loaded="onLoaded"
            >

                <div slot="Result" slot-scope="props">
                    <button
                            class="ui green button"
                            @click="onActionClicked('view-item', props.rowData)"
                    >
                        <i class="zoom icon"></i>
                    </button>

                    <button
                            class="ui red button"
                            @click="onActionVoid('view-item', props.rowData)"
                    >
                        <i class="zoom icon"></i>
                    </button>
                </div>
            </vuetable>

            </div>

            <div class="vuetable-pagination ui basic segment grid">
                <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
                <vuetable-pagination ref="pagination"
                                     @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
            </div>

        </div>

        <modalResulting v-if="selected_data" :data="selected_data"/>

    </div>
</template>

<script>
    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import MyDetailRow from "../components/resultingModal";
    import loader from "@/components/loader"
    import modalResulting from "../components/resultingModal";
    import XLSX from 'xlsx';
    import {config} from '../config/config';

    export default {
        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo, MyDetailRow, loader,modalResulting,XLSX,config
        },
        name: 'resulting',

        data() {
            return {
                vueurl:config.baseURL+'portal/pending/matches',
                columns: [

                    // {
                    //     name: 'parent_match_id',
                    //     title: '#',
                    //     dataClass: 'center aligned',
                    //     callback: 'showDetailRow'
                    // },
                    {
                        name: 'parent_match_id',
                        title: 'MatchID',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned',
                        sortField: 'parent_match_id',
                       // callback: 'showDetailRow'
                    },
                    {
                        name: 'home_team',
                        title: 'Home',
                        sortField: 'home_team'
                    },
                    {
                        name: 'away_team',
                        title: 'Away',
                        sortField: 'away_team'
                    },
                    {
                        name: 'start_time',
                        title: 'Start Time',
                        sortField: 'start_time',
                        callback: 'createdAt'
                    },
                    {
                        name: 'sport_name',
                        title: 'Sport',
                        sortField: 'sport_name'
                    },
                    {
                        name: 'bets',
                        title: 'Bets',
                        dataClass: 'center aligned',
                        sortField: 'bets'
                    },
                    {
                        name: 'result',
                        dataClass: 'center aligned',
                        title: 'Result'
                    },

                    'Result'
                ],

                detailRow: MyDetailRow,
                loading: true,
                prevRoute: '',
                range:'',
                is_partial_resulting:false,
                search_match:'',
                loadOnStart: true,
                sortOrder: [{
                    field: 'modified',
                    direction: 'asc'
                }],
                user_id:'',
                role:'',
                token:'',
                moreParams: {
                    start: '',
                    filter: '',
                    end: '',
                    token:this.token,
                },
                sport_id: 1,
                selected_data: {},
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'glyphicon glyphicon-chevron-up',
                        descendingIcon: 'glyphicon glyphicon-chevron-down',
                        handleIcon: 'glyphicon glyphicon-menu-hamburger',
                    },
                    pagination: {
                        infoClass: 'pull-left',
                        wrapperClass: 'vuetable-pagination pull-right',
                        activeClass: 'btn-primary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                }

            }
        },




        mounted(){
            //var headerHeight = document.getElementById('header').offsetHeight;
            //document.getElementById('main-content').style.top = headerHeight + 'px';

            var vm = this;


            var obj = window.localStorage.getItem('user_info');
            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;


            vm.role=rolee;
            vm.user_id=userr;
            vm.token=jsonData.token;;

            if((vm.role  !=2 && vm.role !=3)){

                vm.$router.push({name: 'login'});
            }

            var start = moment();
            var end = moment();

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
            }



            $('input[name="dates"]').daterangepicker({
                startDate: start,
                endDate: end,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);



        },

        beforeCreate: function () {
            var vm=this;


            const itemStr = localStorage.getItem("timer")
            // if the item doesn't exist, return null
            if (!itemStr) {
                return null
            }
            const item = itemStr

            var checktimer = new Date(item);

            const now = new Date()
            // compare the expiry time of the item with the current time
            if (now.getTime() > checktimer.getTime()) {
                // If the item is expired, delete the item from storage
                // and return null
                localStorage.removeItem("timer");
                localStorage.removeItem("user_info");


                const Toast = vm.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 9000
                });
                Toast.fire({
                    type: 'error',
                    title: "Your session expired, Please login again "
                })

                vm.$router.push({name: 'login'});
                return null
            }


        },


        created: function () {
        },

        methods: {

            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");

                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },

            onLoading() {
                this.loading = true

            },
            onLoaded() {
                this.loading = false

            },

            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                vm.moreParams.start = start;
                vm.moreParams.end = end;
                vm.moreParams.token = vm.token;

                this.$refs.vuetable.reload();


            },

            searchMatches:function(){
                this.moreParams.filter = this.search_match;
                this.$refs.vuetable.reload();
            },
            getsport: function () {
                var vm = this;

                var data = {
                    parent_match_id: vm.parent_match_id,
                    token:vm.token,

                };


                $.ajax({
                    url: config.baseURLWEB+'resulting/sports',
                    type: 'POST',
                    data: JSON.stringify(data),
                    success: function (response, textStatus, jQxhr) {

                        vm.sports_info = response.data;
                    },
                    error: function (jqXhr, textStatus, error) {

                        vm.$swal("Error!", error, "error");
                    }
                });
            },

            dataManager(sortOrder, pagination) {
                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {

                    local = _.orderBy(
                        local,
                        sortOrder[0].sortField,
                        sortOrder[0].direction
                    );
                }

                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );

                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },


            showDetailRow: function (value) {

                $(".ui.dropdown").dropdown();

              //   value._showDetails = !value._showDetails;
                // var icon = this.$refs.vuetable.isVisibleDetailRow(value) ? 'minus' : 'plus';
                // return [
                //     '<a class="show-detail-row">',
                //     '<i class=" circle ' + icon + ' icon"></i>',
                //     '</a>'
                // ].join('');

                this.$refs.vuetable.toggleDetailRow(value.parent_match_id)
            },

            // onCellClicked (data) {
            //     this.$refs.vuetable.toggleDetailRow(data.parent_match_id)
            //
            // },

            onCellClicked (data, field, event) {

                this.$refs.vuetable.toggleDetailRow(data.data.parent_match_id)
            },


            onPaginationData(paginationData) {
                this.$refs.paginationInfo.setPaginationData(paginationData)
                this.$refs.pagination.setPaginationData(paginationData)
            },


            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },

            onActionClicked(action, data) {


                this.selected_data=data

                $('#modal-resulting').modal("show");//.modal({centered: false}).modal("show");
            },

            reloadResulting(action, data) {

                var vm = this;
                vm.$swal.fire({
                    title: 'Refresh Resulting/Payouts?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Refresh!'
                }).then((result) => {

                    if (result.value) {
                        var Voiddata = {
                            user_id: vm.user_id,
                            token:vm.token,
                        };

                        $.ajax({
                            url: config.baseURLWEB+'payouts',
                            type: 'post',
                            data: JSON.stringify(Voiddata),
                            success: function (response, textStatus, jQxhr) {

                                if (response.status_code == 200) {
                                    vm.$swal("Success!", "Application Restarted", "success");

                                    $('#switch-modall').modal('hide');
                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }else {

                    }
                })
            },

            onActionVoid(action, data) {

                var vm = this;

                if (action == 'view-item') {

                    vm.$swal({
                        title: "Match Voiding",
                        text: "If you are sure, type in the  Void Factor:",
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Void Match',
                        showLoaderOnConfirm: true,
                        preConfirm: (priority) => {

                            if (!priority) {
                                return;
                            }
                            var Voiddata = {
                                parent_match_id: data.parent_match_id,
                                void_factor: priority,
                                user_id: vm.user_id,
                                token:vm.token,
                            };

                            $.ajax({
                                url: config.baseURLWEB+'odibets/voiding',
                                type: 'POST',
                                data: JSON.stringify(Voiddata),
                                success: function (response, textStatus, jQxhr) {

                                    if (response.status_code == 200) {
                                        vm.$swal("Success!", response.status_description, "success");

                                        $('#switch-modall').modal('hide');
                                    } else {
                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }
                    });

                } else {


                }
            }



        },


        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.reload();
            },
            range(){

                var start = this.range[0];
                var end = this.range[1];
                start= moment(start).format('YYYY-MM-DD');
                end= moment(end).format('YYYY-MM-DD');
                this.moreParams.start = start;
                this.moreParams.end = end;
                this.moreParams.token = this.token;
                this.$refs.vuetable.reload();
            }
        },

    }

</script>