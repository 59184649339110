<template>
    <div class="l-container-content">
        <div class="row">
            <div class="col-6">
                <div class="text-title">
                    <h2 class="text-medium">Users Logs</h2>
                </div>
            </div>
            <div class="col-6 text-right">
                <div class="l-input inline">
                    <i  class=" search icon"></i>
                    <input v-on:keyup.enter="searchMatches" type="text" placeholder="Search..." v-model="search_match">
                </div>
                <div class="l-input inline">
                    <i class="icon-calendar"></i>
                    <input name="dates" placeholder="Choose date" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <loader v-show='loading' />
                <vuetable ref="vuetable"
                          :api-url="vueurl"
                          :fields="columns"
                          :sort-order="sortOrder"
                          track-by="id"
                          :append-params="moreParams"
                          :per-page="10"
                          @vuetable:pagination-data="onPaginationData"
                          @vuetable:loading="onLoading"
                          @vuetable:loaded="onLoaded"
                >
                    <div slot="status-slot" slot-scope="props">

                        <div v-if="props.rowData.status==0 && !props.rowData.description"><strong>PENDING</strong></div>
                        <div v-if="props.rowData.status === '1'"><strong style="color:#21BA45;" >Success</strong></div>
                        <div style="text-transform:uppercase;" v-if="props.rowData.status==0 && props.rowData.description" ><strong styel="color:#2185D0;">{{props.rowData.description}}</strong></div>
                    </div>
                    <div slot="actions" slot-scope="props">

                        <b>
                            <button
                                    class="ui blue button"
                                    @click="onActionClicked('edit', props.rowData)"
                            > <i class="fa fa-pencil"></i>
                            </button>

                        </b>

                        <b>
                            <button
                                    class="ui green button"
                                    @click="onActionClicked('confirm', props.rowData)"
                            >
                                <i class="fa-check-square"></i>
                            </button>

                        </b>

                    </div>
                </vuetable>
                <div class="vuetable-pagination ui basic segment grid">
                    <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

                    <vuetable-pagination ref="pagination"
                                         @vuetable-pagination:change-page="onChangePage"
                    ></vuetable-pagination>
                </div>
            </div>
        </div>
        <modalApprove />
        <markets v-if="selected_data" :data="selected_data"/>
        <reconcile v-if="deposit_data" :data="deposit_data"/>

    </div>
</template>

<script>

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import modalApprove from "../bets/modal-approve";
    import loader from "@/components/loader"
    import markets from "../modals/markets";
    import reconcile from "../modals/reconcile"
    import {config} from '../config/config';


    export default {


        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo,modalApprove,loader,markets,reconcile,config
        },
        name: 'approvals',

        props:{
            data:{
                type: Object

            }
        },

        data() {
            return {
                vueurl:config.baseURLWEB+'dash/api/log',
                loading: true,
                selected_data: {},
                deposit_data:'',
                columns: [
                    {
                        name: 'log_id',
                        title: 'ID',
                        sortField: 'log_id',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'

                    },
                    {
                        name: 'first_name',
                        title: 'First Name',
                        sortField: 'first_name',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'last_name',
                        title: 'Last Name',
                        sortField: 'last_name',
                        dataClass: ''

                    },

                    {
                        name: 'username',
                        title: 'Phone',
                        sortField: 'username',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'action',
                        title: 'Action',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: "created",
                        title: 'Date Deposited',
                        sortField: 'created',
                    }
                ],
                range:'',
                user_id:'',
                role:'',
                token:'',
                search_match:'',
                loadOnStart: true,
                sortOrder: [{
                    field: 'created',
                    direction: 'asc'
                }],
                is_partial_resulting: false,
                moreParams: {
                    start: '',
                    filter: '',
                    end: '',
                    token: ''
                }

            }
        },

        created: function(){


            console.log("created called at this point");

            var vm=this;

            vm.checktimer();


            var start = moment();
            var end = moment();


            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;

            vm.moreParams.token=vm.token;

             vm.moreParams.start=start.format('YYYY-MM-DD');
             vm.moreParams.end=end.format('YYYY-MM-DD');


            if((vm.role  !=2 && vm.role !=3)){

                vm.$router.push({name: 'login'});
            }


        },



        mounted() {

            var vm=this;

            var start = moment();
            var end = moment();


            function cb(start, end) {



                $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));


                vm.moreParams.start = start.format('YYYY-MM-DD');
                vm.moreParams.end = end.format('YYYY-MM-DD');
                vm.$refs.vuetable.reload();
            }

            $('input[name="dates"]').daterangepicker({
                startDate: start,
                endDate: end,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);

        },



        methods: {


            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");


                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },

            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;

                 vm.moreParams.start = start;
                 vm.moreParams.end = end;

            },

            searchMatches:function(){
                this.moreParams.filter = this.search_match;
                this.$refs.vuetable.reload();
            },

            createdAt: function (value) {
                if (!value) {
                    return '-';
                } else {
                    return moment(value).format('h:mm a,DD MMM YYYY');
                }

            },

            onActionClicked(action, data) {
                // $('#modal-approvals').modal({centered: false}).modal("show");
                console.log("slot actions: on-click", data);


                var vm=this;

                if (action == 'confirm') {

                    vm.$swal.fire({
                        title: 'Confirm DEPOSIT?',
                        text: "If you are sure, Proceed with Bet Confirmation:!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Confirm!'
                    }).then((result) => {
                        if (result.value) {

                            var betcanceldata = {
                                bet_id: data.bet_id	,
                                user_id: vm.user_id,
                            };
                            console.log("Payload | " + JSON.stringify(data));
                            $.ajax({
                                url: config.baseURL+'odibets/reversal',
                                type: 'POST',
                                data: JSON.stringify(betcanceldata),
                                success: function (response, textStatus, jQxhr) {
                                    console.log("Swap Matche: " + JSON.stringify(response));
                                    if (response.status_code == 200) {
                                        vm.$swal("Success!", response.status_description, "success");

                                    } else {
                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }
                    })

                }


                if (action == 'edit') {

                    vm.deposit_data=data;

                    $('.ui.modal#reconcile').modal({
                        closable: true,
                    }).modal('show');


                }


            },


            onPaginationData(paginationData) {
                this.$refs.paginationInfo.setPaginationData(paginationData)
                this.$refs.pagination.setPaginationData(paginationData)
            },


            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },


            onLoading() {
                this.loading = true
                console.log('loading... show your spinner here')
            },
            onLoaded() {
                this.loading = false
                console.log('loaded! .. hide your spinner here')
            },




        },


    }

</script>