<template>

    <div class="ui modal" id="editname">
        <i class="close icon"></i>

        <div class="header">
            Edit Profile Name
        </div>
        <div class="scrolling content">
            <div class="ui form">
                <div class="field">
                    <label>Firstname</label>
                    <div class="ui fluid input">
                        <input class="" placeholder="Enter Bonus Amount" v-model="fname">
                    </div>
                </div>

                <div class="field">
                    <label>Middle Name</label>
                    <div class="ui fluid input">
                        <input class="" placeholder="Enter Bonus Amount" v-model="mname">
                    </div>
                </div>

                <div class="field">
                    <label>Last Name</label>
                    <div class="ui fluid input">
                        <input class="" placeholder="Enter Bonus Amount" v-model="lname">
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="ui deny button">
                Cancel
            </div>
            <div  @click="awardBonus" class="ui green left labeled icon button">
                <i class="checkmark icon"></i> Edit Profile Name
            </div>
        </div>


    </div>


</template>

<script>



    import {config} from '../config/config';

    export default {

        components: {
            config
        },
        props:{
            data:{
                type: Object

            }
        },



        data() {
            return {

                fname:'',
                mname:'',
                lname:'',
                selected_bonus_type:'',
                award_data:this.data,
                selectedProfile:'',
                user_id:'',
                role:'',
                token:''

            }
        },


        watch:{
            data(newVal,oldVal){

                this.award_data = newVal;
            }
        },
        computed: {

        },

        created(){
            var vm = this;

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;
        },


        mounted() {


            this.selectedProfile=this.award_data.profile_id,
                $('.ui.modal#editname').modal({
                    closable: true
                }).modal('hide');


        },

        methods: {

            awardBonus:function () {

                var vm=this;

                if (!vm.fname || !vm.mname) {
                    vm.$swal("Missing!", "All fields are REQUIRED!", "warning");
                    return;
                }

                vm.$swal.fire({
                    title: 'Edit Name',
                    text: "If you are sure, Edit Names:!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Edit Name!'
                }).then((result) => {
                    if (result.value) {

                        var bonusData = {
                            fname: vm.fname,
                            mname: vm.mname,
                            lname: vm.lname,
                            profile_id: vm.selectedProfile,
                            user_id: vm.user_id,
                            token: vm.token,
                        };

                        $.ajax({
                            url: config.baseURL+'portal/edit/name',
                            type: 'POST',
                            data: JSON.stringify(bonusData),
                            success: function (response, textStatus, jQxhr) {
                                $('.ui.modal#editname').modal({
                                    closable: true
                                }).modal('hide');


                                if (response.status_code == 200) {
                                    vm.$swal("Success!", response.status_description, "success");

                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }
                })


            }


        },

    }
</script>