<template>
    <div class="l-main-content">
        <div class="row">
            <div class="col-6">
                <div class="text-title">
                    <h2 class="text-medium">JackPot Matches</h2>
                </div>
            </div>

            <div class="col-6 text-right">
                <div class="column">
                    <button class="ui blue button margin-left-md" >Add Matches</button>
                </div>
            </div>

        </div>

        <div class="row">
            <loader v-show='loading' />
            <vuetable ref="vuetable"
                      :api-url="vueurl"
                      :fields="columns"
                      :sort-order="sortOrder"
                      track-by="created"
                      :append-params="moreParams"
                      :per-page="31"
                      @vuetable:pagination-data="onPaginationData"
                      @vuetable:loading="onLoading"
                      @vuetable:loaded="onLoaded"
            >

                <div class="ui tiny buttons" slot="detele" slot-scope="props">

                    <button
                            class="ui green button"
                            @click="onActionDelete('view-item', props.rowData)"
                    >
                        <i class="zoom icon"></i>
                    </button>

                </div>


                <div class="ui tiny buttons" slot="Actions" slot-scope="props">

                    <button
                            class="ui green button"
                            @click="onActionVoid('view-item', props.rowData)"
                    >
                        <i class="zoom icon"></i>
                    </button>

                </div>

                <div class="ui tiny buttons" slot="detele" slot-scope="props">

                    <button
                            class="ui green button"
                            @click="onActionDelete('view-item', props.rowData)"
                    >
                        <i class="zoom icon"></i>
                    </button>

                </div>

                <div class="ui tiny buttons" slot="approve" slot-scope="props">

                    <button
                            class="ui green button"
                            @click="onActionApprove('view-item', props.rowData)"
                    >
                        <i class="zoom icon"></i>
                    </button>

                </div>



            </vuetable>
            <div class="vuetable-pagination ui basic segment grid">
                <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

                <vuetable-pagination ref="pagination"
                                     @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
            </div>
        </div>
    </div>
</template>

<style>
    td.vuetable-slot{
        overflow: visible !important;
    }
</style>

<script>

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import loader from "@/components/loader"
    import markets from "../modals/markets";
    import XLSX from 'xlsx';
    import  moment from 'moment';
    import {config} from '../config/config';



    export default {
        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo,loader,markets,XLSX,config
        },
        name: 'report',

        data() {
            return {
                vueurl:config.baseURL+'portal/jackpot/matches',

                items: [
                    { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
                    { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
                    { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
                    { age: 38, first_name: 'Jami', last_name: 'Carney' }
                ],

                loading: true,
                selected_data: {},
                columns: [

                    {
                        name: 'parent_match_id',
                        title: 'Match ID',
                        titleClass: '',
                        dataClass: '',
                    },

                    {
                        name: 'home_team',
                        title: 'Home team',
                        titleClass: '',
                        dataClass: '',
                    },
                    {
                        name: 'away_team',
                        title: 'Away Team',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'

                    },

                    {
                        name: 'start_time',
                        title: 'Start time',
                        titleClass: '',
                        dataClass: '',

                    },
                    {
                        name: 'result',
                        title: 'Result',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: 'outcome',
                        title: 'Outcome',
                        titleClass: '',
                        dataClass: ''
                    },
                    'detele',
                    'Actions',
                    'approve'

                ],
                exceldata:[],
                range:'',
                user_id:'',
                token:'',
                role:'',
                search_match:'',
                loadOnStart: true,
                sortOrder: [{
                     field: 'created',
                     direction: 'asc'
                }],
                is_partial_resulting: false,

                moreParams: {

                     filter: window.localStorage.getItem('jackpot'),
                     token: this.token,
                },

            }
        },

        created: function(){

            var vm = this;
            vm.checktimer();

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;

            if((vm.role  !=2 && vm.role !=3)){

                vm.$router.push({name: 'login'});
            }

            vm.moreParams.filter= window.localStorage.getItem('jackpot');
            vm.moreParams.token = vm.token;

        },

        ready() {
            window.addEventListener('beforeunload', this.leaving);
        },



        mounted(){
            var vm = this;

            var start = moment();
            var end = moment();

            var dateInput = $('input[name="dates"]');

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                dateInput.val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
            }

            dateInput.daterangepicker({
                startDate: start,
                endDate: end,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);


        },
        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.reload();
            },
            range(){
                var start = this.range[0];
                var end = this.range[1];
                start= moment(start).format('YYYY-MM-DD');
                end= moment(end).format('YYYY-MM-DD');
                this.moreParams.start = start;
                this.moreParams.end = end;
                this.moreParams.token = this.token;
                this.$refs.vuetable.reload();
            }
        },
        methods: {

            onActionVoid(action, data) {
                var vm = this;

                if (action == 'view-item') {
                    vm.$swal({
                        title: "Match Resulting",
                        text: "If you are sure, type in the  result:",
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Result Match',
                        showLoaderOnConfirm: true,
                        preConfirm: (priority) => {

                            if (!priority) {
                                return;
                            }
                            var Voiddata = {
                                parent_match_id: data.parent_match_id,
                                result: priority,
                                user_id: vm.user_id,
                                token: vm.token
                            };

                            $.ajax({
                                url: config.baseURLWEB+'jackpot/resulting',
                                type: 'POST',
                                data: JSON.stringify(Voiddata),
                                success: function (response, textStatus, jQxhr) {

                                    if (response.status_code == 200) {
                                        vm.$swal("Success!", response.status_description, "success");

                                        $('#switch-modall').modal('hide');
                                        window.location.reload()
                                    } else {
                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }
                    });

                } else {


                }
            },


            onActionApprove(action, data) {
                var vm = this;

                vm.$swal.fire({
                    title: 'Approve Match Resulting?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Approve Results!'
                }).then((result) => {

                    if (result.value) {
                        var Voiddata = {
                            parent_match_id: data.parent_match_id,
                            user_id: vm.user_id,
                            status: 1,
                            token: vm.token
                        };

                        $.ajax({
                            url: config.baseURL+'portal/jackpot/results/approve',
                            type: 'POST',
                            data: JSON.stringify(Voiddata),
                            success: function (response, textStatus, jQxhr) {

                                if (response.status_code == 200) {
                                    vm.$swal("Success!", response.status_description, "success");

                                    $('#switch-modall').modal('hide');
                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }else {

                    }
                })
            },

            onActionDelete(action, data) {
                var vm = this;

                vm.$swal.fire({
                    title: 'Delete Match ?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Delete Match!'
                }).then((result) => {

                    if (result.value) {
                        var Voiddata = {
                            parent_match_id: data.parent_match_id,
                            user_id: vm.user_id,
                            status: 1,
                            token: vm.token
                        };

                        $.ajax({
                            url: config.baseURL+'portal/jackpot/delete/match',
                            type: 'POST',
                            data: JSON.stringify(Voiddata),
                            success: function (response, textStatus, jQxhr) {

                                if (response.status_code == 200) {
                                    vm.$swal("Success!", response.status_description, "success");

                                    $('#switch-modall').modal('hide');
                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }else {

                    }
                })
            },
            leaving: function () {
                alert("Leaving...");
            },



            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");


                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },

            onFocus: function() {

                var vm = this;

                var start = moment();
                var end = moment();

                function cb(start, end) {
                    vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                    $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
                }

                $('input[name="dates"]').daterangepicker({
                    startDate: start,
                    endDate: end,
                    ranges: {
                        'Today': [moment(), moment()],
                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    }
                }, cb);

                cb(start, end);


            },

            createdAt: function (value) {
                if (!value) {
                    return '-';
                } else {
                    return moment(value).format('h:mm a,DD MMM YYYY');
                }
            },

            dateFilter: function (start, end) {
                var vm = this;
                vm.start = start;
                vm.end = end;

                vm.moreParams.start = start;
                vm.moreParams.end = end;
                vm.moreParams.token = vm.token;

                this.$refs.vuetable.reload();


            },
            searchMatches: function(){
                this.moreParams.filter = this.search_match;
                this.$refs.vuetable.reload();
            },
            dataManager(sortOrder, pagination) {
                if (this.data.length < 1) return;

                let local = this.data;
                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {

                    local = _.orderBy(
                        local,
                        sortOrder[0].sortField,
                        sortOrder[0].direction
                    );
                }

                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );
                console.log('pagination:', pagination)
                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },
            onPaginationData(paginationData) {
                this.$refs.paginationInfo.setPaginationData(paginationData)
                this.$refs.pagination.setPaginationData(paginationData)
            },
            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },
            onLoading() {
                this.loading = true
                console.log('loading... show your spinner here')
            },
            onLoaded() {
                this.loading = false
                console.log('loaded! .. hide your spinner here');
            },
        },
    }

</script>