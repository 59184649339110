<template>
    <div class="l-main-content" v-show="role==2 || role==3 ">
        <div   class="container">
            <div class="row bg-white bg-shadow margin-bottom-md padding-top-md">
                <div class="col">
                    <div id="reportrange" class="left aligned column" style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ddd; width: 100%; border-radius: 4px;">
                        <i class="fa fa-calendar" style="margin-right: .75em;"></i>
                        <span></span> <i class="fa fa-caret-down" style="float: right"></i>
                    </div>
                    <!--<h6><strong>Previous period</strong>: 9 May 21</h6>-->
                </div>

                <div v-show="user_id==85 || user_id==17  || user_id==18 ||user_id==25 ||user_id==26">
                    <div class="rsum">
                        <div class="sum green">
                            <loader v-show='loading1' />
                            <div class="h">
                                <div class="l">Deposits</div>
                            </div>

                            <div class="t">{{deposit}}</div>
                            <!--<div class="s">+ 0.00%</div>-->
                        </div>
                        <div class="sum red">
                            <div class="h">
                                <div class="l">Withdrawals</div>
                                <!--<div class="r">- 2.01%</div>-->
                            </div>
                            <div class="t">{{withdraw}}</div>
                            <!--<div class="s">+ 0.00%</div>-->
                        </div>
                    </div>
                    <div class="rsum">
                        <div class="sum sum-3 blue">
                            <div class="h">
                                <div class="l">Stake</div>
                                <!--<div class="r">- 2.01%</div>-->
                            </div>
                            <div class="t">{{stake}}</div>
                            <!--<div class="s">+ 0.00%</div>-->
                        </div>
                        <div class="sum sum-3 yellow">
                            <div class="h">
                                <div class="l">Winnings</div>
                                <!--<div class="r">- 2.01%</div>-->
                            </div>
                            <div class="t">{{winnings}}</div>
                            <!--<div class="s">+ 0.00%</div>-->
                        </div>
                        <div class="sum sum-3 teal">
                            <div class="h">
                                <div class="l">P&L</div>
                                <!--<div class="r">- 2.01%</div>-->
                            </div>
                            <div class="t">{{pnl}}</div>
                            <!--<div class="s">+ 0.00%</div>-->
                        </div>
                    </div>
                </div>

                <div class="padding-sm"></div>
                <div class="l-tabs">
                    <ul>
                        <li :class="{'selected': selectedTab1 == 'real'}" @click="selectedTab1 = 'real'">Real Bets</li>
                        <li :class="{'selected': selectedTab1 == 'kiron'}" @click="selectedTab1 = 'kiron'">Kiron</li>
                    </ul>
                </div>
                <div v-show="selectedTab1 == 'real'" class="col">
                    <div class="overflow">
                        <table class="table simple">
                            <tbody>
                            <tr class="sm">
                                <td class="text-grey text-left">Real Bets</td>
                                <td class="text-grey text-right">{{bets}}</td>
                                <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                            </tr>

                            <tr class="sm">
                                <td class="text-grey text-left">Stake</td>
                                <td class="text-grey text-right">GHS {{stake}}</td>
                                <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Winnings</td>
                                <td class="text-grey text-right">GHS {{winnings}}</td>
                                <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">P&L</td>
                                <td class="text-grey text-right">GHS {{pnl}}</td>
                                <!--<td class="long text-medium text-right"><div class="ui red tiny label">- 0.00%</div></td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Resulted Bets</td>
                                <td class="text-grey text-right">{{percent}}%</td>
                                <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Unsettled Bets</td>
                                <td class="text-grey text-right">{{pending}}</td>
                                <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">SMS Bets</td>
                                <td class="text-grey text-right">{{sms_bets}}</td>
                                <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-show="selectedTab1 == 'kiron'" class="col">
                    <div class="overflow">
                        <table class="table simple">
                            <tbody>
                            <tr class="sm">
                                <td class="text-grey text-left">Total Bets</td>
                                <td class="text-grey text-right">{{virtual_bet}}</td>
                                <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Stake</td>
                                <td class="text-grey text-right">GHS {{virtual_stake}}</td>
                                <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Winnings</td>
                                <td class="text-grey text-right">GHS {{virtual_winnings}}</td>
                                <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">P&L</td>
                                <td class="text-grey text-right">GHS {{virtual_pnl}}</td>
                                <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Unsettled Bets</td>
                                <td class="text-grey text-right">0</td>
                                <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="row bg-white bg-shadow margin-bottom-md">
                <div class="l-tabs">
                    <ul>
                        <li :class="{'selected': selectedTab2 == 'liabilities'}" @click="selectedTab2 = 'liabilities'">Liabilities</li>
                        <li :class="{'selected': selectedTab2 == 'balance'}" @click="selectedTab2 = 'balance'">Balance</li>
                    </ul>
                </div>
                <div v-show="selectedTab2 == 'liabilities'" class="col">
                    <div class="overflow">
                        <table class="table simple">
                            <tbody>
                            <tr class="sm">
                                <td class="text-grey text-left">Total Liabilities</td>
                                <td class="text-grey text-right">GHS {{balance}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Active</td>
                                <td class="text-grey text-right">GHS {{active}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Last Month</td>
                                <td class="text-grey text-right">GHS {{last_3}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">3 Months</td>
                                <td class="text-grey text-right">GHS {{last_5}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">1 Year</td>
                                <td class="text-grey text-right">GHS {{last_year}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-show="selectedTab2 == 'balance'" class="col">
                    <div class="overflow">
                        <table class="table simple">
                            <tbody>
                            <tr class="sm">
                                <td class="text-grey text-left">100 -1000</td>
                                <td class="text-grey text-right">{{balance1}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">1,001-10,000</td>
                                <td class="text-grey text-right">{{balance2}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">10,001-100,000</td>
                                <td class="text-grey text-right">{{balance3}}</td>
                            </tr>

                            <tr class="sm">
                                <td class="text-grey text-left">70,000+</td>
                                <td class="text-grey text-right">{{balance5}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">100,000+</td>
                                <td class="text-grey text-right">{{balance4}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">1,000,000+</td>
                                <td class="text-grey text-right">{{balance6}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <div class="row bg-white bg-shadow margin-bottom-md">
                <div class="col padding-top-md padding-bottom-sm">
                    <h3 class="text-medium">Acquisition</h3>
                    <small class="text-grey-light">Today, 14 September 2020</small>
                </div>
                <div class="col-6">
                    <h5 class="text-grey">Signups</h5>
                    <h3 class="text-green text-medium">{{acquisition}}</h3>
                </div>
                <div class="col-6 border-left">
                    <h5 class="text-grey">Inactives</h5>
                    <h3 class="text-medium text-grey-light text-red">{{dropoffs}}</h3>
                </div>
                <div class="overflow padding-top-sm">
                    <div class="tabular">
                        <loader v-show='loading' />
                        <vuetable ref="vuetable"
                                  :api-url="vueurl"
                                  :fields="columns"
                                  :multi-sort="true"
                                  :append-params="moreParams"
                                  :sort-order="sortOrder"
                                  :per-page="10"
                                  @vuetable:pagination-data="onPaginationData"
                                  @vuetable:loading="onLoading"
                                  @vuetable:loaded="onLoaded">
                        </vuetable>

                        <div class="vuetable-pagination ui basic segment grid">
                            <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

                            <vuetable-pagination ref="pagination"
                                                 @vuetable-pagination:change-page="onChangePage"
                            ></vuetable-pagination>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import Chart from "chart.js";
    import $ from  "jquery";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import loader from "@/components/loader"
    import {config} from '../config/config';

    function formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    export default {


        data(){
            return{
                vueurl:config.baseURLGhana+'dash/api/attribution',
                selectedTab1: 'real',
                selectedTab2: 'liabilities',
                summary: [],
                liability: [],
                liabilityy: [],
                liability1: [],
                liability2: [],
                liability3: [],
                liability4: [],
                liability5: [],
                liability6: [],
                dashtop_loader: '',
                deposit: 0,
                range:'',
                vuetable: '',
                depositors: 0,
                withdraw: 0,
                withdrawals: 0,
                bets: 0,
                virtual_bet: 0,
                kiron_bet: 0,
                pnl: 0,
                virtual_pnl: 0,
                kiron_pnl: 0,
                stake: 0,
                user: 0,
                acquisition: 0,
                dropoffs: 0,
                winnings: 0,
                virtual_stake: 0,
                kiron_stake: 0,
                virtual_winnings: 0,
                kiron_winnings: 0,
                balance: 0,
                pending: 0,
                percent: 0,
                active: 0,
                last_3: 0,
                last_5: 0,
                numbers: 0,
                last_year: 0,
                total1: 0,
                balance1: 0,
                sms_bets: 0,

                total2: 0,
                balance2: 0,
                loading: true,
                loading1: true,

                total3: 0,
                balance3: 0,

                total4: 0,
                balance4: 0,

                total5: 0,
                balance5: 0,

                total6: 0,
                balance6: 0,



                user_id: '',
                role: '',

                columns: [
                    {
                        name: 'name',
                        title: 'Attribution',
                    },
                    {
                        name: 'count',
                        title: 'Count',
                        sortField: 'count',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'
                    },
                ],
                moreParams: {
                    filter: '',
                    start: '',
                    end: '',

                },
                sortOrder: [{
                    field: 'count',
                    direction: 'desc'
                }],
            }
        },
        components: { DatePicker,Vuetable, VuetablePagination, VuetablePaginationInfo,loader ,config},


        mounted() {

            var vm = this;

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role
            var userr=jsonData.id

            vm.role=rolee;
            vm.user_id=userr;




            if((vm.role  !=2 && vm.role !=3)){

                vm.$router.push({name: 'login'});
            }

            var start = moment();
            var end = moment();

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                $('#reportrange span').html(start.format('D MMM, YYYY') + ' - ' + end.format('D MMM, YYYY'));
            }

            $('#reportrange').daterangepicker({
                startDate: start,
                endDate: end,
                autoApply:true,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);

        },

        beforeCreate: function () {
            var vm=this;
            const itemStr = localStorage.getItem("timer")
            // if the item doesn't exist, return null
            if (!itemStr) {
                return null
            }
            const item = itemStr

            var checktimer = new Date(item);

            const now = new Date()
            // compare the expiry time of the item with the current time
            if (now.getTime() > checktimer.getTime()) {
                // If the item is expired, delete the item from storage
                // and return null
                localStorage.removeItem("timer");
                localStorage.removeItem("user_info");


                const Toast = vm.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 9000
                });
                Toast.fire({
                    type: 'error',
                    title: "Your session expired, Please login again "
                })

                vm.$router.push({name: 'login'});
                return null
            }


        },


        created(){
            var vm=this;


            if (performance.navigation.type == 1) {
                if (this.$route.path == '/page3') {
                    if (confirm('Reload site?. Change you made may not be saved.')) {
                        vm.checktimer();
                    } else {

                    }
                }

            }

            vm.checktimer();

            vm.getliability();
            vm.getliabilitysta();
            vm.getliability1();
            vm.getliability2();
            vm.getliability3();
            vm.getliability4();
            vm.getliability5();
            vm.getliability6();

        },


        methods:{


            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");


                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },

            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                vm.moreParams.start = start;
                vm.moreParams.end = end;

                vm.checktimer();
                vm.getsummaryData(start,end);

                this.$refs.vuetable.reload();


            },


            getsummaryData: function (start,end) {


                var vm = this;

                this.loading1=true;


                $.get({
                    url: config.baseURLGhana+'dash/api/dash?start='+start+'&end='+end,
                    type: "GET",
                    success: function (response) {

                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.deposit=formatNumber(json[key].deposit);
                                vm.depositors=formatNumber(json[key].depositors);
                                vm.withdraw=formatNumber(json[key].withdraw);
                                vm.withdrawals=formatNumber(json[key].withdrawals);
                                vm.bets=formatNumber(json[key].bets);
                                vm.virtual_bet=formatNumber(json[key].virtual_bet);
                                vm.kiron_bet=formatNumber(json[key].kiron_bet);
                                vm.pnl=formatNumber(json[key].pnl);
                                vm.virtual_pnl=formatNumber(json[key].virtual_pnl);
                                vm.kiron_pnl=formatNumber(json[key].kiron_pnl);
                                vm.stake=formatNumber(json[key].stake);
                                vm.virtual_stake=formatNumber(json[key].virtual_stake);
                                vm.kiron_stake=formatNumber(json[key].kiron_stake);
                                vm.acquisition=formatNumber(json[key].acquisition);
                                vm.dropoffs=formatNumber(json[key].dropoffs);
                                vm.winnings=formatNumber(json[key].winnings);
                                vm.virtual_winnings=formatNumber(json[key].virtual_winnings);
                                vm.kiron_winnings=formatNumber(json[key].kiron_winnings);
                                vm.pending=formatNumber(json[key].pending);
                                vm.sms_bets=formatNumber(json[key].sms_bets);
                                let b = Math.floor(json[key].bets); //floor aut
                                let p = Math.floor(json[key].pending); //floor aut
                                let r= b-p;

                                vm.percent= Math.round(r/b * 100);


                                this.loading1=false;

                            }
                        }


                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },


            getliability: function () {
                var vm = this;

                $.get({
                    url: config.baseURLGhana+'dash/api/total/liability',
                    type: "GET",
                    success: function (response) {

                        vm.liability= response;


                        var json=vm.liability

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.balance=formatNumber(json[key].balance);

                            }
                        }

                    },
                    error: function () {


                    }
                })

            },


            getliabilitysta: function () {
                var vm = this;


                $.get({
                    url: config.baseURLGhana+'dash/api/liability',
                    type: "GET",
                    success: function (response) {
                        vm.liabilityy= response;


                        var json=vm.liabilityy

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.active=formatNumber(json[key].active);
                                vm.last_3=formatNumber(json[key].last_3);
                                vm.last_5=formatNumber(json[key].last_5);
                                vm.last_year=formatNumber(json[key].last_year);

                            }
                        }
                    },
                    error: function () {


                    }
                })

            },


            getliability1: function () {
                var vm = this;

                $.get({
                    url: config.baseURLGhana+'dash/api/liability1',
                    type: "GET",
                    success: function (response) {

                        vm.liability1= response;


                        var json=vm.liability1

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.total1=formatNumber(json[key].total);
                                vm.balance1=formatNumber(json[key].numbers);


                            }
                        }
                    },
                    error: function () {


                    }
                })

            },


            getliability2: function () {
                var vm = this;

                $.get({
                    url: config.baseURLGhana+'dash/api/liability2',
                    type: "GET",
                    success: function (response) {


                        vm.liability2= response;


                        var json=vm.liability2

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.total2=formatNumber(json[key].total);
                                vm.balance2=formatNumber(json[key].numbers);


                            }
                        }

                    },
                    error: function () {


                    }
                })

            },


            getliability3: function () {
                var vm = this;

                $.get({
                    url: config.baseURLGhana+'dash/api/liability3',
                    type: "GET",
                    success: function (response) {


                        vm.liability3= response;


                        var json=vm.liability3

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.total3=formatNumber(json[key].total);
                                vm.balance3=formatNumber(json[key].numbers);


                            }
                        }

                    },
                    error: function () {


                    }
                })

            },


            getliability4: function () {
                var vm = this;

                $.get({
                    url: config.baseURLGhana+'dash/api/liability4',
                    type: "GET",
                    success: function (response) {


                        vm.liability4= response;


                        var json=vm.liability4

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.total4=formatNumber(json[key].total);
                                vm.balance4=formatNumber(json[key].numbers);


                            }
                        }

                    },
                    error: function () {


                    }
                })

            },


            getliability5: function () {
                var vm = this;
                $.get({
                    url: config.baseURLGhana+'dash/api/liability5',
                    type: "GET",
                    success: function (response) {
                        vm.liability5= response;


                        var json=vm.liability5

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.total5=formatNumber(json[key].total);
                                vm.balance5=formatNumber(json[key].numbers);


                            }
                        }

                    },
                    error: function () {


                    }
                })

            },


            getliability6: function () {
                var vm = this;
                $.get({
                    url: config.baseURLGhana+'dash/api/liability6',
                    type: "GET",
                    success: function (response) {
                        vm.liability6= response;


                        var json=vm.liability6

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.total6=formatNumber(json[key].total);
                                vm.balance6=formatNumber(json[key].numbers);


                            }
                        }

                    },
                    error: function () {


                    }
                })

            },

            onPaginationData(paginationData) {
                this.$refs.pagination.setPaginationData(paginationData);
                this.$refs.paginationInfo.setPaginationData(paginationData);

            },
            onChangePage(page) {
                this.$refs.vuetable.changePage(page)

            },
            onLoading() {
                this.loading = true

            },
            onLoaded() {
                this.loading = false

            },

        },


        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.reload();
            },
            range(){

                var start = this.range[0];
                var end = this.range[1];
                start= moment(start).format('YYYY-MM-DD');
                end= moment(end).format('YYYY-MM-DD');
                this.moreParams.start = start;
                this.moreParams.end = end;
                this.getsummaryData(start,end);


            },
        },




    }
</script>