<template>
    <div class="l-container-content">
        <div class="row">
            <div class="col-6">
                <div class="text-title">
                    <h2 class="text-medium">Withdrawals</h2>
                </div>
            </div>
            <div class="col-6 text-right">
                <div class="l-input inline">
                    <i  class=" search icon"></i>
                    <input v-on:keyup.enter="searchMatches" type="text" placeholder="Search..." v-model="search_match">
                </div>
                <div class="l-input inline">
                    <i class="icon-calendar"></i>
                    <input name="dates" placeholder="Choose date" />
                </div>

            </div>


            <button  @click="uploadwithdraw" class="ui red button margin-left-md"><i class="fa fa-upload"></i></button>
        </div>

        <div class="row">
            <div class="col">
                <loader v-show='loading' />
                <vuetable ref="vuetable"
                          :api-url="vueurl"
                          :fields="columns"
                          :sort-order="sortOrder"
                          track-by="match_id"
                          :append-params="moreParams"
                          :per-page="15"
                          @vuetable:pagination-data="onPaginationData"
                          @vuetable:loading="onLoading"
                          @vuetable:loaded="onLoaded"
                ></vuetable>
                <div class="vuetable-pagination ui basic segment grid">
                    <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

                    <vuetable-pagination ref="pagination"
                                         @vuetable-pagination:change-page="onChangePage"
                    ></vuetable-pagination>
                </div>
            </div>
        </div>
        <modalApprove />
        <markets v-if="selected_data" :data="selected_data"/>
        <wupload  />
    </div>
</template>
<script>

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import modalApprove from "../bets/modal-approve";
    import loader from "@/components/loader"
    import markets from "../modals/markets";
    import {config} from '../config/config';
    import wupload from "../modals/uploadwithdraw"

    export default {


        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo,modalApprove,loader,markets,wupload
        },
        name: 'approvals',

        data() {
            return {
                vueurl:config.baseURLWEB+'dash/api/withdraw',
                loading: true,
                selected_data: {},
                columns: [
                    {
                        name: 'withdraw_reference',
                        title: 'withdraw_reference',
                        sortField: 'withdraw_reference',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'status_desc',
                        title: 'status_desc',
                        sortField: 'status_desc',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'amount',
                        title: 'amount',
                        sortField: 'amount',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'description',
                        title: 'description',
                        sortField: 'description',
                        titleClass: '',
                        dataClass: ''
                    }
                ],
                range:'',
                user_id:'',
                role:'',
                token:'',
                search_match:'',
                loadOnStart: true,
                sortOrder: [{
                    field: 'w.created',
                    direction: 'asc'
                }],
                is_partial_resulting: false,
                moreParams: {
                    start: '',
                    filter: '',
                    end: '',
                    token: ''
                }

            }
        },

        created: function(){

            var vm=this;


            vm.checktimer();

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;

            if((vm.role  !=2 && vm.role !=3 && vm.role !=5)){

                vm.$router.push({name: 'login'});
            }

            var start = moment();
            var end = moment();

            vm.moreParams.start=start.format('YYYY-MM-DD');
            vm.moreParams.end=end.format('YYYY-MM-DD');
            vm.moreParams.token=vm.token;


        },


        mounted() {


            // var vm=this;
            //
            // var start = moment();
            // var end = moment();
            //
            // function cb(start, end) {
            //     vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
            //     $('#reportrange span').html(start.format('D MMM, YYYY') + ' - ' + end.format('D MMM, YYYY'));
            // }
            //
            // $('#reportrange').daterangepicker({
            //     startDate: start,
            //     endDate: end,
            //     autoApply:true,
            //     ranges: {
            //         'Today': [moment(), moment()],
            //         'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            //         'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            //         'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            //         'This Month': [moment().startOf('month'), moment().endOf('month')],
            //         'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            //     }
            // }, cb);
            //
            // cb(start, end);

        },




        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.reload();
            },
            range(){

                var start = this.range[0];
                var end = this.range[1];
                start= moment(start).format('YYYY-MM-DD');
                end= moment(end).format('YYYY-MM-DD');
                this.moreParams.start = start;
                this.moreParams.end = end;
                this.$refs.vuetable.reload();
            }
        },


        methods: {

            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");


                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },


            uploadwithdraw(){

                var vm=this;

                var vm = this;

                if (vm.upload_loader) {
                    return;
                }

                var jsondata = {
                    mpesa_code: vm.mpesa_code,
                    deposit_amount: vm.mpesa_amt,
                    status_description: vm.description,
                };

                // vm.upload_loader = 'loading';

                vm.deposit_mpesa=jsondata;


                $('.ui.modal#wupload').modal({
                    closable: true,
                }).modal('show');

            },


            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                vm.moreParams.start = start;
                vm.moreParams.end = end;
                vm.moreParams.end = vm.token;

                this.$refs.vuetable.reload();

                console.log("start date filter",start)
                console.log("end date filter ",end)
            },

            searchMatches:function(){
                this.moreParams.filter = this.search_match;
                this.$refs.vuetable.reload();
            },

            dataManager(sortOrder, pagination) {
                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {
                    console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                    local = _.orderBy(
                        local,
                        sortOrder[0].sortField,
                        sortOrder[0].direction
                    );
                }

                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );
                console.log('pagination:', pagination)
                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },


            onPaginationData(paginationData) {
                this.$refs.paginationInfo.setPaginationData(paginationData)
                this.$refs.pagination.setPaginationData(paginationData)
            },


            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },


            onLoading() {
                this.loading = true
                console.log('loading... show your spinner here')
            },
            onLoaded() {
                this.loading = false
                console.log('loaded! .. hide your spinner here')
            },




        },


    }

</script>