<template>
    <div class="l-main">
        <div class="l-main-content">
            <div class="row p-left p-right p-top">
                <div class="col-max">
                    <div class="m-input-group">
                        <i class="search icon"></i>
                        <div class="m-input-group-input">
                            <input v-on:keyup.enter="searchProfile" type="text" placeholder="Enter phone number..." v-model="search_profile">
                        </div>
                        <button class="bg-primary" @click="searchProfile">Search</button>
                        <!--<button class="" @click="clearProfileData">Clear</button>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="l-main-content padded" v-show="profile_data">
            <div class="row">
                <div class="card bg-white padding-bottom-sm">
                    <div class="row vertical-middle">
                        <div class="col-6 vertical-middle">
                            <h3><b>{{profile_data.fullName}}</b> </h3>
                            <h1>{{profile_data.msisdn}}</h1>
                            <div class="row padding-top-md margin-top-md border-top">
                                <div class="col-6">
                                    <h4>Balance</h4>
                                    <h3><strong>Ksh. {{profile_data.balance}}</strong></h3>
                                </div>
                                <div class="col-6 padding-top-md">
                                    <div class="row vertical-middle padding-top-none">
                                        <div class="col-6 vertical-middle border-left padding-left-md">
                                            <h4>Bonus</h4>
                                            <h4><strong>{{profile_data.bonus}}</strong></h4>
                                        </div>
                                        <div class="col-6 vertical-middle border-left padding-left-md">
                                            <h4>Points</h4>
                                            <h4><strong>{{profile_data.points}}</strong></h4>
                                        </div>
                                        <!--<div class="col-4 vertical-middle border-left padding-left-md">-->
                                            <!--<h4>Bets</h4>-->
                                            <!--<h4><strong>{{profile_data.betCount}}</strong></h4>-->
                                        <!--</div>-->
                                    </div>
                                </div>
                            </div>
                            <button    v-if="user_id == 1  ||  user_id == 18 ||  user_id == 28 || user_id == 21 ||  user_id == 40  || user_id==63 || user_id==37 || user_id==57 || user_id==48 || user_id==108"  @click="DeductAccout"   class="ui tiny red button margin-right-md">Deduct Account</button>
                            <button     @click="RaiseTicket"   class="ui tiny green button margin-right-md">Raise Ticket</button>
                        </div>
                        <div class="col-6 border-left padding-left-md">
                            <table class="table clean simple">
                                <tbody>
                                    <tr>
                                        <td>Deposits</td>
                                        <td class="text-right">Ksh. {{profile_data.deposit}}</td>
                                    </tr>

                                    <tr>
                                        <td>Bets</td>
                                        <td class="text-right">{{profile_data.betCount}}</td>
                                    </tr>
                                    <tr>
                                        <td>Stake</td>
                                        <td class="text-right">Ksh. {{profile_data.stake}}</td>
                                    </tr>
                                    <tr>
                                        <td>Winnings</td>
                                        <td class="text-right">Ksh. {{profile_data.amount}}</td>
                                    </tr>
                                    <tr>
                                        <td>Withdrawals</td>
                                        <td class="text-right">Ksh. {{profile_data.withdraw}}</td>
                                    </tr>
                                    <tr>
                                        <td>Registration Date</td>
                                        <td class="text-right">{{profile_data.created}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row border-top">
                        <div class="col-6 padding-top-md">
                            <strong v-if="profile_data.blacklistt == 1 "   class="text-red">Blacklisted ( {{profile_data.reason}} ) </strong>
                            <strong  v-if="profile_data.blacklistt == 0 "   class="text-green">Active</strong>
                        </div>
                        <div class="col-6 text-right">
                            <!--<button  @click="blacklistAccout" v-if="profile_data.blacklistt == 0 && (user_id == 18 || user_id == 28 || user_id == 21 ||  user_id == 40 || user_id == 37 ||  user_id == 46 || user_id==63)" class="ui tiny red button margin-right-md">BLACKLIST ACCOUNT</button>-->
                            <button  @click="blastlistAccout('blacklist',profile_data)" v-if="profile_data.blacklistt == 0 && (user_id == 18 || user_id == 28 || user_id == 21 ||  user_id == 40 || user_id == 37 ||  user_id == 46 || user_id==63 || user_id==57 || user_id==108 || user_id==48)" class="ui tiny red button margin-right-md">BLACKLIST ACCOUNT</button>
                            <button   @click="activateAccout" v-if="profile_data.blacklistt == 1 && (user_id == 18 || user_id == 28 || user_id == 21 ||  user_id == 40 || user_id == 37 ||  user_id == 46 || user_id==63 || user_id==108 || user_id==57  || user_id==48) "  class="ui tiny blue button margin-right-md">ACTIVATE ACCOUNT</button>
                            <button v-if="role == 18 || user_id == 28 || user_id == 21 ||  user_id == 40  || user_id==63 || user_id==108 || user_id==48 " @click="award()" style="margin-top: 10px;" class="ui tiny green button refund-punter">AWARD BONUS</button>
                            <button   @click="editname" v-if="(user_id == 18 || user_id == 28 || user_id == 21 ||  user_id == 40 || user_id == 37 ||  user_id == 46 || user_id==63 || user_id==108 || user_id==48 || user_id == 57)"  class="ui tiny blue button margin-right-md">Edit Name</button>
                            <!--<button v-if="user_id == 18 || user_id == 28 || user_id == 21 ||  user_id == 40 ||  user_id == 37 ||  user_id == 46" @click="bonusaward()" style="margin-top: 10px;" class="ui tiny green button refund-punter">AWARD BONUS</button>-->
                            <button    @click="resendCode"   class="ui tiny blue button margin-right-md">Resend Code</button>
                        </div>
                    </div>
                    </div>
            </div>
        </div>

        <!--<loader v-show="!profile_data" />-->
        <div v-if="profile_data">
            <div class="l-tabs" >
                <ul class="ui tabular basic menu">
                    <li class="item active" data-tab="default">Bets</li>
                    <li class="item" data-tab="instant">Instant Bets</li>
                    <li class="item" data-tab="bets">Jackpot Bets</li>
                    <li class="item hidden" data-tab="virtual-bets">Virtual Bets</li>
                    <li class="item" data-tab="kiron-bets">Kiron Bets</li>
                    <li class="item" data-tab="transactions">Transactions</li>
                    <li class="item" data-tab="transactions1">Old Transactions</li>
                    <li class="item" data-tab="deposit">Deposits</li>
                    <li class="item" data-tab="withdraws">Withdrawals</li>
                    <li class="item" data-tab="withdraws1"> Tickets</li>


                </ul>
            </div>
            <div class="l-main-content padded" >
                <div class="ui  tab active " data-tab="default" >
                    <div class="ui middle aligned grid">
                        <div class="eight wide column">
                            <h2>New Bets</h2>
                        </div>
                        <div class="eight wide right aligned column" v-show="profile">
                            <div class="ui icon input">
                                <input v-on:keyup.enter="searchBets" type='text' placeholder="Search..." v-model="search_bet"/>
                                <i @click="searchBets" class="icon link search"></i>
                            </div>
                        </div>
                    </div>

                    <div class="p-bottom"></div>
                    <div class="sixteen wide column">

                        <div class="tabular">
                            <loader v-show='loading' />
                            <vuetable ref="vuetable"
                                      :api-url="defaulturl"
                                      :fields="columns"
                                      track-by="msisdn"
                                      :first-page="0"
                                      :multi-sort="true"
                                      :append-params="moreParams"
                                      :sort-order="sortOrder"
                                      :per-page="10"
                                      :load-on-start="true"
                                      @vuetable:data-manager="dataManager"
                                      @vuetable:pagination-data="onPaginationData"
                                      @vuetable:loading="onLoading"
                                      @vuetable:loaded="onLoaded">

                                <template slot="actions" scope="props">
                                    <div class="custom-actions">
                                        <div>
                                            <div style="max-width:200px;">
                                                <div @click="itemAction(props.rowData)" style="cursor:pointer;color:blue;"><strong>#{{props.rowData.bet_reference}}</strong></div>
                                                <span style="color:black;">{{props.rowData.total_games}} game(s)</span>
                                            </div>
                                        </div>

                                    </div>
                                </template>


                                <div slot="status-slot" slot-scope="props">

                                    <div v-if="props.rowData.status === '3' "><strong style="color:#DB2828;">LOST</strong></div>
                                    <div  v-if="props.rowData.status === '1'"><strong>PENDING</strong></div>
                                    <div  v-if="props.rowData.status === '8'"><strong>Awaiting Approval</strong></div>
                                    <div v-if="props.rowData.status === '5' || props.rowData.status === '2'"><strong style="color:#21BA45;">WON</strong></div>
                                    <div v-if="props.rowData.status === '6'"><strong style="color:#21BA45;">CASHOUT</strong></div>
                                    <div v-if="props.rowData.status === '4' || props.rowData.status === '24'" ><strong styel="color:#2185D0;">CANCELLED</strong></div>
                                    <div v-if="props.rowData.status === '19' || props.rowData.status === '22'" ><strong style="color:#ff0000 ;">SPAMMING</strong></div>
                                </div>


                                <div slot="action" slot-scope="props">

                                    <b>
                                        <button
                                                class="ui small button"
                                                @click="raiseTicketAction('bet-cancel', props.rowData)"
                                        >
                                            <i class=" red ticket icon"></i>
                                        </button>
                                    </b>
                                </div>
                            </vuetable>


                            <div class="vuetable-pagination ui basic segment grid">
                                <vuetable-pagination-info ref="paginationInfo"
                                ></vuetable-pagination-info>
                                <vuetable-pagination ref="pagination"
                                                     @vuetable-pagination:change-page="onChangePage"
                                ></vuetable-pagination>
                            </div>
                        </div>
                    </div>
                </div>
              <div class="ui  tab  " data-tab="instant" >
                <div class="ui middle aligned grid">
                  <div class="eight wide column">
                    <h2>Instant Bets</h2>
                  </div>
                  <div class="eight wide right aligned column" v-show="profile">
                    <div class="ui icon input">
                      <input v-on:keyup.enter="searchInstantBets" type='text' placeholder="Search..." v-model="search_instant"/>
                      <i @click="searchInstantBets" class="icon link search"></i>
                    </div>
                  </div>
                </div>

                <div class="p-bottom"></div>
                <div class="sixteen wide column">

                  <div class="tabular">
                    <loader v-show='loadingI' />
                    <vuetable ref="instant"
                              :api-url="instanturl"
                              :fields="instantcolumnss"
                              track-by="msisdn"
                              :first-page="0"
                              :multi-sort="true"
                              :append-params="instantParams"
                              :sort-order="sortOrder"
                              :per-page="10"
                              :load-on-start="true"
                              @vuetable:data-manager="dataManager"
                              @vuetable:pagination-data="onPaginationDataInstant"
                              @vuetable:loading="onLoadingI"
                              @vuetable:loaded="onLoadedI">

                      <template slot="actions" scope="props">
                        <div class="custom-actions">
                          <div>
                            <div style="max-width:200px;">
                              <div @click="instantBetslipAction(props.rowData)" style="cursor:pointer;color:blue;"><strong>#{{props.rowData.bet_reference}}</strong></div>
                              <span style="color:black;">{{props.rowData.total_games}} game(s)</span>
                            </div>
                          </div>

                        </div>
                      </template>


                      <div slot="status-slot" slot-scope="props">

                        <div v-if="props.rowData.status === '3' "><strong style="color:#DB2828;">LOST</strong></div>
                        <div  v-if="props.rowData.status === '1'"><strong>PENDING</strong></div>
                        <div  v-if="props.rowData.status === '8'"><strong>Awaiting Approval</strong></div>
                        <div v-if="props.rowData.status === '5' || props.rowData.status === '2'"><strong style="color:#21BA45;">WON</strong></div>
                        <div v-if="props.rowData.status === '6'"><strong style="color:#21BA45;">CASHOUT</strong></div>
                        <div v-if="props.rowData.status === '4' || props.rowData.status === '24'" ><strong styel="color:#2185D0;">CANCELLED</strong></div>
                        <div v-if="props.rowData.status === '19' || props.rowData.status === '22'" ><strong style="color:#ff0000 ;">SPAMMING</strong></div>
                      </div>


                      <div slot="action" slot-scope="props">

                        <b>
                          <button
                              class="ui small button"
                              @click="raiseTicketAction('bet-cancel', props.rowData)"
                          >
                            <i class=" red ticket icon"></i>
                          </button>
                        </b>
                      </div>
                    </vuetable>


                    <div class="vuetable-pagination ui basic segment grid">
                      <vuetable-pagination-info ref="paginationInfoInstant"
                      ></vuetable-pagination-info>
                      <vuetable-pagination ref="paginationInstant"
                                           @vuetable-pagination:change-page="onChangePageInstant"
                      ></vuetable-pagination>
                    </div>
                  </div>
                </div>
              </div>
                <div class="ui  tab  " data-tab="bets">
                    <div class="ui middle aligned grid">
                        <div class="eight wide column">
                            <h2>Jackpot Bets </h2>
                        </div>
                        <div class="eight wide right aligned column" v-show="profile">
                            <div class="ui icon input">
                                <input v-on:keyup.enter="searchBetss" type='text' placeholder="Search..." v-model="search_bets"/>
                                <i @click="searchBetss" class="icon link search"></i>
                            </div>
                        </div>
                    </div>

                    <div class="p-bottom"></div>
                    <div class="sixteen wide column">

                        <div class="tabular">
                            <loader v-show='loadingB' />
                            <vuetable ref="vuetablebets"
                                      :api-url="betsurl"
                                      :fields="columnss"
                                      track-by="msisdn"
                                      :first-page="0"
                                      :multi-sort="true"
                                      :append-params="moreParamss"
                                      :sort-order="sortOrder"
                                      :per-page="10"
                                      :row-class="onRowClass"
                                      :load-on-start="true"
                                      @vuetable:data-manager="dataManager"
                                      @vuetable:pagination-data="onPaginationDataBets"
                                      @vuetable:loading="onLoadingB"
                                      @vuetable:loaded="onLoadedB">

                                <template slot="actions" scope="props">
                                    <div class="custom-actions">
                                        <div>
                                            <div style="max-width:200px;">
                                                <div @click="getvirtual(props.rowData)" style="cursor:pointer;color:blue;"><strong>#{{props.rowData.bet_reference}}</strong></div>
                                                <span style="color:black;">{{props.rowData.total_games}} game(s)</span>
                                            </div>
                                        </div>

                                    </div>
                                </template>


                                <div slot="status-slot" slot-scope="props">

                                    <div v-if="props.rowData.bet_status === '3'"><strong style="color:#DB2828;">LOST</strong></div>
                                    <div  v-if="props.rowData.bet_status === '1'"><strong>PENDING</strong></div>
                                    <div  v-if="props.rowData.bet_status === '8'"><strong>Awaiting Approval</strong></div>
                                    <div v-if="props.rowData.bet_status === '5' || props.rowData.bet_status === '2'"><strong style="color:#21BA45;">WON</strong></div>
                                    <div v-if="props.rowData.bet_status === '4' || props.rowData.bet_status === '24'" ><strong styel="color:#2185D0;">CANCELLED</strong></div>
                                    <div v-if="props.rowData.bet_status === '19' || props.rowData.bet_status === '22'" ><strong style="color:#ff0000 ;">SPAMMING</strong></div>
                                </div>


                            </vuetable>


                            <div class="vuetable-pagination ui basic segment grid">
                                <vuetable-pagination-info ref="paginationInfoBets"
                                ></vuetable-pagination-info>
                                <vuetable-pagination ref="paginationBets"
                                                     @vuetable-pagination:change-page="onChangePageBets"
                                ></vuetable-pagination>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ui tab hidden" data-tab="virtual-bets">
                    <div class="ui middle aligned grid">
                        <div class="eight wide column">
                            <h2>Virtual Bets</h2>
                        </div>
                        <div class="eight wide right aligned column" v-show="profile">
                            <div class="ui icon input">
                                <input v-on:keyup.enter="searchVirtualBets" type='text' placeholder="Search..." v-model="search_virtual"/>
                                <i @click="searchVirtualBets" class="icon link search"></i>
                            </div>
                        </div>
                    </div>

                    <div class="p-bottom"></div>
                    <div class="sixteen wide column">
                        <!--api-url="http://35.189.221.84/dashboard-api/v1/dash/api/virtual"-->
                        <div class="tabular">
                            <loader v-show='loadingV' />
                            <vuetable ref="virtual"
                                      :api-url="virtualurl"
                                      :fields="virtualcolumns"
                                      :multi-sort="true"
                                      :append-params="virtualParams"
                                      :per-page="10"
                                      :sort-order="sortOrder"
                                      @vuetable:pagination-data="onPaginationDataVirtual"
                                      @vuetable:loading="onLoadingV"
                                      @vuetable:loaded="onLoadedV">


                                <template slot="actions" scope="props">
                                    <div class="custom-actions">
                                        <div>
                                            <div style="max-width:200px;">
                                                <div @click="getvirtual(props.rowData)" style="cursor:pointer;color:blue;"><strong>#{{props.rowData.bet_reference}}</strong></div>
                                                <span style="color:black;">{{props.rowData.total_games}} game(s)</span>
                                            </div>
                                        </div>

                                    </div>
                                </template>

                                <div slot="status-slot" slot-scope="props">

                                    <div v-if="props.rowData.status === '3'"><strong style="color:#DB2828;">LOST</strong></div>
                                    <div  v-if="props.rowData.status === '1'"><strong>PENDING</strong></div>
                                    <div v-if="props.rowData.status === '5' || props.rowData.status === '2'"><strong style="color:#21BA45;">WON</strong></div>
                                    <div v-if="props.rowData.status === '4' || props.rowData.status === '24'" ><strong styel="color:#2185D0;">CANCELLED</strong></div>
                                </div>

                            </vuetable>


                            <div class="vuetable-pagination ui basic segment grid">
                                <vuetable-pagination-info ref="paginationInfoVirtual"
                                ></vuetable-pagination-info>
                                <vuetable-pagination ref="paginationVirtual"
                                                     @vuetable-pagination:change-page="onChangePageVirtual"
                                ></vuetable-pagination>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ui tab" data-tab="kiron-bets">
                    <div class="ui middle aligned grid">
                        <div class="eight wide column">
                            <h2>Kiron Bets</h2>
                        </div>
                        <div class="eight wide right aligned column" v-show="profile">
                            <div class="ui icon input">
                                <input v-on:keyup.enter="searchKironBets" type='text' placeholder="Search..." v-model="search_kiron"/>
                                <i @click="searchKironBets" class="icon link search"></i>
                            </div>
                        </div>
                    </div>

                    <div class="p-bottom"></div>
                    <div class="sixteen wide column">
                        <!--api-url="http://35.189.221.84/dashboard-api/v1/dash/api/virtual"-->
                        <div class="tabular">
                            <loader v-show='loadingK' />
                            <vuetable ref="kiron"
                                      :api-url="kironurl"
                                      :fields="kironcolumns"
                                      :multi-sort="true"
                                      :append-params="kironParams"
                                      :per-page="10"
                                      :sort-order="sortOrder"
                                      @vuetable:pagination-data="onPaginationDataKiron"
                                      @vuetable:loading="onLoadingK"
                                      @vuetable:loaded="onLoadedK">


                                <template slot="actions" scope="props">
                                    <div class="custom-actions">
                                        <div>
                                            <div style="max-width:200px;">
                                                <div @click="getkiron(props.rowData)" style="cursor:pointer;color:blue;"><strong>#{{props.rowData.bet_reference}}</strong></div>
                                                <span style="color:black;">{{props.rowData.total_games}} game(s)</span>
                                            </div>
                                        </div>

                                    </div>
                                </template>

                                <div slot="status-slot" slot-scope="props">

                                    <div v-if="props.rowData.status === '3'"><strong style="color:#DB2828;">LOST</strong></div>
                                    <div  v-if="props.rowData.status === '1'"><strong>PENDING</strong></div>
                                    <div v-if="props.rowData.status === '5' || props.rowData.status === '2'"><strong style="color:#21BA45;">WON</strong></div>
                                    <div v-if="props.rowData.status === '6'"><strong style="color:#21BA45;">PAID</strong></div>
                                    <div v-if="props.rowData.status === '4' || props.rowData.status === '24'" ><strong styel="color:#2185D0;">CANCELLED</strong></div>
                                </div>


                                <div slot="action" slot-scope="props">
                                    <!--<b v-if="(role == 2 || role==3) && (props.rowData.status==5)">-->
                                        <!--<button-->
                                                <!--class="ui small button"-->
                                                <!--@click="onKironClicked('priority', props.rowData)"-->
                                        <!--&gt;-->
                                            <!--<i class="edit icon"></i>-->
                                        <!--</button>-->

                                    <!--</b>-->


                                    <b v-if="(role == 2 || role == 3) && (props.rowData.status==5)">
                                        <button
                                                class="ui small button"
                                                @click="onKironPayout('priority', props.rowData)"
                                        >
                                            <i class="phone icon"></i>
                                        </button>

                                    </b>

                                </div>

                            </vuetable>


                            <div class="vuetable-pagination ui basic segment grid">
                                <vuetable-pagination-info ref="paginationInfoKiron"
                                ></vuetable-pagination-info>
                                <vuetable-pagination ref="paginationKiron"
                                                     @vuetable-pagination:change-page="onChangePageKiron"
                                ></vuetable-pagination>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ui tab" data-tab="deposit">

                    <div class="ui grid">
                        <div class="sixteen wide column">
                            <h2>Deposits</h2>
                        </div>



                        <div class="eight wide left aligned column" v-show="profile">
                            <div class="ui icon input">
                                <input v-on:keyup.enter="searchDeposit" type='text' placeholder="Search..." v-model="search_deposit"/>
                                <i @click="searchDeposit" class="icon link search"></i>
                            </div>
                        </div>

                        <div class="eight wide right aligned column ">
                            <button v-on:click="raiseDepositIssue(profile_data.msisdn)" class="ui tiny labeled green icon button deposit-issue">
                                <i class="plus icon"></i>
                                Raise Deposit Issue
                            </button>
                        </div>


                    </div>
                    <div class="sixteen wide column p-top">
                        <div class="tabular">
                            <loader v-show='loadingD' />
                            <vuetable ref="deposit"
                                      :api-url="depositurl"
                                      :fields="depositcolumns"
                                      :multi-sort="true"
                                      :append-params="depositParams"
                                      :sort-order="depositSortOrder"
                                      :per-page="10"
                                      @vuetable:pagination-data="onPaginationDataDeposit"
                                      @vuetable:loading="onLoadingD"
                                      @vuetable:loaded="onLoadedD">
                                >

                                <div slot="status" slot-scope="props">

                                    <div v-if="props.rowData.status === '1'"><strong style="color:#21BA45;">SUCCESS</strong></div>
                                    <div  v-if="props.rowData.status==0 && !props.rowData.description"><strong>PENDING</strong></div>
                                    <div v-if="props.rowData.status==0 && props.rowData.description"><strong style="color:#21BA45;">{{props.rowData.description}}</strong></div>
                                </div>


                                <div slot="actions" slot-scope="props">
                                    <!--<b v-if="role == 2 && props.rowData.status==1 ">-->
                                    <button class="ui blue button" @click="onActionClicked('edit', props.rowData)"><i class="fa fa-pencil"></i></button>
                                    <!--</b>-->
                                </div>


                            </vuetable>


                            <div class="vuetable-pagination ui basic segment grid">
                                <vuetable-pagination-info ref="paginationInfoDeposit"
                                ></vuetable-pagination-info>
                                <vuetable-pagination ref="paginationDeposit"
                                                     @vuetable-pagination:change-page="onChangePagedeposits"
                                ></vuetable-pagination>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ui tab" data-tab="withdraws">
                    Withdrawals content

                    <div class="eight wide right aligned column" v-show="profile">
                        <div class="ui icon input">
                            <input v-on:keyup.enter="searchWithdraws" type='text' placeholder="Search..." v-model="search_withdraw"/>
                            <i @click="searchWithdraws" class="icon link search"></i>
                        </div>
                    </div>

                    <div class="sixteen wide column">
                        <div class="tabular">
                            <loader v-show='loadingW' />
                            <vuetable ref="withdraw"

                                      :api-url="withdrawalsurl"
                                      :fields="withdrawcolumns"
                                      :multi-sort="true"
                                      :append-params="withdrawParams"
                                      :sort-order="wSortOrder"
                                      :per-page="10"
                                      @vuetable:pagination-data="onPaginationDataWithdraw"
                                      @vuetable:loading="onLoadingW"
                                      @vuetable:loaded="onLoadedW">
                                >

                                <div slot="ticket" slot-scope="props">

                                    <p>
                                        <button
                                                class="ui small button"
                                                @click="raiseWTicketAction('reverse', props.rowData)">
                                            <i class="blue  reload  icon "></i>
                                        </button>

                                    </p>

                                </div>

                                <div slot="action" slot-scope="props">

                                    <p v-if="((role == 2  || role == 3) && (props.rowData.status !==0 && !props.rowData.withdraw_reference && props.rowData.checker==1))">
                                        <button
                                                class="ui small button"
                                                @click="onActionWithdraw('reverse', props.rowData)">
                                            <i class="blue  reload  icon "></i>
                                        </button>

                                    </p>

                                </div>




                            </vuetable>


                            <div class="vuetable-pagination ui basic segment grid">
                                <vuetable-pagination-info ref="paginationInfoWithdraw"
                                ></vuetable-pagination-info>
                                <vuetable-pagination ref="paginationWithdraw"
                                                     @vuetable-pagination:change-page="onChangePagewithdrawals"
                                ></vuetable-pagination>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ui tab" data-tab="withdraws1">
                    Raised Tickets

                    <div class="eight wide right aligned column" v-show="profile">
                        <div class="ui icon input">
                            <input v-on:keyup.enter="searchWithdraws1" type='text' placeholder="Search..." v-model="search_withdraw"/>
                            <i @click="searchWithdraws1" class="icon link search"></i>
                        </div>
                    </div>

                    <div class="sixteen wide column">
                        <div class="tabular">
                            <loader v-show='loadingW1' />
                            <vuetable ref="withdraw1"

                                      :api-url="ticketsurl"
                                      :fields="ticketscolumns"
                                      :multi-sort="true"
                                      :append-params="ticketParams"
                                      :sort-order="tSortOrder"
                                      :per-page="10"
                                      @vuetable:pagination-data="onPaginationDataWithdraw1"
                                      @vuetable:loading="onLoadingW1"
                                      @vuetable:loaded="onLoadedW1">
                                >


                                <template  v-slot:status-slot="props">
                                    <div  v-if="props.rowData.reference_type === '1'"><strong>PENDING Bet</strong></div>
                                    <div  v-if="props.rowData.reference_type === '2'"><strong>Pending Payout</strong></div>
                                    <div  v-if="props.rowData.reference_type === '3'"><strong>Wrong   Resulting</strong></div>
                                    <div  v-if="props.rowData.reference_type === '4'"><strong>Balance  Deducted</strong></div>
                                    <div  v-if="props.rowData.reference_type === '5'"><strong>Failed Withdraw</strong></div>
                                    <div  v-if="props.rowData.reference_type === '6'"><strong>Profile Activate</strong></div>
                                    <div  v-if="props.rowData.reference_type === '7'"><strong>Profile Deactivate</strong></div>
                                    <div  v-if="props.rowData.reference_type === '8'"><strong>Name Change</strong></div>

                                </template>

                                <template  v-slot:status="props">

                                    <div  style="color: red" v-if="props.rowData.status === '11'"><strong>PENDING</strong></div>
                                    <div  style="color: #0ea432" v-if="props.rowData.status === '1'"><strong>CLOSED</strong></div>


                                </template>

                                <div slot="actions" slot-scope="props">

                                    <p>
                                        <button
                                                class="ui green small button"
                                                @click="ticketcloseClicked('close', props.rowData)">
                                            <i class="blue  reload  icon "></i>
                                        </button>

                                    </p>

                                </div>

                            </vuetable>


                            <div class="vuetable-pagination ui basic segment grid">
                                <vuetable-pagination-info ref="paginationInfoWithdraw1"
                                ></vuetable-pagination-info>
                                <vuetable-pagination ref="paginationWithdraw1"
                                                     @vuetable-pagination:change-page="onChangePagewithdrawals1"
                                ></vuetable-pagination>
                            </div>
                        </div>
                    </div>
                </div>
                <div  class="ui tab" data-tab="transactions">

                    <div class="ui middle aligned grid">
                        <div class="eight wide column">
                            <h2>Profile Transactions</h2>
                        </div>


                        <div class="eight wide right aligned column" v-show="profile">
                            <div class="ui icon input">
                                <input v-on:keyup.enter="searchTransactions" type='text' placeholder="Search..." v-model="search_transactions"/>
                                <i @click="searchTransactions" class="icon link search"></i>
                            </div>

                            <select v-model="selectedType"  @change="filterTransactions"  class="ui  dropdown">
                                <option value="0">All Transactions</option>
                                <option value="4">Bonus</option>
                                <option value="1">Bet</option>
                                <option value="9">Casino</option>
                                <option value="8">Cancel Bet</option>
                                <option value="6">Resulting</option>

                            </select>
                        </div>

                    </div>


                    <div class="p-bottom"></div>
                    <div class="sixteen wide column">
                        <div class="tabular">
                            <loader v-show='loadingT' />
                            <vuetable ref="transactions"
                                      :api-url="transactionsurl"
                                      :fields="trans_columns"
                                      :multi-sort="true"
                                      :sort-order="trans_sort_order"
                                      :append-params="trans_params"
                                      :per-page="10"
                                      @vuetable:pagination-data="onPaginationDataTrans"
                                      @vuetable:loading="onLoadingT"
                                      @vuetable:loaded="onLoadedT">


                                <div slot="action" slot-scope="props">


                                    <!--<p v-if="((role == 2  || role == 3) && (props.rowData.created_by =='WITHDRAW' ))">-->
                                        <!--<button-->
                                                <!--class="ui small button"-->
                                                <!--@click="onActionWithdraw('reverse', props.rowData)">-->
                                            <!--<i class=" blue reload icon"></i>-->
                                        <!--</button>-->

                                    <!--</p>-->



                                    <!--<p v-if="((role == 2  || role == 3) && (props.rowData.created_by =='DEPOSIT' ))">-->
                                        <!--<button-->
                                                <!--class="ui small button"-->
                                                <!--@click="onActionDeposit(props.rowData)">-->
                                            <!--<i class=" blue reload icon"></i>-->
                                        <!--</button>-->

                                    <!--</p>-->

                                </div>


                            </vuetable>


                            <div class="vuetable-pagination ui basic segment grid">
                                <vuetable-pagination-info ref="paginationInfoTrans"
                                ></vuetable-pagination-info>
                                <vuetable-pagination ref="paginationTrans"
                                                     @vuetable-pagination:change-page="onChangePagetransactions"
                                ></vuetable-pagination>
                            </div>
                        </div>
                    </div>
                </div>

                <div  class="ui tab" data-tab="transactions1">

                    <div class="ui middle aligned grid">
                        <div class="eight wide column">
                            <h2>Profile Transactions</h2>
                        </div>

                        <div class="eight wide right aligned column" v-show="profile">
                            <div class="ui icon input">
                                <input v-on:keyup.enter="searchTransactions1" type='text' placeholder="Search..." v-model="search_transactions1"/>
                                <i @click="searchTransactions1" class="icon link search"></i>
                            </div>
                        </div>

                    </div>


                    <div class="p-bottom"></div>
                    <div class="sixteen wide column">
                        <div class="tabular">
                            <loader v-show='loadingT1' />
                            <vuetable ref="transactionss"
                                      :api-url="transactions1url"
                                      :fields="trans_columnss"
                                      :multi-sort="true"
                                      :sort-order="trans_sort_order1"
                                      :append-params="trans_params1"
                                      :per-page="10"
                                      @vuetable:pagination-data="onPaginationDataTrans1"
                                      @vuetable:loading="onLoadingT1"
                                      @vuetable:loaded="onLoadedT1">


                            </vuetable>


                            <div class="vuetable-pagination ui basic segment grid">
                                <vuetable-pagination-info ref="paginationInfoTrans1"
                                ></vuetable-pagination-info>
                                <vuetable-pagination ref="paginationTrans1"
                                                     @vuetable-pagination:change-page="onChangePagetransactions1"
                                ></vuetable-pagination>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
        <div v-if="selected_data">
            <betslip    :data="selected_data" ></betslip>
        </div>

        <div v-if="instant_selected_data">
          <instant    :data="instant_selected_data" ></instant>
        </div>
        <div v-if="Vselected_data" >
            <vbetslip   :data="Vselected_data" ></vbetslip>
        </div>

        <div v-if="deposit_data">
             <deposit    :data="deposit_data"></deposit>
        </div>

        <div v-if="award_data">
            <award    :data="award_data"></award>
        </div>

        <div v-if="edit_data">
            <editname    :data="edit_data"></editname>
        </div>

        <reconcile v-if="deposit_data" :data="deposit_data"/>


        <blacklist v-if="blacklist_data" :data="blacklist_data"/>
        <ticket v-if="ticket_data" :data="ticket_data"/>
        <pticket v-if="pticket_data" :data="pticket_data"/>

    </div>
</template>



<script>

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import moment from 'moment'
    import betslip from "../modals/betslip"
    import instant from "../modals/instant"
    import vbetslip from "../modals/vbetslip"
    import award from "../modals/award"
    import editname from "../modals/editname"
    import deposit from "../modals/deposit"
    import _ from "lodash";
    import loader from "@/components/loader"
    import reconcile from "../modals/reconcile"
    import blacklist from "../modals/blacklist";
    import ticket from "../modals/tickets";
    import pticket from "../modals/profileticket";
    import {config} from '../config/config';




    export default {

        name: 'profiles',

        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo,betslip,deposit,vbetslip,award, loader,reconcile,editname,blacklist,ticket,pticket,config,instant

        },
        props:{
            data:{
                type: String

            }
        },
        data() {
            return {
                defaulturl:config.baseURL+'odibets/dashboard/bets',
                instanturl:config.baseURL+'odibets/instant/bets',
                betsurl:config.baseURL+'portal/jackpot/bets',
                virtualurl:config.baseURL+'odibets/virtual',
                kironurl:config.baseURL+'odibets/kiron/virtual',
                depositurl:config.baseURL+'odibets/deposits',
                withdrawalsurl:config.baseURL+'odibets/withdrawals',
                ticketsurl:config.baseURL+'portal/profile/tickets',
                transactionsurl:config.baseURL+'portal/dash/transactions',
                transactions1url:config.baseURLWEB+'dash/api/transactions1',
                bets:'',
                rowData:'',
                kiron:'',
                instant:'',
                selectedType:1,
                search_bets:'',

                search_kiron:'',
                search_instant:'',
                kiron_bets:'',
                instant_bets:'',
                role:'',
                user_id:'',
                search_virtual:'',
                search_transactions:'',
                search_transactions1:'',
                transactions:'',
                transactionss:'',
                withdraw:'',
                withdraw1:'',
                token:'',
                vuetable:'',
                vuetablebets:'',
                deposit:'',
                selectedTab: 'real-bets',
                profile_loader: '',
                search_profile: '',
                search_withdraw: '',
                search_bet: '',
                profile_data: '',
                selectedProfile: '',
                profileData: '',
                modalShow: false,
                blacklist: '',
                modalVisible: false,
                modalData: null,
                instanrmodalData: null,
                VmodalData: null,
                betslips: '',
                instantbetslips: '',
                Vbetslips: '',
                selectedBet: '',
                search_deposit: '',
                moreParams: {
                    profile: '',
                    bet: '',
                    filter: '',
                    token: this.token,

                },
                moreParamss: {
                    profile: '',
                    bet: '',
                    filter: '',
                    token: this.token,

                },
                virtualParams: {
                    profile: '',
                    bet: '',
                    filter: '',
                    token: this.token,

                },

                kironParams: {
                    profile: '',
                    bet: '',
                    filter: '',
                    token: this.token,

                },

              instantParams: {
                profile: '',
                bet: '',
                filter: '',
                token: this.token,

              },
                withdrawParams: {
                    profile:'',
                    filter:'',
                    token: this.token,
                },

                ticketParams: {
                    profile:'',
                    filter:'',
                    token: this.token,
                },
                depositParams: {
                    filter: '',
                    profile: '',
                    token: this.token,
                },
                trans_params: {
                    filter: '',
                    type: '1',
                    profile: '',
                    token: this.token,
                },
                trans_params1: {
                    filter: '',
                    profile: '',
                    token: this.token,
                },

                columns: [
                    {
                        name: 'bet_id',
                        title: 'SystemID',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned',
                        width: "15%",

                    },

                    {
                        name: 'actions',
                        title: 'Actions',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'
                    },
                    {
                        name: 'bet_amount',
                        title: 'Stake',
                        sortField: 'bet_amount',
                        titleClass: '',
                        dataClass: ''
                    },

                    {
                        name: 'total_odd',
                        title: 'ODDS',
                        sortField: 'total_odd',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: 'possible_win',
                        title: 'To WIN',
                        sortField: 'possible_win',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: 'created',
                        title: 'Placed ON',
                        sortField: 'created',
                        titleClass: '',
                        dataClass: '',
                        callback: 'createdAt'
                    },
                    {
                        name: 'status-slot',
                        title: 'Status',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned',
                    },
                    'action'

                ],
                columnss: [
                    {
                        name: 'jp_bet_id',
                        title: 'SystemID',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned',
                        width: "15%",

                    },

                    {
                        name: 'actions',
                        title: 'Actions',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'
                    },
                    {
                        name: 'bet_amount',
                        title: 'Stake',
                        sortField: 'bet_amount',
                        titleClass: '',
                        dataClass: ''
                    },

                    {
                        name: 'possible_win',
                        title: 'To WIN',
                        sortField: 'possible_win',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: 'created',
                        title: 'Placed ON',
                        sortField: 'created',
                        titleClass: '',
                        dataClass: '',
                        callback: 'createdAt'
                    },
                    {
                        name: 'status-slot',
                        title: 'Status',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned',
                    },

                ],

              instantcolumnss: [
                {
                  name: 'bet_id',
                  title: 'SystemID',
                  titleClass: 'center aligned',
                  dataClass: 'center aligned',
                  width: "15%",

                },

                {
                  name: 'actions',
                  title: 'Actions',
                  titleClass: 'center aligned',
                  dataClass: 'center aligned'
                },
                {
                  name: 'bet_amount',
                  title: 'Stake',
                  sortField: 'bet_amount',
                  titleClass: '',
                  dataClass: ''
                },

                {
                  name: 'possible_win',
                  title: 'To WIN',
                  sortField: 'possible_win',
                  titleClass: '',
                  dataClass: ''
                },
                {
                  name: 'created',
                  title: 'Placed ON',
                  sortField: 'created',
                  titleClass: '',
                  dataClass: '',
                  callback: 'createdAt'
                },
                {
                  name: 'status-slot',
                  title: 'Status',
                  titleClass: 'center aligned',
                  dataClass: 'center aligned',
                },

              ],

                virtualcolumns: [
                    {
                        name: 'bet_id',
                        title: 'SystemID',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned',
                        width: "15%",

                    },

                    {
                        name: 'actions',
                        title: 'Actions',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'
                    },
                    {
                        name: 'bet_amount',
                        title: 'Stake',
                        sortField: 'bet_amount',
                        titleClass: '',
                        dataClass: ''
                    },

                    {
                        name: 'total_odd',
                        title: 'ODDS',
                        sortField: 'total_odd',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: 'possible_win',
                        title: 'To WIN',
                        sortField: 'possible_win',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: 'created',
                        title: 'Placed ON',
                        sortField: 'created',
                        titleClass: '',
                        dataClass: '',
                        callback: 'createdAt'
                    },
                    {
                        name: 'status-slot',
                        title: 'Status',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned',
                    },

                ],


                kironcolumns: [
                    {
                        name: 'bet_id',
                        title: 'SystemID',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned',
                        width: "15%",

                    },

                    {
                        name: 'actions',
                        title: 'Actions',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'
                    },
                    {
                        name: 'bet_amount',
                        title: 'Stake',
                        sortField: 'bet_amount',
                        titleClass: '',
                        dataClass: ''
                    },

                    {
                        name: 'total_odd',
                        title: 'ODDS',
                        sortField: 'total_odd',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: 'possible_win',
                        title: 'To WIN',
                        sortField: 'possible_win',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: 'created',
                        title: 'Placed ON',
                        sortField: 'created',
                        titleClass: '',
                        dataClass: '',
                        callback: 'createdAt'
                    },
                    {
                        name: 'status-slot',
                        title: 'Status',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned',
                    },

                    'action'

                ],

                trans_columns: [
                    {
                        name: 'reference_id',
                        title: '#',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'
                    },
                    {
                        name: 'reference_id',
                        title: 'Bet ID'
                    },
                    {
                        name: 'amount',
                        title: 'Amount',
                        sortField: 'amount'
                    },
                    {
                        name: 'running_balance',
                        title: 'Running Balance',
                        titleClass: 'running_balance'
                    },
                    {
                        name: 'created',
                        title: 'Transaction Date',
                        sortField: 'created',
                        callback: 'createdAt'

                    },
                    {
                        name: 'created_by',
                        title: 'Created By',
                        sortField: 'created_by'

                    },
                    {
                        name: 'channel_id',
                        title: 'CHANNEL',
                        sortField: 'channel_id'

                    },
                    'action'
                ],


                trans_columnss: [
                    {
                        name: 'reference_id',
                        title: '#',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'
                    },
                    {
                        name: 'reference_id',
                        title: 'Bet ID'
                    },
                    {
                        name: 'amount',
                        title: 'Amount',
                        sortField: 'amount'
                    },
                    {
                        name: 'running_balance',
                        title: 'Running Balance',
                        titleClass: 'running_balance'
                    },
                    {
                        name: 'created',
                        title: 'Transaction Date',
                        sortField: 'created',
                        callback: 'createdAt'

                    },
                    {
                        name: 'created_by',
                        title: 'Created By',
                        sortField: 'created_by'

                    },
                    {
                        name: 'channel_id',
                        title: 'CHANNEL',
                        sortField: 'channel_id'

                    },
                    'action'
                ],


                depositcolumns: [

                    {
                        name: 'mpesa_code',
                        title: 'TransactionID',
                        sortField: 'mpesa_code'
                    },
                    {
                        name: 'mpesa_amt',
                        title: 'Amount',
                        sortField: 'mpesa_amt',
                        callback: 'formatNumber'
                    },

                    {
                        name: 'mpesa_sender',
                        title: 'Name',
                    },
                    {
                        name: 'created',
                        title: 'Date Deposited',
                        sortField: 'created',
                        callback: 'createdAt'
                    },
                    'status',

                    'actions'
                ],


                wSortOrder: [{
                    field: 'created',
                    direction: 'desc'
                 }],


                tSortOrder: [{
                    field: 'created',
                    direction: 'desc'
                }],



                withdrawcolumns :[
                    {
                        name: 'transaction_id',
                        title: '#ID',
                        sortField: 'transaction_id',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'
                    },
                    {
                        name: 'withdraw_reference',
                        title: 'Reference',
                        sortField: 'withdraw_reference'
                    },
                    {
                        name: 'amount',
                        title: 'Amount',
                        sortField: 'amount',
                        callback: 'formatNumber'
                    },
                    {
                        name: 'status_desc',
                        title: 'Status'
                    },
                    {
                        name: 'created',
                        title: 'Withdrawal Date',
                        callback: 'createdAt'
                    },

                    'action',
                    'ticket'
                ],


                ticketscolumns: [
                    {
                        name: 'msisdn',
                        title: 'Mobile',
                        sortField: 'msisdn',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'

                    },

                    {
                        name: 'reference_id',
                        title: 'Reference',
                        sortField: 'reference_id',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'status-slot',
                        title: 'Type',
                        titleClass: '',
                        dataClass: '',

                    },

                    {
                        name: 'amount',
                        title: 'Amount',
                        sortField: 'amount',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'status',
                        title: 'status',
                        titleClass: '',
                        dataClass: '',

                    },

                    {
                        name: 'raised_by',
                        title: 'Agent ',
                        titleClass: ''
                    },

                    {
                        name: 'solved_by',
                        title: 'Support ',
                        titleClass: ''
                    },
                    {
                        name: 'created',
                        title: 'created',
                        sortField: 'created',
                        callback: 'depositedAt'
                    },
                    {
                        name: 'solved_date',
                        title: 'solved',
                        sortField: 'created',
                        callback: 'depositedAt'
                    },


                    'actions'


                ],

                sortOrder: [{
                    field: 'created',
                    direction: 'desc'
                }],
                depositSortOrder: [{
                    field: 'created',
                    direction: 'desc'
                }],
                trans_sort_order: [{
                    field: 'created',
                    direction: 'desc'
                }],
                trans_sort_order1: [{
                    field: 'created',
                    direction: 'desc'
                }],
                selected_data:null,
                instant_selected_data:null,
                deposit_data:null,
                ticket_data:null,
                pticket_data:null,
                award_data:null,
                edit_data:null,
                blacklist_data:null,
                Vselected_data:null,
                loading: true,
                loadingV: true,
                loadingT: true,
                loadingT1: true,
                loadingD: true,
                loadingW: true,
                loadingW1: true,
                loadingB: true,
                loadingK: true,
                loadingI: true,


            }
        },

        computed: {
            profile: function () {
               // console.log("Profile Data: " + JSON.stringify(this.profile_data));
                return this.profile_data;
            }

        },

        mounted(){


        },


        created: function(){

            var obj = window.localStorage.getItem('user_info');
            if (!obj) {
                this.$router.push({name: 'login'});
            }

            var vm=this;



            var obj = window.localStorage.getItem('user_info');

            var username = window.localStorage.getItem('username');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;



            vm.role=rolee;
            vm.user_id=userr;
            vm.username=username;
            vm.token=tokenn;

            vm.checktimer();

            $('.menu .item').tab();
            vm.selectedTab =='real-bets';
          //  console.log("selectedTab",vm.selectedTab)
            // $(".ui.dropdown").dropdown();

        },

        methods: {


            onActionClicked(action, data) {
                // $('#modal-approvals').modal({centered: false}).modal("show");
              //  console.log("slot actions: on-click", data);


                var vm=this;

                if (action == 'edit') {

                    vm.deposit_data=data;

                    $('.ui.modal#reconcile').modal({
                        closable: true,
                    }).modal('show');


                }


            },


            raiseTicketAction(action, data) {



                var vm=this;




                var myObj = {
                    bet_id: data.bet_id,
                    msisdn: vm.profile_data.msisdn,
                    amount: 0,
                }


                vm.ticket_data=myObj;

                    $('.ui.modal#ticket').modal({
                        closable: true,
                    }).modal('show');



            },


            blastlistAccout(action, data) {

                var vm=this;

                if (action == 'blacklist') {

                    vm.blacklist_data=data;

                    $('.ui.modal#blacklist').modal({
                        closable: true,
                    }).modal('show');


                }


            },


            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");

                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },


            award : function (){
                var vm=this;

                var myObj = {
                    profile_id: vm.profile_data.profileID,
                }
              //  console.log("data being sent",myObj)

                vm.award_data=myObj

                $('.ui.modal#award').modal({
                    closable: true,
                }).modal('show');

               // console.log("hello award")
            },

            editname : function (){
                var vm=this;

                    var myObj = {
                        profile_id: vm.profile_data.profileID,
                    }
               // console.log("data being sent",myObj)

                vm.edit_data=myObj

                $('.ui.modal#editname').modal({
                    closable: true,
                }).modal('show');

               // console.log("hello award")
            },

             itemAction: function (data) {
              //  console.log("Modal called once for bet id "+ data.bet_id)

                var vm = this;
                $.get({
                    url: config.baseURL+'odibets/final?bet_id='+data.bet_id + '&token='+vm.token,
                    type: "GET",
                    success: function (response) {
                     //   console.log("server response",response)
                        var betslips = response;

                        if (!Array.isArray(betslips)) {
                            return;
                        }

                        $.each(betslips, function (index, item) {
                            if (item.bet_pick == "1") {
                                item.pick = 'Home';
                            } else if (item.bet_pick == "2") {
                                item.pick = 'Away';
                            } else if (item.bet_pick == "X") {
                                item.pick = 'Draw';
                            } else {
                                item.pick = item.bet_pick;
                            }

                            if (item.status == 1) {
                                item.bet_status = 'bg-grey-light';
                            } else if (item.status == 3) {
                                item.bet_status = 'red';
                            } else if (item.status == 4 || item.status == 24) {
                                item.bet_status = 'blue';
                            } else if (item.status == 5 ) {
                                item.bet_status = 'green';
                            } else if (item.status == 2 ) {
                                item.bet_status = 'grey';
                            }


                            if (item.live_bet == 1) {
                                item.bet_type = 'Live';
                            } else if (item.live_bet == 0) {
                                item.bet_type = 'Prematch';
                            }

                            item.start_time = moment(item.start_time).format('HH:mm DD/MM/YY');
                            item.ft_score = item.ft_score ? item.ft_score : '0:0';
                            item.winning_outcome = item.winning_outcome ? item.winning_outcome : 'NONE';
                        });

                        vm.betslips = betslips;



                        var myObj = {
                            betData: data,
                            betslipData: vm.betslips
                        }

                        vm.selected_data= myObj;

                        vm.modalData = myObj;

                      //  console.log("betslip final data"+ JSON.stringify(vm.selected_data))


                        // $('.ui.modal#betslip').modal({
                        //     closable: true,
                        // }).modal('show');


                        $('#betslip').modal("show");

                        // $('.ui.modal#deposit').modal({
                        //     closable: true,
                        // }).modal('show');


                    },
                    error: function () {


                    }
                })

            },

          instantBetslipAction: function (data) {

            var vm = this;
            $.get({
              url: config.baseURL+'instant/betslip?bet_id='+data.bet_id + '&token='+vm.token,
              type: "GET",
              success: function (response) {
                //   console.log("server response",response)
                var betslips = response;

                if (!Array.isArray(betslips)) {
                  return;
                }

                $.each(betslips, function (index, item) {
                  if (item.bet_pick == "1") {
                    item.pick = 'Home';
                  } else if (item.bet_pick == "2") {
                    item.pick = 'Away';
                  } else if (item.bet_pick == "X") {
                    item.pick = 'Draw';
                  } else {
                    item.pick = item.bet_pick;
                  }

                  if (item.status == 1) {
                    item.bet_status = 'bg-grey-light';
                  } else if (item.status == 3) {
                    item.bet_status = 'red';
                  } else if (item.status == 4 || item.status == 24) {
                    item.bet_status = 'blue';
                  } else if (item.status == 5 ) {
                    item.bet_status = 'green';
                  } else if (item.status == 2 ) {
                    item.bet_status = 'grey';
                  }


                  if (item.live_bet == 1) {
                    item.bet_type = 'Live';
                  } else if (item.live_bet == 0) {
                    item.bet_type = 'Prematch';
                  }

                  //item.start_time = moment(item.start_time).format('HH:mm DD/MM/YY');
                  item.result = item.result ? item.result : '0:0';
                  //item.winning_outcome = item.winning_outcome ? item.winning_outcome : 'NONE';
                });

                vm.instantbetslips = betslips;



                var myObj = {
                  betData: data,
                  betslipData: vm.instantbetslips
                }

                vm.instant_selected_data= myObj;

                vm.instanrmodalData = myObj;



                $('#instant').modal("show");
              },
              error: function () {


              }
            })

          },


            getvirtual: function (data) {
             //   console.log("Modal called once for bet id "+ data.jp_bet_id)

                var vm = this;
                $.get({
                    url: config.baseURL+'portal/jackpot/slips?bet_id=' + data.jp_bet_id + '&token='+vm.token,
                    type: "GET",
                    success: function (response) {
                        var betslips = response;

                        if (!Array.isArray(betslips)) {
                            return;
                        }


                        $.each(betslips, function (index, item) {
                            if (item.bet_pick == "1") {
                                item.pick = 'Home';
                            } else if (item.bet_pick == "2") {
                                item.pick = 'Away';
                            } else if (item.bet_pick == "X") {
                                item.pick = 'Draw';
                            } else {
                                item.pick = item.bet_pick;
                            }


                            if (item.status == 1) {
                                item.bet_status = 'bg-grey-light';
                            } if (item.status == 3) {
                                item.bet_status = 'red';
                            } if (item.status == 4 || item.status == 24) {
                                item.bet_status = 'blue';
                            }  if (item.status == 5 ) {
                                item.bet_status = 'green';
                            }  if (item.status == 2 ) {
                                item.bet_status = 'grey';
                            }

                            // if (item.status == 1) {
                            //     item.bet_status = 'bg-grey-light';
                            // } else if (item.status == 3) {
                            //     item.bet_status = 'red';
                            // } else if (item.status == 4 || item.status == 24) {
                            //     item.bet_status = 'blue';
                            // } else if (item.status == 5) {
                            //     item.bet_status = 'green';
                            // }

                            if (item.live_bet == 1) {
                                item.bet_type = 'Live';
                            } else if (item.live_bet == 0) {
                                item.bet_type = 'Prematch';
                            }

                            item.start_time = moment(item.start_time).format('HH:mm DD/MM/YY');
                            item.ft_score = item.ft_score ? item.ft_score : '0:0';
                            item.winning_outcome = item.winning_outcome ? item.winning_outcome : 'NONE';
                        });

                        vm.Vbetslips = betslips;




                        var myObj = {
                            betData: data,
                            betslipData: vm.Vbetslips
                        }

                        vm.Vselected_data= myObj;

                        vm.VmodalData = myObj;

                       // console.log("betslip final data"+ JSON.stringify(vm.Vselected_data))


                        $('.ui.modal#vbetslip').modal({
                            closable: true,
                        }).modal('show');


                    },
                    error: function () {


                    }
                })

            },



            getkiron: function (data) {
               // console.log("Modal called once for bet id "+ data.bet_id)

                var vm = this;
                $.get({
                    url: config.baseURL+'odibets/kiron/slips?bet=' + data.bet_id+ '&token='+vm.token,
                    type: "GET",
                    success: function (response) {
                        var betslips = response;

                        if (!Array.isArray(betslips)) {
                            return;
                        }


                        $.each(betslips, function (index, item) {
                            if (item.bet_pick == "1") {
                                item.pick = 'Home';
                            } else if (item.bet_pick == "2") {
                                item.pick = 'Away';
                            } else if (item.bet_pick == "X") {
                                item.pick = 'Draw';
                            } else {
                                item.pick = item.bet_pick;
                            }

                            if (item.status == 1) {
                                item.bet_status = 'bg-grey-light';
                            } else if (item.status == 3) {
                                item.bet_status = 'red';
                            } else if (item.status == 4 || item.status == 24) {
                                item.bet_status = 'blue';
                            } else if (item.status == 5) {
                                item.bet_status = 'green';
                            }

                            if (item.live_bet == 1) {
                                item.bet_type = 'Live';
                            } else if (item.live_bet == 0) {
                                item.bet_type = 'Prematch';
                            }

                            item.start_time = moment(item.start_time).format('HH:mm DD/MM/YY');
                            item.ft_score = item.ft_score ? item.ft_score : '0:0';
                            item.winning_outcome = item.winning_outcome ? item.winning_outcome : 'NONE';
                        });

                        vm.Vbetslips = betslips;



                        var myObj = {
                            betData: data,
                            betslipData: vm.Vbetslips
                        }

                        vm.Vselected_data= myObj;

                        vm.VmodalData = myObj;

                      //  console.log("betslip final data"+ JSON.stringify(vm.Vselected_data))


                        $('.ui.modal#vbetslip').modal({
                            closable: true,
                        }).modal('show');

                        // $('.ui.modal#deposit').modal({
                        //     closable: true,
                        // }).modal('show');


                    },
                    error: function () {


                    }
                })

            },


            clearProfileData(){
                //location.reload();
                this.profile_data = '';
                this.search_profile = '';
            },


            searchTransactions: function () {
                this.trans_params.filter=this.search_transactions;
                this.$refs.transactions.reload();
            },

            filterTransactions: function () {
                this.trans_params.type=this.selectedType;
                this.$refs.transactions.reload();
            },

            searchTransactions1: function () {
                this.trans_params1.filter=this.search_transactions1;
                this.$refs.transactionss.reload();
            },
            searchWithdraws: function () {
                this.withdrawParams.filter=this.search_withdraw;
                this.$refs.withdraw.reload();
            },


            searchWithdraws1: function () {
                this.withdrawParams.filter=this.search_withdraw;
                this.$refs.withdraw1.reload();
            },

            //
            // searchBets: function () {
            //     console.log("seach old bets")
            //     this.moreParams[2] = 'filter=' + this.search_bet;
            //     this.$refs.vuetable.reload();
            // },
            // searchBetss: function () {
            //     console.log("serach new bwts")
            //     this.moreParamss[2] = 'filter=' + this.search_bets;
            //     this.$refs.vuetablebets.reload();
            // },
            searchVirtualBets: function () {
              //  console.log("virtual serach ",this.search_virtual)
                this.virtualParams.filter =   this.search_virtual;
                this.$refs.virtual.reload();
            },

            searchKironBets: function () {
              //  console.log("virtual serach ",this.search_kiron)
                this.kironParams.filter =   this.search_kiron;
                this.$refs.kiron.reload();
            },

          searchInstantBets: function () {
            this.instantParams.filter =   this.search_instant;
            this.$refs.instant.reload();
          },

            raiseDepositIssue:function(val){
              //  console.log("deposit value",val)

                var vm=this;

                vm.deposit_data=val;

                $('#deposit').modal("show");//.modal({centered: false}).modal("show");
                // $('.ui.modal#deposit').modal({
                //     closable: true,
                // }).modal('show');

            },




            onKironPayout(action, data) {


            },


            ticketcloseClicked(action, data) {
                var vm =this;

                if (action == 'close') {
                    vm.$swal.fire({
                        title: 'Close  Ticket?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Close Ticket!'
                    }).then((result) => {

                        if (result.value) {
                            var Voiddata = {
                                id: data.ticket_id,
                                token: vm.token,
                            };

                            $.ajax({
                                url: config.baseURL+'portal/close/ticket',
                                type: 'POST',
                                data: JSON.stringify(Voiddata),
                                success: function (response, textStatus, jQxhr) {

                                    if (response.status_code == 200) {

                                        vm.$refs.vuetable.reload();

                                        vm.$swal("Success!", response.status_description, "success");


                                        $('#switch-modall').modal('hide');

                                    } else {

                                        vm.$refs.vuetable.reload();


                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }else {

                        }
                    })
                }
            },


            onActionWithdraw(action, data) {
                // $('#modal-approvals').modal({centered: false}).modal("show");
              //  console.log("slot actions: on-click", data);


                var vm=this;

                if (action == 'reverse') {

                    vm.$swal.fire({
                        title: 'Confirm WITHDRAW REVERSAL?',
                        text: "If you are sure, Proceed with Reversal",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Bet Refund!'
                    }).then((result) => {
                        if (result.value) {

                            var betcanceldata = {
                                transaction_id: data.transaction_id,
                                msisdn: vm.profile_data.msisdn,
                                response_code: 'fSWWcHzn7Sce',
                                user_id: vm.user_id,
                                token: vm.token,
                            };
                            $.ajax({
                                url: config.baseURL+'portal/sort/withdraw',
                                type: 'POST',
                                data: JSON.stringify(betcanceldata),
                                success: function (response, textStatus, jQxhr) {
                               //     console.log("Swap Matche: " + JSON.stringify(response));
                                    if (response.status_code == 200) {
                                        vm.$swal("Success!", response, "success");

                                    }else {
                                        vm.$swal("Error!", response, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }
                    })

                }


            },



            raiseWTicketAction(action, data) {
                // $('#modal-approvals').modal({centered: false}).modal("show");
                //  console.log("slot actions: on-click", data);


                var vm=this;

                if (action == 'reverse') {

                    vm.$swal.fire({
                        title: 'Raise  Ticket?',
                        text: "Verify and Proceed , Raise Ticket!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Raise Ticket'
                    }).then((result) => {
                        if (result.value) {

                            var dataa = {
                                bet_id:  data.transaction_id,
                                msisdn: vm.profile_data.msisdn,
                                amount: data.amount,
                                token: vm.token,
                                reference_type: 5
                            };
                            $.ajax({
                                url: config.baseURL+'portal/raise/ticket',
                                type: 'POST',
                                data: JSON.stringify(dataa),
                                success: function (response, textStatus, jQxhr) {
                                    //     console.log("Swap Matche: " + JSON.stringify(response));
                                    if (response.status_code == 200) {
                                        vm.$swal("Success!", response, "success");

                                    }else {
                                        vm.$swal("Error!", response, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }
                    })

                }


            },



            formatNumber: function (num) {
                return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            },

            onLoading() {
                this.loading = true
               // this.$refs.vuetable.reload()
                console.log('loading... show your spinner here')
            },
            onLoaded() {
                this.loading = false
                 // this.$refs.vuetable.reload();
                console.log('loaded! .. hide your spinner here')
            },

            createdAt: function (value) {
                if (!value) {
                    return '-';
                } else {
                    return moment(value).format('h:mm a,DD MMM YYYY');
                }

            },

            genderLabel(value) {

                if (value === '3'){
                    return  '<div><strong style="color:#DB2828;">LOST</strong></div>'
                }else if (value ==1 ){
                    return  '<div><strong>PENDING</strong></div>'
                }
                else if (value ==5 ){
                    return   '<div><strong style="color:#21BA45;">WON</strong></div>'
                }
                else{
                    return  '<div><strong styel="color:#2185D0;">CANCELLED</strong></div>'
                }

            },

            searchDeposit(){

                this.depositParams.filter = this.search_deposit;
              //  this.depositParams.profile = this.profile_data.profileID;
                this.$refs.deposit.reload();

            },

            searchTransaction(){

            },

            DeductAccout() {

                var vm = this;
                vm.$swal({
                    title: "Deduct Amount?",
                    text: "If you are sure, type in the new Amount:",
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Deduct',
                    showLoaderOnConfirm: true,
                    preConfirm: (priority) => {
                    //    console.log("finally", priority)
                        if (!priority) {
                            return;
                        }
                        var dataa = {
                            profile_id: vm.profile_data.profileID,
                            amount: priority,
                            user_id: vm.user_id,
                            token: vm.token
                        };
                        $.ajax({
                            url: config.baseURL+'portal/deduct/account',
                            type: 'POST',
                            data: JSON.stringify(dataa),
                            success: function (response, textStatus, jQxhr) {
                             //   console.log("Swap Matche: " + JSON.stringify(response));
                                if (response.status_code == 200) {
                                    vm.$swal("Success!", response.status_description, "success");

                                    $('#switch-modall').modal('hide');

                                        vm.$refs.transactions.reload();


                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }
                });

            },


            RaiseTicket() {


                var vm=this;

                let code = "";
                for (let i = 0; i < 6; i++) {
                    code += Math.floor(Math.random() * 10);
                }


                var myObj = {
                    bet_id: code,
                    msisdn: vm.profile_data.msisdn,
                    amount: 0,
                };

                vm.pticket_data=myObj;

                $('.ui.modal#pticket').modal({
                    closable: true,
                }).modal('show');

            },

                blacklistAccout(){

                var vm=this;

                vm.$swal.fire({
                    title: 'Blacklist ACCOUNT?',
                    text: "",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Blacklist!'
                }).then((result) => {
                    if (result.value) {
                        var Voiddata = {
                            msisdn: vm.profile_data.msisdn,
                            user_id: vm.user_id,
                            token: vm.token
                        };
                      //  console.log("Payload | " + JSON.stringify(Voiddata));
                        $.ajax({
                            url: config.baseURL+'portal/subscribe',
                            type: 'POST',
                            data: JSON.stringify(Voiddata),
                            success: function (response, textStatus, jQxhr) {
                              //  console.log("Swap Matche: " + JSON.stringify(response));
                                if (response.status_code == 200) {
                                    vm.$swal("Success!", response.status_description, "success");

                                    $('#switch-modall').modal('hide');

                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }else {

                    }
                })
            },
            activateAccout(){
                var vm=this;

                vm.$swal.fire({
                    title: 'ACTIVATE ACCOUNT?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, ACTIVATE ACCOUNT!'
                }).then((result) => {
                    if (result.value) {
                        var Voiddata = {
                            msisdn: vm.profile_data.msisdn,
                            user_id: vm.user_id,
                            token: vm.token
                        };
                      //  console.log("Payload | " + JSON.stringify(Voiddata));
                        $.ajax({
                            url: config.baseURL+'portal/subscribe',
                            type: 'POST',
                            data: JSON.stringify(Voiddata),
                            success: function (response, textStatus, jQxhr) {
                                if (response.status_code == 200) {
                                    vm.$swal("Success!", response.status_description, "success");

                                    $('#switch-modall').modal('hide');

                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }else {

                    }
                })
            },


            resendCode(){
                var vm=this;

                vm.$swal.fire({
                    title: 'Resend OTP/CODE?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, RESEND CODE!'
                }).then((result) => {
                    if (result.value) {
                        var Voiddata = {
                            msisdn: vm.profile_data.msisdn,
                            user_id: vm.user_id,
                            token: vm.token
                        };
                       // console.log("Payload | " + JSON.stringify(Voiddata));
                        $.ajax({
                            url: config.baseURL+'portal/resend/code',
                            type: 'POST',
                            data: JSON.stringify(Voiddata),
                            success: function (response, textStatus, jQxhr) {

                                if (response.status_code == 200) {
                                    vm.$swal("Success!", response.status_description, "success");

                                    $('#switch-modall').modal('hide');

                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }else {

                    }
                })
            },


            bonusaward(){
                var vm=this;

                vm.$swal.fire({
                    title: 'Award Promo Bonus?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Award Bonus!'
                }).then((result) => {
                    if (result.value) {
                        var Voiddata = {
                            msisdn: vm.profile_data.msisdn,
                            token: vm.token,
                            user_id: vm.user_id
                        };

                        $.ajax({
                            url: config.baseURL+'portal/promoaward',
                            type: 'POST',
                            data: JSON.stringify(Voiddata),
                            success: function (response, textStatus, jQxhr) {
                             //   console.log("Swap Matche: " + JSON.stringify(response));
                                if (response.status_code == 200) {
                                    vm.$swal("Success!", response.status_description, "success");

                                    $('#switch-modall').modal('hide');

                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }else {

                    }
                })
            },

            searchProfile: function () {

                if (!this.search_profile) {
                    return;
                }

                var vm = this;

                $.get({
                    url: config.baseURL+'odibets/search?msisdn=' + vm.search_profile+ "&user_id="+ vm.user_id +"&token="+ vm.token,
                    type: "GET",
                    success: function (response) {
                        vm.profile_loader = '';


                        var profile = response;
//                        console.log("Profile Data: " + JSON.stringify(profile));

                        if (!(profile.profile_id)) {
                            vm.profile_data = '';
                            vm.selectedProfile = '';
                            return;
                        }

                        var firstName = profile.first_name ? profile.first_name : '';
                        var lastName = profile.last_name ? profile.last_name : '';
                        var middleName = profile.middle_name ? profile.middle_name : '';

                        var profileData = {
                            profileID: profile.profile_id,
                            created: profile.created,
                            msisdn: profile.msisdn,
                            network: profile.network,
                            maxStake: profile.max_stake ? profile.max_stake : 0,
                            singleBetMaxStake: profile.single_bet_max_stake ? profile.single_bet_max_stake : 0,
                            multibetBetMaxStake: profile.multibet_bet_max_stake ? profile.multibet_bet_max_stake : 0,
                            points: profile.points ? profile.points : 0,
                            maxDailyPossibleWin: profile.max_daily_possible_win ? profile.max_daily_possible_win : 0,
                            balance: vm.formatNumber(Number(profile.balance)),
                            bonus: vm.formatNumber(Number(profile.bonus_balance)),
                            fullName: firstName + ' ' + middleName + ' ' + lastName,
                            betCount: vm.formatNumber(Number(profile.bet_count)),
                            totalOdd: vm.formatNumber(Number(profile.total_odd)),
                            stake: vm.formatNumber(Number(profile.bet_amount)),
                            referals: vm.formatNumber(Number(profile.referals)),
                            amount: vm.formatNumber(Number(profile.amount)),
                            deposit: profile.deposit ? vm.formatNumber(profile.deposit) : 0,
                            withdraw: profile.withdraw ? vm.formatNumber(Number(profile.withdraw)) : 0,
                            blacklistt: profile.blacklist,
                            reason: profile.reason,
                        };



                        vm.profile_data = profileData;
                        vm.blacklist=profileData.blacklistt;
                        vm.selectedProfile = profileData.profileID;


                        setTimeout(function(){
                             // $(".ui.menu").tab();
                            $('.tabular.menu .item').tab();
                        }, 200)

                        vm.$refs.vuetable.reload();
                        vm.$refs.vuetablebets.reload();
                        vm.$refs.deposit.reload();
                        vm.$refs.kiron.reload();
                        vm.$refs.withdraw.reload();
                        vm.$refs.transactions.reload();



                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },



            //
            searchBets: function () {

                this.moreParams.filter = this.search_bet;
                this.moreParams.profile = this.profile_data.profileID;
                this.$refs.vuetable.reload();
            },

            searchBetss: function () {

                this.moreParamss.filter = this.search_bets;
                this.moreParamss.profile = this.profile_data.profileID;
                this.$refs.vuetablebets.reload();
            },


            onPaginationData(paginationData) {
                this.$refs.pagination.setPaginationData(paginationData);
                this.$refs.paginationInfo.setPaginationData(paginationData);

            },

            onPaginationDataBets(paginationData) {
                this.$refs.paginationBets.setPaginationData(paginationData);
                this.$refs.paginationInfoBets.setPaginationData(paginationData);

            },

            onPaginationDataVirtual(paginationData) {
                this.$refs.paginationVirtual.setPaginationData(paginationData);
                this.$refs.paginationInfoVirtual.setPaginationData(paginationData);

            },

            onPaginationDataKiron(paginationData) {
                this.$refs.paginationKiron.setPaginationData(paginationData);
                this.$refs.paginationInfoKiron.setPaginationData(paginationData);

            },

          onPaginationDataInstant(paginationData) {
            this.$refs.paginationInstant.setPaginationData(paginationData);
            this.$refs.paginationInfoInstant.setPaginationData(paginationData);

          },

            onPaginationDataTrans(paginationData) {
                this.$refs.paginationTrans.setPaginationData(paginationData);
                this.$refs.paginationInfoTrans.setPaginationData(paginationData);

            },

            onPaginationDataTrans1(paginationData) {
                this.$refs.paginationTrans1.setPaginationData(paginationData);
                this.$refs.paginationInfoTrans1.setPaginationData(paginationData);

            },
            onPaginationDataDeposit(paginationData) {
                this.$refs.paginationDeposit.setPaginationData(paginationData);
                this.$refs.paginationInfoDeposit.setPaginationData(paginationData);

            },
            onPaginationDataWithdraw(paginationData) {
                this.$refs.paginationWithdraw.setPaginationData(paginationData);
                this.$refs.paginationInfoWithdraw.setPaginationData(paginationData);

            },

            onPaginationDataWithdraw1(paginationData) {
                this.$refs.paginationWithdraw1.setPaginationData(paginationData);
                this.$refs.paginationInfoWithdraw1.setPaginationData(paginationData);

            },
            onRowClass (dataItem, index) {
                return (dataItem.isOverdue) ? 'color-red' : 'color-white'
            },

            dataManager(sortOrder, pagination) {

                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {
                    local = _.orderBy(
                        local,
                        sortOrder[0].sortField,
                        sortOrder[0].direction
                    );
                }

                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );
                console.log('pagination:', pagination)
                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },


            onChangePage(page) {
                this.$refs.vuetable.changePage(page)

            },
            onChangePageBets(page) {
                this.$refs.vuetablebets.changePage(page)

            },

            onChangePageVirtual(page) {

                this.$refs.virtual.changePage(page)
            },

            onChangePageKiron(page) {

                this.$refs.kiron.changePage(page)
            },

          onChangePageInstant(page) {

            this.$refs.instant.changePage(page)
          },



            onChangePagedeposits(page) {

                this.$refs.deposit.changePage(page)
            },
            onChangePagewithdrawals(page) {

                this.$refs.withdraw.changePage(page)
            },

            onChangePagewithdrawals1(page) {

                this.$refs.withdraw1.changePage(page)
            },

            onChangePagetransactions(page) {

                this.$refs.transactions.changePage(page)
            },

            onChangePagetransactions1(page) {

                this.$refs.transactionss.changePage(page)
            },

            onLoadingT() {
                this.loadingT = true

            },
            onLoadedT() {
                this.loadingT = false

            },

            onLoadingT1() {
                this.loadingT1 = true

            },
            onLoadedT1() {
                this.loadingT1 = false

            },

            onLoadingD() {
                this.loadingD = true

            },
            onLoadedD() {
                this.loadingD = false

            },

            onLoadingW() {
                this.loadingW = true

            },
            onLoadedW() {
                this.loadingW = false

            },

            onLoadingW1() {
                this.loadingW1 = true

            },
            onLoadedW1() {
                this.loadingW1 = false

            },
            onLoadingV() {
                this.loadingV = true
            },
            onLoadedV() {
                this.loadingV = false

            },


            onLoadingK() {
                this.loadingK = true

            },

            onLoadedK() {
                this.loadingK = false

            },


          onLoadingI() {
            this.loadingI = true

          },

          onLoadedI() {
            this.loadingI = false

          },

            onLoadingB() {
                this.loadingB = true

            },
            onLoadedB() {
                this.loadingB = false

            },



        },


        watch:{


            // data(newVal, oldVal) {
            //     this.$refs.vuetable.reload();
            // }

            selectedProfile(newVal , oldval) {

                  if (newVal) {
                      var vm= this;



                    // vm.$refs.vuetable.reload();
                    // vm.$refs.vuetablebets.reload();
                    // vm.$refs.deposit.reload();
                    // vm.$refs.withdraw.reload();
                    // vm.$refs.virtual.reload();
                    // vm.$refs.transactions.reload();

                  //  console.log("selectedProfile",newVal)


                    vm.moreParams.profile =  vm.profile_data.profileID;
                    vm.moreParamss.profile =  vm.profile_data.profileID;
                    vm.virtualParams.profile =  vm.profile_data.profileID;
                    vm.kironParams.profile =  vm.profile_data.profileID;
                    vm.instantParams.profile =  vm.profile_data.profileID;
                    vm.ticketParams.profile = vm.profile_data.msisdn;
                    vm.depositParams.profile = vm.profile_data.msisdn;
                    vm.withdrawParams.profile = vm.profile_data.profileID;
                      vm.trans_params.profile =vm.profile_data.profileID;
                      vm.trans_params1.profile =vm.profile_data.profileID;




                      vm.moreParams.token =  vm.token;
                      vm.moreParamss.token =  vm.token;
                      vm.virtualParams.token =  vm.token;
                      vm.instantParams.token =  vm.token;
                      vm.depositParams.token =  vm.token;
                      vm.withdrawParams.token =  vm.token;
                      vm.ticketParams.token =  vm.token;
                      vm.trans_params.token =  vm.token;
                      vm.trans_params1.token =  vm.token;
                       vm.kironParams.token =  vm.token;


                    vm.$refs.vuetable.reload();
                       vm.$refs.vuetablebets.reload();
                      vm.$refs.deposit.reload();
                       vm.$refs.withdraw.reload();
                       vm.$refs.withdraw1.reload();
                      vm.$refs.virtual.reload();
                      vm.$refs.transactions.reload();
                      vm.$refs.kiron.reload();
                      vm.$refs.instant.reload();


                } else {
                    this.profile_data = '';
                }
            },


            selectedTab(newVal , oldval) {

                if (newVal) {
                    var vm= this;




                    vm.moreParams.profile =  vm.profile_data.profileID;
                    vm.moreParamss.profile =  vm.profile_data.profileID;
                    vm.moreParamss.token =  vm.token;
                    vm.moreParams.token =  vm.token;

                    vm.virtualParams.profile =  vm.profile_data.profileID;
                    vm.virtualParams.token =  vm.token;
                    vm.kironParams.token =  vm.token;
                    vm.kironParams.profile =  vm.profile_data.profileID;

                  vm.instantParams.token =  vm.token;
                  vm.instantParams.profile =  vm.profile_data.profileID;



                    vm.depositParams.profile = vm.profile_data.msisdn;
                    vm.depositParams.token =  vm.token;


                    vm.withdrawParams.profile = vm.profile_data.profileID;
                    vm.withdrawParams.token =  vm.token;

                    vm.ticketParams.profile = vm.profile_data.msisdn;
                    vm.ticketParams.token =  vm.token;





                    vm.trans_params.profile =vm.profile_data.profileID;
                    vm.trans_params.token =  vm.token;
                    vm.trans_params1.profile =vm.profile_data.profileID;
                    vm.trans_params1.token =  vm.token;

                    vm.$refs.vuetable.reload();
                    vm.$refs.vuetablebets.reload();
                    vm.$refs.deposit.reload();
                    vm.$refs.kiron.reload();
                    vm.$refs.instant.reload();
                    vm.$refs.withdraw.reload();
                    vm.$refs.withdraw1.reload();
                    vm.$refs.virtual.reload();
                    vm.$refs.transactions.reload();
                    vm.$refs.transactionss.reload();


                } else {
                    this.profile_data = '';
                }
            }
        }

    }

</script>