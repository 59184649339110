<template>
    <div class="l-login">

        <h2>Login</h2>
        <div class="l-form">

            <form v-on:submit.prevent="goToVerify()" method="POST" class="ui form padding-top-lg">
                <div class="field">
                    <label>Phone Number</label>
                    <input style="font-size: 16px !important;" class="" type="text" placeholder="Enter username" v-model="username" required />
                </div>
                <div class="field">
                    <label>Password</label>
                    <input style="font-size: 16px !important;" class="" type="password" placeholder="Enter password" v-model="password" required />
                </div>
                <div class="field" v-if="loginstatus">
                    <button type="submit" class="ui button fluid bg-primary">Login</button>
                </div>

                <div class="field" v-if="!loginstatus">
                    <button disabled  class="ui button fluid bg-primary">Please wait</button>
                </div>
            </form>

            <div v-if="alert_visible" class="ui positive message transition visible">
                <i class="close icon"></i>
                {{alert_message}}
            </div>
        </div>

    </div>
</template>



<script>

    import {config} from '../config/config';

    export default {
        components: {
            config
        },
        name: 'Login',
        data() {
            return {
                username: '',
                loginstatus: true,
                password: '',
                loading: [],
                alert_visible: false,
                alert_message: ''
            }
        },

        mounted(){
            localStorage.removeItem("timer");
            localStorage.removeItem("user_info");
            localStorage.removeItem("username");
        },
        methods: {
            dismiss: function () {
                this.alert_visible = false;
            },
            goToVerify() {

                this.loginstatus=false;

                var required = [];
                if (!this.username) {
                    required.push('username');

                }
                if (!this.password) {
                    required.push('password');
                }

                if (required.length > 0) {
                    this.alert_message = 'Fields required ---' + required.join();
                    this.loading = [];
                    this.alert_visible = true;
                    return;
                }
                this.alert_visible = false;
                var vm = this;
                let loginData = {
                    'username': vm.username,
                    'password': vm.password
                }

                var now = new Date();
                now.setMinutes(now.getMinutes() + 120); // timestamp
                now = new Date(now); // Date object

                $.post({
                    url: config.baseURL+'odibets/auth',
                    type: "POST",
                    data:  JSON.stringify(loginData),
                    success: function(data){
                        vm.loading = [];

                        vm.loginstatus=true;


                        if(data.status_code == 200) {
                            vm.loginstatus=true;

                            let authenticated = data.data.authenticated;
                            if (authenticated) {
                                let user = data.data.data;

                                let obj = JSON.stringify(user);


                                var jsonData = JSON.parse(obj);
                                var role=jsonData.role
                                var user_id=jsonData.id;


                                console.log("user info",role)


                                // if((role==2 || role==10)){
                                //
                                //     window.localStorage.setItem('user_info', obj);
                                //     window.localStorage.setItem('timer', now);
                                // }

                                window.localStorage.setItem('username', vm.username);
                                window.localStorage.setItem('idd', user_id);

                                const Toast = vm.$swal.mixin({
                                    toast: true,
                                    position: 'top-center',
                                    showConfirmButton: false,
                                    timer: 2000
                                });
                                Toast.fire({
                                    type: 'success',
                                    title: "Welcome back"
                                })

                                // if(role == 2){
                                //      vm.$router.push({name: 'dash-ke'});
                                //
                                // }else if(role == 4 ){
                                //      vm.$router.push({name: 'verify'});
                                // }
                                // else if(role == 5 ){
                                //     vm.$router.push({name: 'verify'});
                                // }
                                // else if(role == 3 ){
                                //     vm.$router.push({name: 'verify'});
                                //
                                // }
                                // else if (role == 10 ){
                                //     vm.$router.push({name: 'report'});
                                // }
                                vm.$router.push({name: 'verify'});

                            } else {
                                vm.alert_message = data.data.error;
                                vm.alert_visible = true;
                                vm.$router.push({name: 'login'});
                            }
                        } else {
                            vm.loginstatus=true;
                            let desc = data.status_description;
                            const Toast = vm.$swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000
                            });
                            Toast.fire({
                                type: 'error',
                                title: desc
                            })
                        }
                    },
                    error: function(jQxhr, status){
                        vm.loginstatus=true;
                        vm.loading = [];
                        vm.alert_message = 'Error while processing!';
                        vm.alert_visible = true;
                        console.log(jQxhr, status);
                    }
                })

            }

        }
    }



</script>