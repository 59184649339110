<template>

    <div class="ui modal" id="ticket">
        <i class="close icon"></i>

        <div class="header">
            Raise Ticket
        </div>
        <div class="content">
            <div class="ui form">
                <div class="equal width fields">
                    <div class="field">
                        <label>Sports</label>
                        <select v-model="selectedSport"  class="ui  dropdown">
                            <option value="1">Pending Bet</option>
                            <option value="2">Pending Payout</option>
                            <option value="2">Wrong   Resulting</option>
                            <option value="4">Balance  Deducted</option>
                            <option value="9">Winner  Approval</option>
                            <option value="10">Bet  Review</option>
                            <option value="11">Score  Update</option>
                        </select>

                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="ui deny button">
                Cancel
            </div>
            <div  @click="raiseTicket" class="ui green left labeled icon button">
                <i class="checkmark icon"></i> Raise  Ticket
            </div>
        </div>


    </div>


</template>

<script>


    import {config} from '../config/config';

    export default {

        components: {
            config
        },
        props:{
            data:{
                type: Object,


            }
        },


        data() {
            return {
                user_id:'',
                role:'',
                token:'',
                profile_data:this.data,
                selectedSport:'',
                pending_market: [],
                bet_id:'',
                msisdn:'',
                amount:'',
                selected_pending_markett:'',

            }
        },


        watch:{
            data(newVal,oldVal){


                this.profile_data = newVal;
                this.bet_id=this.profile_data.bet_id
                this.msisdn=this.profile_data.msisdn
                this.amount=this.profile_data.amount

            }
        },
        computed: {


        },
        created() {

            // this.getWrongMarkets()

            $('.ui.modal#sports').modal({
                closable: true
            }).modal('hide');

            this.bet_id=this.profile_data.bet_id
            this.msisdn=this.profile_data.msisdn
            this.amount=this.profile_data.amount

            // this.pending_market=this.match_data.selected_data



        },

        mounted() {
            //var headerHeight = document.getElementById('header').offsetHeight;
            //document.getElementById('main-content').style.top = headerHeight + 'px';

            var vm = this;


            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=jsonData.token;


            vm.bet_id=vm.profile_data.bet_id
            vm.msisdn=vm.profile_data.msisdn
            vm.amount=vm.profile_data.amount



        },


        methods: {

            raiseTicket:function () {


                var vm=this;

                if (!vm.selectedSport) {
                    vm.$swal("Missing!", "All fields are REQUIRED!", "warning");
                    return;
                }

                vm.$swal.fire({
                    title: 'Raise  Ticket?',
                    text: "Verify and Proceed , Raise Ticket!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor:  '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Raise Ticket'
                }).then((result) => {
                    if (result.value) {
                        var dataa = {
                            bet_id: vm.bet_id,
                            msisdn: vm.msisdn,
                            amount: vm.amount,
                            token: vm.token,
                            reference_type: vm.selectedSport
                        };
                        $.ajax({
                            url: config.baseURL+'portal/raise/ticket',
                            type: 'POST',
                            data: JSON.stringify(dataa),


                            success: function (response, textStatus, jQxhr) {



                                $('.ui.modal#ticket').modal({
                                    closable: true
                                }).modal('tickets');

                                if (response.status_code == 200) {
                                    $('#ticket').modal('hide');
                                    vm.$swal("Success!", response.status_description, "success");

                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }
                })


            },










        },

    }
</script>