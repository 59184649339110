<template>
    <div class="l-container-content">
        <div class="row">
            <div class="col-6">
                <div class="text-title">
                    <h2 class="text-medium">Jackpot Winners</h2>
                </div>
            </div>

            <div class="col-6 text-right">

                <!--<div class="l-input inline">-->
                    <!--<i class=" search icon"></i>-->
                    <!--<input v-on:keyup.enter="searchMatches" type="text" placeholder="Search..." v-model="search_match">-->
                <!--</div>-->
                <!--<div class="l-input inline">-->
                    <!--<i class="icon-calendar"></i>-->
                    <!--<input name="dates" placeholder="Choose date" />-->
                <!--</div>-->
            </div>
        </div>

        <div class="row">
            <div id="table-wrapper" style="position:relative">
                <loader v-show='loading' />
                <vuetable ref="vuetable"

                          :api-url="vueurl"
                          :fields="columns"
                          :sort-order="sortOrder"
                          track-by="parent_match_id"
                          :append-params="moreParams"
                          :per-page="10"
                          @vuetable:pagination-data="onPaginationData"
                          @vuetable:loading="onLoading"
                          @vuetable:loaded="onLoaded"
                >


                    <div slot="Allocate" slot-scope="props">
                        <button
                                class="ui green button"
                                @click="onActionVoid('view-item', props.rowData)"
                        >
                            <i class="zoom icon"></i>
                        </button>
                    </div>
                </vuetable>

            </div>

            <div class="vuetable-pagination ui basic segment grid">
                <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
                <vuetable-pagination ref="pagination"
                                     @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
            </div>

        </div>

        <modalResulting v-if="selected_data" :data="selected_data"/>

    </div>
</template>

<script>
    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import MyDetailRow from "../components/resultingModal";
    import loader from "@/components/loader"
    import modalResulting from "../components/resultingModal";
    import XLSX from 'xlsx';
    import {config} from '../config/config';

    export default {
        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo, MyDetailRow, loader,modalResulting,XLSX,config
        },
        name: 'resulting',

        data() {
            return {
                vueurl:config.baseURL+'portal/jackpot/winners',
                columns: [
                    {
                        name: 'bet_id',
                        title: 'Bet Id',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned',
                    },
                    {
                        name: 'jp_week_id',
                        title: 'Jp Id',
                    },
                    {
                        name: 'msisdn',
                        title: 'Phone',
                    },
                    {
                        name: 'events_won',
                        title: 'Events Won',
                    },
                    {
                        name: 'possible_win',
                        title: 'Possible Win',
                    },
                    {
                        name: 'allocated_by',
                        title: 'Allocated_by',
                        dataClass: 'center aligned',
                    },

                    'Allocate'
                ],
                detailRow: MyDetailRow,
                loading: true,
                range:'',
                is_partial_resulting:false,
                search_match:'',
                loadOnStart: true,
                sortOrder: [{
                    field: 'created',
                    direction: 'desc'
                }],
                moreParams: {
                    start: '',
                    filter: '',
                    end: ''
                },
                sport_id: 1,
                selected_data: {},
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'glyphicon glyphicon-chevron-up',
                        descendingIcon: 'glyphicon glyphicon-chevron-down',
                        handleIcon: 'glyphicon glyphicon-menu-hamburger',
                    },
                    pagination: {
                        infoClass: 'pull-left',
                        wrapperClass: 'vuetable-pagination pull-right',
                        activeClass: 'btn-primary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                }

            }
        },
        mounted(){
            //var headerHeight = document.getElementById('header').offsetHeight;
            //document.getElementById('main-content').style.top = headerHeight + 'px';

            var vm = this;

            var start = moment();
            var end = moment();

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
            }

            $('input[name="dates"]').daterangepicker({
                startDate: start,
                endDate: end,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);



            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.username;

            vm.role=rolee;
            vm.user_id=userr;



        },


        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.reload();
            },
            range(){

                var start = this.range[0];
                var end = this.range[1];
                start= moment(start).format('YYYY-MM-DD');
                end= moment(end).format('YYYY-MM-DD');
                this.moreParams.start = start;
                this.moreParams.end = end;
                this.$refs.vuetable.reload();
            }
        },
        methods: {

            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");

                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },

            onLoading() {
                this.loading = true
                console.log('loading... show your spinner here')
            },
            onLoaded() {
                this.loading = false
                console.log('loaded! .. hide your spinner here')
            },

            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                vm.moreParams.start = start;
                vm.moreParams.end = end;

                this.$refs.vuetable.reload();

                console.log("start date filter",start)
                console.log("end date filter ",end)
            },

            searchMatches:function(){
                this.moreParams.filter = this.search_match;
                this.$refs.vuetable.reload();
            },
            getsport: function () {
                var vm = this;

                var data = {
                    parent_match_id: vm.parent_match_id,

                };


                $.ajax({
                    url: config.baseURL+'resulting/sports',
                    type: 'POST',
                    data: JSON.stringify(data),
                    success: function (response, textStatus, jQxhr) {
                        console.log("Swap Matche: " + JSON.stringify(response));
                        vm.sports_info = response.data;
                    },
                    error: function (jqXhr, textStatus, error) {

                        vm.$swal("Error!", error, "error");
                    }
                });
            },

            dataManager(sortOrder, pagination) {
                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {
                    console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                    local = _.orderBy(
                        local,
                        sortOrder[0].sortField,
                        sortOrder[0].direction
                    );
                }

                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );
                console.log('pagination:', pagination)
                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },


            showDetailRow: function (value) {

                $(".ui.dropdown").dropdown();

                //   value._showDetails = !value._showDetails;
                // var icon = this.$refs.vuetable.isVisibleDetailRow(value) ? 'minus' : 'plus';
                // return [
                //     '<a class="show-detail-row">',
                //     '<i class=" circle ' + icon + ' icon"></i>',
                //     '</a>'
                // ].join('');

                this.$refs.vuetable.toggleDetailRow(value.parent_match_id)
            },

            // onCellClicked (data) {
            //     this.$refs.vuetable.toggleDetailRow(data.parent_match_id)
            //
            // },

            onCellClicked (data, field, event) {
                console.log('cellClicked: ', data.data)
                this.$refs.vuetable.toggleDetailRow(data.data.parent_match_id)
            },


            onPaginationData(paginationData) {
                this.$refs.paginationInfo.setPaginationData(paginationData)
                this.$refs.pagination.setPaginationData(paginationData)
            },


            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },

            onActionClicked(action, data) {

                console.log("data console",data)
                this.selected_data=data

                console.log("slot actions: on-click"+JSON.stringify(data));
                $('#modal-resulting').modal("show");//.modal({centered: false}).modal("show");
            },

            onActionVoid(action, data) {

                var vm = this;

                if (action == 'view-item') {

                    vm.$swal({
                        title: "Allocate Money ",
                        text: "If you are sure, type in the  Allocate Money:",
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Allocate Money',
                        showLoaderOnConfirm: true,
                        preConfirm: (priority) => {
                            console.log("finally", priority)
                            if (!priority) {
                                return;
                            }
                            var Voiddata = {
                                id: data.winner_id,
                                amount: priority,
                                user_id: vm.user_id
                            };
                            console.log("Payload | " + JSON.stringify(Voiddata));
                            $.ajax({
                                url: config.baseURL+'portal/jackpot/allocate',
                                type: 'POST',
                                data: JSON.stringify(Voiddata),
                                success: function (response, textStatus, jQxhr) {
                                    console.log("Swap Matche: " + JSON.stringify(response));
                                    if (response.status_code == 200) {
                                        vm.$swal("Success!", response.status_description, "success");

                                        window.location.reload()

                                        $('#switch-modall').modal('hide');
                                    } else {
                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }
                    });

                } else {


                }
            }



        },
        created: function () {
            var a = moment([2018, 9, 24]);
            var b = moment();
            var diff = b.diff(a, 'days') + 1;
            this.currentDay = 'DAY ' + diff; // 1
            this.checktimer();
            // this.getsport()
        },
    }

</script>