<template>

    <div  class="ui modal" id="vbetslip">
        <i class="close icon"></i>
        <div class="header">
            <div class="ui equal width grid">
                <div class="column">
                    Jackpot System ID: {{selected_data.betData.jp_bet_id}}<br/>
                    <h6>Stake {{selected_data.betData.bet_amount}}/-</h6>
                    <span>ODDS: {{selected_data.betData.total_odd}}</span>
                </div>
                <div class="right aligned column">
                    <strong v-show="selected_data.betData.bet_status==1">PENDING</strong>
                    <strong v-show="selected_data.betData.bet_status==3" style="color:#DB2828;">LOST</strong>
                    <strong v-show="selected_data.betData.bet_status==6" styel="color:#e1e1e1;">VOIDED</strong>
                    <strong v-show="selected_data.betData.bet_status==4 || selected_data.betData.status==24" styel="color:#2185D0;">CANCELLED</strong>
                    <strong v-show="selected_data.betData.bet_status==5" style="color:#21BA45;">WON <div class="detail">Ksh. {{selected_data.betData.possible_win}}</div></strong>
                </div>
            </div>
        </div>

        <div class="content bg-grey-light">
            <div class="ui segments bg-white" v-for="betslip in selected_data.betslipData" :key="betslip.jp_bet_slip_id">
                <div class="ui segment">
                    <div class="ui grid">
                        <div class="seven wide right aligned column">
                            <h3>{{betslip.home_team}}</h3>
                        </div>
                        <div class="two wide center aligned column">
                            <h3><strong>{{betslip.ft_score}}</strong></h3>
                        </div>
                        <div class="seven wide column">
                            <h3>{{betslip.away_team}}</h3>
                        </div>
                    </div>
                </div>
                <div class="ui horizontal segments">
                    <div class="ui segment">
                        <h4>Start Time</h4>
                        <p>{{betslip.start_time}}</p>
                    </div>
                    <div class="ui segment">
                        <h4>Competition</h4>
                        <p>{{betslip.competition_name}}</p>
                    </div>
                    <div class="ui segment">
                        <h4>Type: Bet</h4>
                        <p>Market: {{betslip.sub_type_id}}</p>
                    </div>
                    <div class="ui segment">
                        <h4>Pick:{{betslip.outcome_name}}</h4>
                        <p>Odd Value: {{betslip.odd_value}}</p>
                    </div>

                    <div class="ui inverted segment" v-bind:class="[betslip.bet_status]">
                        <!--<p>{{winning_outcome}}</p>-->
                    </div>

                </div>


            </div>


        </div>

    </div>


</template>

<script>



    export default {
        components: {},
        props:{
            data:{
                type: Object

            }
        },


        data() {
            return {

                final: '',
                sport_id: 1,
                selected_data:this.data

            }
        },

        watch:{
            data(newVal,oldVal){

                this.selected_data = newVal;
            }
        },
        computed: {

        },
        mounted() {

            $('.ui.modal#new-user').modal({
                closable: true
            }).modal('hide');

        },

        methods: {


        },

    }
</script>