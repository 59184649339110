<template>
    <div class="ui tiny modal" id="jpaward">

        <i class="close icon"></i>
        <div class="header">
            Jp Awards
        </div>
        <div class="scrolling content">
            <div class="ui grid">
                <div class="column">
                    <div class="ui form">
                        <div class="equal width fields">
                            <div class="field">
                                <label>Events Won</label>
                                <div class="ui labeled input">
                                    <input type="text" style="text-transform: uppercase;" placeholder="Enter jp events to be awarded" v-model="events">
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label> Amount</label>
                            <div class="ui fluid input">
                                <input type="text" class="" placeholder="Provide  Amount" v-model="amount">
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <div class="actions">
            <div class="ui deny button">
                Cancel
            </div>
            <div  @click="setJpAward" class="ui green left labeled icon button">
                <i class="checkmark icon"></i> Submit
            </div>
        </div>
    </div>
</template>
<script>

    import {config} from '../config/config';

    export default {

        components: {
            config
        },

        props:{
            data:'',
            token:''
        },

        mounted(){
            var vm = this;

            var obj = window.localStorage.getItem('user_info');
            var username = window.localStorage.getItem('username');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role
            var userr=jsonData.id
            var tokenn=jsonData.token

            vm.role=rolee;
            vm.user_id=userr;
            vm.username=username;
            vm.token=tokenn;




        },


        data() {
            return {

                user_id:'',
                username:'',
                role:'',
                token:'',
                'events':'',
                'amount':'',
                'jp_week':this.data

            }
        },
        watch:{
            data(newVal,oldVal){

                this.deposit_data = newVal;
            }
        },
        methods:{
            closeModal(){
                $(".ui.modal#deposit").modal("hide");
            },

            setJpAward: function () {
                var vm = this;
                if (!vm.events || !vm.amount ) {
                    vm.$swal("Missing!", "All fields are required!", "warning");
                    return;
                }

                if (vm.dep_loader) {
                    return;
                }

                vm.dep_loader = 'loading';
                var data = {
                    amount: vm.amount,
                    events: vm.events,
                    jp_id: vm.jp_week,
                    user_id: vm.user_id,
                    username: vm.username,
                    token: vm.token
                };



                $.ajax({
                    url: config.baseURL+'portal/create/jpawards',
                    type: 'POST',
                    data: JSON.stringify(data),
                    success: function (response, textStatus, jQxhr) {


                        if (response.status_code == 200) {
                            vm.$swal("Success!", response.status_description, "success");
                            vm.amount = '';
                            vm.events = '';

                            $('.ui.modal#jpaward').modal({
                                closable: true
                            }).modal('hide');

                        } else {
                            vm.$swal("Error!", response.status_description, "error");
                            $('#jpaward').modal('hide');
                            $('.ui.modal#jpaward').modal({
                                closable: true
                            }).modal('hide');
                        }
                    },
                    error: function (jqXhr, textStatus, error) {
                        vm.$swal("Error!", error, "error");
                        $('.ui.modal#jpaward').modal({
                            closable: true
                        }).modal('hide');
                    }
                });
            },
        }
    }
</script>