<template>
    <div class="l-main-content">


        <div class="container">
            <div class="row margin-top-md">
                <div class="col">
                    <label class="l-select-label">Select recipient</label>
                    <select v-model="recipients" class="l-select">
                        <option value="" selected>Select recipients</option>
                        <option  value="test">Test</option>
                        <option  value="activeplayers">Active</option>
                        <option  value="inactiveplayers">InActive</option>
                        <option  value="kiron">Kiron</option>
                        <option  value="casino">Casino</option>
                        <option  value="ussdplayers">USSD/SMS</option>
                        <option  value="opera">Opera</option>
                    </select>
                </div>
            </div>
            <div class="row margin-top-md">
                <div class="col l-textarea">
                    <label class="padding-bottom-sm">Message to send</label>
                    <textarea v-model="message" class="" rows="10"></textarea>
                    <div class="message"><strong>{{message_page_count}}</strong> pages / <strong>{{message_characters}}</strong> characters</div>


                    <div>

                        <button class=""  @click="sendMessage">Send message</button>
                        <svg class="spinner" viewBox="0 0 50 50" :class="{'spin': spin == true}">
                            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="7.5"></circle>
                        </svg>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>


<script>

    import {config} from '../config/config';

    export default {
        components: {
            config
        },
        name: 'sms',

        data() {
            return {

                message_characters:0,
                message_page_count:0,
                message:'',
                recipients:'',
                user_id:'',
                role:'',
                token:'',
                spin: false

            }
        },




        mounted(){
            //var headerHeight = document.getElementById('header').offsetHeight;
            //document.getElementById('main-content').style.top = headerHeight + 'px';

            var vm = this;


            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;





            if((vm.role  !=2 && vm.role !=3)){

                alert(vm.role);

                vm.$router.push({name: 'login'});
            }





        },


        watch: {

            message: function (value, old) {
                if (value) {
                    this.message_characters = value.length;
                } else {
                    this.message_characters = 0;
                }

                this.message_page_count = _.ceil(this.message_characters / 160);
            },


        },
        methods: {



            sendMessage(action, data) {

                var vm = this;

                vm.spin = true;
                if (!vm.recipients || !vm.message) {
                    vm.$swal("Missing!", "All fields are REQUIRED!", "warning");
                    return;
                }
                vm.$swal.fire({
                    title: 'Bulk Message ?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Send Message'
                }).then((result) => {

                    if (result.value) {
                        var Voiddata = {
                            message: vm.message,
                            target: vm.recipients,
                            token: vm.token,
                        };

                        $.ajax({
                            url: config.baseURL+'portal/bulk/sms',
                            type: 'POST',
                            data: JSON.stringify(Voiddata),
                            success: function (response, textStatus, jQxhr) {

                                vm.spin = false;

                                if (response.status_code == 200) {

                                    vm.message='';
                                    vm.recipients='';
                                    vm.$swal("Success!", response.status_description, "success");


                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.spin = false;

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }else {

                    }
                })
            }



        },

    }

</script>