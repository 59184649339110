<template>
    <div class="l-container-navigation main">
        <ul  v-if="(role === '2'  || role === '3' )&& this.$route.name == 'bets-approvals' || this.$route.name == 'jackpot-approvals' || this.$route.name == 'jackpot-allocate'">
            <li :class="{'active': this.$route.name == 'bets-approvals'}"><router-link :to="{name: 'bets-approvals'}">Winner Approvals</router-link></li>
            <!--<li :class="{'active': this.$route.name == 'jackpot-approvals'}"><router-link :to="{name: 'jackpot-approvals'}">Jackpot Approvals</router-link></li>-->
            <!--<li :class="{'active': this.$route.name == 'jackpot-allocate'}"><router-link :to="{name: 'jackpot-allocate'}">Jackpot Winners Allocation</router-link></li>-->
        </ul>
        <ul  v-if="( role === '2' || role === '3') && this.$route.name == 'matches' || this.$route.name == 'matches.vue-virtuals' || this.$route.name == 'matches.vue-resulting' || this.$route.name == 'matches.vue-print' || this.$route.name == 'matches.vue-booklet' || this.$route.name == 'matches.vue-real' || this.$route.name == 'matches.vue-competition'  || this.$route.name == 'kiron-competition' || this.$route.name == 'teams-competition' || this.$route.name == 'matches-freebet'">
            <li :class="{'active': this.$route.name == 'matches.vue-resulting'}"><router-link :to="{name: 'matches.vue-resulting'}">Resulting</router-link></li>
            <li :class="{'active': this.$route.name == 'matches.vue-real'}"><router-link :to="{name: 'matches.vue-real'}">Matches</router-link></li>
            <li :class="{'active': this.$route.name == 'matches.vue-virtuals'}"><router-link :to="{name: 'matches.vue-virtuals'}">Matches Archieve</router-link></li>
            <!--<li :class="{'active': this.$route.name == 'matches.vue-print'}"><router-link :to="{name: 'matches.vue-print'}">Print Matches</router-link></li>-->
            <!--<li :class="{'active': this.$route.name == 'matches.vue-booklet'}"><router-link :to="{name: 'matches.vue-booklet'}">Markets</router-link></li>-->
            <li :class="{'active': this.$route.name == 'matches.vue-competition'}"><router-link :to="{name: 'matches.vue-competition'}">Competition</router-link></li>
            <!--<li :class="{'active': this.$route.name == 'kiron-competition'}"><router-link :to="{name: 'kiron-competition'}">Kiron</router-link></li>-->
            <!--<li :class="{'active': this.$route.name == 'teams-competition'}"><router-link :to="{name: 'teams-competition'}">teams</router-link></li>-->
            <li :class="{'active': this.$route.name == 'matches-freebet'}"><router-link :to="{name: 'matches-freebet'}">Freebet-Matches</router-link></li>
        </ul>
        <ul  v-if=" (role === '2'  || role === '3') && this.$route.name == 'admin' || this.$route.name == 'users' || this.$route.name == 'influencer'">
            <li :class="{'active': this.$route.name == 'users'}"><router-link :to="{name: 'users'}">Users</router-link></li>
            <li :class="{'active': this.$route.name == 'influencer'}"><router-link :to="{name: 'influencer'}">Influencer</router-link></li>
        </ul>

        <ul  v-if="( role === '2' || role === '3') && this.$route.name == 'transactions' || this.$route.name == 'transactions-reconcile' ||  this.$route.name == 'transactions-deposit' || this.$route.name == 'transactions-withdraw'  || this.$route.name == 'transactions-logs' ">
            <!--<li :class="{'active': this.$route.name == 'transactions-reconcile'}"><router-link :to="{name: 'transactions-reconcile'}">Reconcile</router-link></li>-->
            <li :class="{'active': this.$route.name == 'transactions-logs'}"><router-link :to="{name: 'transactions-logs'}">Users Logs</router-link></li>
            <!--<li :class="{'active': this.$route.name == 'transactions-deposit'}"><router-link :to="{name: 'transactions-deposit'}">Deposit</router-link></li>-->
            <li :class="{'active': this.$route.name == 'transactions-withdraw'}"><router-link :to="{name: 'transactions-withdraw'}">Withdrawals</router-link></li>


        </ul>
    </div>
</template>


<script>


    export default {
        components: {

        },
        name: 'Navigation',


        mounted(){
            var vm = this;

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role

            vm.role=rolee;

            console.log("role at navigation",vm.role)



        },

        data() {
            return {
                role: '',

            }
        }


    }




</script>