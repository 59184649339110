<template>
    <div class="ui modal" id="bonus">

        <i class="close icon"></i>
        <div class="header">
            Award Bonus  Promotion
        </div>
        <div class="content bg-grey-lightest">

            <div class="ui form">
                <form class="form" enctype="multipart/form-data" method="POST">

                    <div class="sixteen wide column">
                        <h4 class="text-grey">Select file to Upload</h4>
                        <div class="ui segments">
                            <div class="ui secondary segment">
                                <input type="hidden" name="token" value="50000000" class="input"/>
                                Choose a file to upload: <input  name="uploadedfile" type="file" id="file_uploader"
                                                                 accept=".csv,.xls,.xlsx" required/><br/>
                            </div>
                        </div>
                    </div>

                    <div class="row padding-md"></div>
                </form>
                <!--<progress></progress>-->
            </div>

        </div>
        <div class="actions">
            <div class="ui deny button">
                Cancel
            </div>
            <div @click="uploadBonusFile" class="ui left labeled icon button">
                <i class="checkmark icon"></i>
                Upload Bonus file
                <svg class="spinner" viewBox="0 0 50 50" :class="{'spin': spin == true}">
                    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="7.5"></circle>
                </svg>
            </div>
        </div>
    </div>

</template>

<script>


    import {config} from '../config/config';

    export default {

        components: {
            config
        },


        data() {
            return {
                upload_loader:'',
                file: '',
                spin: false,
                user_id:'',
                role:'',
                token:'',
            }
        },


        watch:{

        },
        computed: {

        },
        created() {

            // this.getWrongMarkets()
            // console.log("modal is being loaded everytime");
            // $('.ui.modal#upload').modal({
            //     closable: true
            // }).modal('hide');

            var vm = this;

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;



        },


        methods: {

            closeModal(){
                $(".ui.modal#bonus").modal("hide");
            },

            handleFileUpload(){
                this.file = this.$refs.file.files[0];
            },


            uploadBonusFile(){
                var vm=this;
                vm.spin = true;

                var formData = new FormData();
                formData.append('uploadedfile', $('#file_uploader')[0].files[0]);
                formData.append('token', vm.token);

                $.ajax({
                    url: config.baseURLWEB+'dash/bonus/upload',
                    type: 'POST',
                    data: formData,
                    maxChunkSize: 10000000000,
                    processData: false, // tell jQuery not to process the data
                    contentType: false, // tell jQuery not to set contentType
                    success: function (data) {
                        vm.upload_loader = '';
                        vm.spin = false;
                        if (data.status_code == 200) {
                            vm.$swal("Response!", data.status_description, "info");
                            $('#bonus').modal('hide');
                        } else {
                            vm.$swal("Response!", data.status_description, "error");
                        }
                    }, error: function (request, textStatus, error) {
                        vm.upload_loader = '';
                        vm.spin = false;
                        vm.$swal("Response!", request.responseText, "error");
                    }
                });

            }


        },

    }
</script>