import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/login.vue'
import Verify from './views/verify.vue'
import index from './components/index.vue'
import Dashboard from "./dashboard/index";
import DashKE from "./dashboard/dash-ke";
import DashGH from "./dashboard/dash-gh";
import Profiles from "./profiles/index";
import Profiles1 from "./profiles/index1";
import Bets from "./bets/index";
import BetsApprovals from "./bets/approvals";
import BetsFree from "./bets/free";
import BetsRejected from "./bets/rejected";

import Matches from "./matches/index";
import Transactions from "./transactions/index";
import MatchesResulting from "./matches/resulting";
import MatchesVirtuals from "./matches/virtuals";
import MatchesReal from "./matches/matches";
import MatchesPrint from "./matches/print";
import MatchesBooklet from "./matches/booklet";
import MatchesCompetition from "./matches/competition";
import Deposit from "./transactions/deposit"
import Reconcile from "./transactions/reconcile"
import Withdraw from "./transactions/withdraw"
import Admin from "./admin/index";
import Users from "./admin/users";
import Influencer from "./admin/userinfluecer";
import Logs from "./transactions/logs"
import kiron from "./matches/kiron"
import temas from "./matches/teams"
import freebet from "./matches/freebet"
import Report from "./report/repo"
import VirualReport from "./report/reports"
import Affiliate from "./affiliate/index";
import Mpesa from "./mpesa/index";
import Tax from "./tax/index";
import Type from "./jackpot/jackpot";
import Jackpot from "./jackpot/type";
import JackpotDashboard from "./jackpot/index";
import MatchesJackpot from "./jackpot/matches";
import AwardsJackpot from "./jackpot/award";
import Markets from "./dashboard/market-stats";
import Sms from "./Sms/sms";

import JPDetails from "./jackpot/type";
import Ticket from "./ticket/ticket";
import TicketClosed from "./ticket/closedticket";
import Referral from "./referral/index";
import Instantreport from "./report/instantreport.vue";

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        //{ path: '', name: 'home', component: Home },
        { path: '', name: 'login', component: Login },
        { path: 'verify', name: 'verify', component: Verify },
        { path: '/', component: index, children: [
            { path: 'dashboard', component: Dashboard, children: [
                { path: 'ke', name: 'dash-ke', component: DashKE, meta: { name: 'Kenya' } },
                { path: 'gh', name: 'dash-gh', component: DashGH, meta: { name: 'Ghana' } },
            ] },
            { path: 'markets', name: 'markets', component: Markets, meta: { name: 'Markets' } },
            { path: 'profiles', name: 'profiles', component: Profiles, meta: { name: 'Profiles' } },
            { path: 'profiles1', name: 'profiles1', component: Profiles1, meta: { name: 'Profiles1' } },
            { path: 'report', name: 'report', component: Report, meta: { name: 'Report' } },
            { path: 'virtualReport', name: 'virtualReport', component: VirualReport, meta: { name: 'VirtualReport' } },
            { path: 'instantReport', name: 'instantReport', component: Instantreport, meta: { name: 'InstantReport' } },
            { path: 'affiliate', name: 'affiliate', component: Affiliate, meta: { name: 'Affiliate' } },
            { path: 'sms', name: 'sms', component: Sms, meta: { name: 'Sms' } },
            { path: 'jackpot', name: 'jackpot', component: Jackpot, meta: { name: 'Jackpots' } },
            { path: 'referral', name: 'referral', component: Referral, meta: { name: 'Referrals' } },

            { path: 'ticket', name: 'ticket', component: Ticket, meta: { name: 'ticket' } },
            { path: 'closed', name: 'closed', component: TicketClosed, meta: { name: 'closed' } },

            { path: 'type', name: 'type', component: Type, meta: { name: 'type' } },
                { path: 'jp', name: 'jp', component: JPDetails, meta: { name: 'Jackpot' } },



            { path: 'jackpot/dashboard', name: 'jackpot-dashboard', component: JackpotDashboard, meta: { name: 'Jackpots' } },
            { path: 'jackpot/matches', name: 'mjackpot', component: MatchesJackpot, meta: { name: 'Matches' } },
            { path: 'jackpot/awards', name: 'ajackpot', component: AwardsJackpot, meta: { name: 'Matches' } },



            { path: 'matches', component: Matches, children: [
                { path: 'resulting', name: 'matches.vue-resulting', component: MatchesResulting, meta: { name: 'Matches' } },
                { path: 'virtuals', name: 'matches.vue-virtuals', component: MatchesVirtuals, meta: { name: 'Matches' } },
                { path: 'real', name: 'matches.vue-real', component: MatchesReal, meta: { name: 'Matches' } },
               // { path: 'print', name: 'matches.vue-print', component: MatchesPrint, meta: { name: 'Matches' } },
                { path: 'booklet', name: 'matches.vue-booklet', component: MatchesBooklet, meta: { name: 'Matches' } },
                { path: 'competition', name: 'matches.vue-competition', component: MatchesCompetition, meta: { name: 'Matches' } },
             //   { path: 'kiron', name: 'kiron-competition', component: kiron, meta: { name: 'Matches' } },
             //   { path: 'teams', name: 'teams-competition', component: temas, meta: { name: 'Matches' } },
                { path: 'freebet', name: 'matches-freebet', component: freebet, meta: { name: 'Matches' } },
            ] },

            { path: 'transactions', component: Transactions, children: [
                   // { path: 'reconcile', name: 'transactions-reconcile', component:Reconcile , meta: { name: 'Transactions' } },
                   // { path: 'deposit', name: 'transactions-deposit', component:Deposit , meta: { name: 'Transactions' } },
                    { path: 'withdraw', name: 'transactions-withdraw', component: Withdraw, meta: { name: 'Transactions' } },
                    { path: 'logs', name: 'transactions-logs', component: Logs, meta: { name: 'Transactions'} },

                ] },

            { path: 'mpesa', name: 'mpesa', component: Mpesa },

                { path: 'tax', name: 'tax', component: Tax },

            { path: 'bets', component: Bets, children: [
                { path: 'approvals', name: 'bets-approvals', component: BetsApprovals, meta: { name: 'Bets' } },
                // { path: 'jackpot/allocate', name: 'jackpot-allocate', component: BetsFree, meta: { name: 'Bets' } },
                // { path: 'rejected', name: 'jackpot-approvals', component: BetsRejected, meta: { name: 'Bets' } },
            ]},
            { path: 'admin', component: Admin, children: [
                { path: '', name: 'users', component: Users, meta: { name: 'Users' } },
                    { path: 'influencer', name: 'influencer', component: Influencer, meta: { name: 'influencer' } },
            ] },

        ] },
    ]
})


//"sass-loader": "^7.3.1",
