<template>
    <div class="l-main-content">
        <div class="container">
            <div class="ui padded grid">
                <div class="row">
                    <div class="six wide computer sixteen wide mobile column">
                        <div class="">
                            <h2 class="text-medium">Affiliate Dashboard</h2>
                        </div>
                    </div>

                    <div class=" six wide computer sixteen wide mobile column">
                        <div id="reportrange" class="margin-top-sm" style="background: #fff; vertical-align: middle; cursor: pointer; padding: 5px 10px; border: 1px solid #ddd; border-radius: 4px; ">
                            <i class="fa fa-calendar margin-right-sm"></i>
                            <span></span> <i class="fa fa-caret-down" style="float: right"></i>
                        </div>
                        <!--<button v-on:keyup.enter="download" class="hidden ui small left icon button bg-primary" @click="download"><i class="icon download"></i> Excel</button>-->
                    </div>
                    <div class="four wide computer sixteen wide mobile right aligned column">
                        <select @change="searchLogs" class="margin-top-sm" id="status" v-model="search_logs" style="width: 100%; display: block; background: #fff; vertical-align: middle; cursor: pointer; padding: 5px 10px; border: 1px solid #ddd; border-radius: 4px;">
                            <option value="" selected disabled hidden>Choose Region</option>
                            <option value="">All Region</option>
                            <option value="1">Social Media Influencer</option>
                            <option value="2">Nairobi</option>
                            <option value="3">Eastern</option>
                            <option value="4">Rift Valley</option>
                            <option value="5">Western</option>
                            <option value="6">Coast</option>
                            <option value="7">Nyanza</option>
                            <option value="8">Publisher's</option>
                        </select>
                        <!--<button class="ui icon labeled blue button" @click="searchLogs"><i class="search icon"></i> Search</button>-->
                    </div>

                    <div class="hidden four wide computer sixteen wide mobile right aligned column">
                        <div>
                            <input v-on:keyup.enter="searchProfile" type="text" placeholder="Enter phone number..." v-model="search_profile">
                        </div>
                        <button class="bg-primary" @click="searchProfile">Search</button>
                    </div>
                </div>

                <div class="row">

                </div>
            </div>
            <div class="rsum margin-bottom-md">
                <loader v-show='loading' />

                <div class="sum sum-3 blue">
                    <div class="h">
                        <div class="l">Signups</div>
                        <!--<div class="r">- 2.01%</div>-->
                    </div>
                    <div class="t">{{signups}}</div>
                </div>
                <!--<div class="sum sum-3 blue">-->
                    <!--<div class="h">-->
                        <!--<div class="l">Deposits</div>-->
                        <!--&lt;!&ndash;<div class="r">- 2.01%</div>&ndash;&gt;-->
                    <!--</div>-->
                    <!--<div class="t">{{deposit}}</div>-->
                <!--</div>-->
                <div class="sum sum-3 yellow">
                    <div class="h">
                        <div class="l">Bets</div>
                        <!--<div class="r">- 2.01%</div>-->
                    </div>
                    <div class="t">{{bets}}</div>
                </div>
                <div class="sum sum-3 teal">
                    <div class="h">
                        <div class="l">Stake</div>
                        <!--<div class="r">- 2.01%</div>-->
                    </div>
                    <div class="t">{{stake}}</div>
                </div>
            </div>
            <div class="responsive">
              <div class="row">
                <loader v-show='loading' />
                <vuetable ref="vuetable"
                          :api-url="vueurl"
                          :fields="columns"
                          :sort-order="sortOrder"
                          track-by="created"
                          :append-params="moreParams"
                          :per-page="10"
                          :css="tableCss"
                          @vuetable:pagination-data="onPaginationData"
                          @vuetable:loading="onLoading"
                          @vuetable:loaded="onLoaded"
                >
                </vuetable>
                  <vuetable-pagination-info :css="paginationInfo" ref="paginationInfo"></vuetable-pagination-info>

                  <vuetable-pagination ref="pagination" :css="pagination"
                                       @vuetable-pagination:change-page="onChangePage"
                  ></vuetable-pagination>
            </div>

            </div>
        </div>
    </div>
</template>

<style>
    td.vuetable-slot{
        overflow: visible !important;
    }
</style>

<script>

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import loader from "@/components/loader"
    import markets from "../modals/markets";
    import XLSX from 'xlsx';
    import  moment from 'moment';
    import {config} from '../config/config';

    function formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }



    export default {
        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo,loader,markets,XLSX,config
        },
        name: 'report',

        data() {
            return {
                vueurl:config.baseURLWEB+'dash/api/affiliate',
                loading: true,
                search_profile: '',
                selected_data: {},
                columns: [

                    {
                        name: 'name',
                        title: 'Name',
                        titleClass: '',
                        dataClass: '',
                    },
                    {
                        name: 'msisdn',
                        title: 'phone',
                        titleClass: '',
                        dataClass: '',
                    },
                    {
                        name: 'region',
                        title: 'Region',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'

                    },
                    {
                        name: "signups",
                        title: 'Signups',
                        sortField: 'signups',
                    },

                    {
                        name: 'bets',
                        title: 'Bets',
                        sortField: 'bets',
                        titleClass: '',
                        dataClass: '',

                    },
                    {
                        name: 'stake',
                        title: 'Stake',
                        sortField: 'stake',
                        titleClass: '',
                        dataClass: ''
                    },




                ],
                exceldata:[],
                range:'',
                deposit:'',
                stake:'',
                signups:'',
                search_logs:'',
                bets:'',
                search_match:'',
                loadOnStart: true,
                sortOrder: [{
                    field: 'stake',
                    direction: 'desc'
                }],
                is_partial_resulting: false,

                moreParams: {
                    start: '',
                    filter: '',
                    region: '',
                    end: '',
                    msisdn: ''
                },
                tableCss: {
                    tableWrapper: '',
                    tableHeaderClass: 'fixed',
                    tableBodyClass: 'fixed',
                    tableClass: 'ui blue selectable unstackable celled table',
                    loadingClass: 'loading',
                    ascendingIcon: 'blue chevron up icon',
                    descendingIcon: 'blue chevron down icon',
                    ascendingClass: 'sorted-asc',
                    descendingClass: 'sorted-desc',
                    sortableIcon: 'grey sort icon',
                    handleIcon: 'grey sidebar icon',
                },
                pagination: {
                    wrapperClass: 'ui right floated pagination menu',
                    activeClass: 'active',
                    disabledClass: 'disabled',
                    pageClass: 'item',
                    linkClass: 'icon item',
                    paginationClass: 'ui bottom attached segment grid',
                    paginationInfoClass: 'left floated left aligned six wide column',
                    dropdownClass: 'ui search dropdown',
                    icons: {
                        first: 'angle double left icon',
                        prev: 'left chevron icon',
                        next: 'right chevron icon',
                        last: 'angle double right icon',
                    }
                },
                paginationInfo: {
                    infoClass: 'left floated left aligned six wide column',
                }

            }
        },

        created: function(){
            this.checktimer();

        },


        mounted() {
            var vm = this;

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role
            var userr=jsonData.id

            vm.role=rolee;
            vm.user_id=userr;



            var start = moment();
            var end = moment();

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                $('#reportrange span').html(start.format('D MMM, YYYY') + ' - ' + end.format('D MMM, YYYY'));
            }

            $('#reportrange').daterangepicker({
                startDate: start,
                endDate: end,
                autoApply:true,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);

        },
        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.reload();
            },
            range(){

                var start = this.range[0];
                var end = this.range[1];
                start= moment(start).format('YYYY-MM-DD');
                end= moment(end).format('YYYY-MM-DD');
                // this.moreParams.start = start;
                // this.moreParams.end = end;
                console.log('date range changed to ',start)

                vm.getsummaryData(start,end,this.search_logs);

            },
        },
        methods: {

            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                vm.moreParams.start = start;
                vm.moreParams.end = end;

                vm.checktimer();
                vm.getsummaryData(vm.start,vm.end);

                this.$refs.vuetable.reload();

                console.log("start date filter cheacker",vm.start)
                console.log("end date filter cheacker  ",vm.end)
            },



            getsummaryData: function (start,end) {
                // console.log("am here start",start)
                // console.log("am here end",end)

                var vm = this;

                this.loading=true;


                $.get({
                    url: config.baseURLWEB+'dash/api/summary/attribution?start='+start+'&end='+end+'&region='+vm.search_logs,
                    type: "GET",
                    success: function (response) {

                        console.log("payload to the server", JSON.stringify(response))


                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.bets=formatNumber(json[key].bets);
                                vm.stake=formatNumber(json[key].stake);
                                vm.signups=formatNumber(json[key].signups);

                                this.loading=false;

                            }
                        }


                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },


            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");


                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },



            createdAt: function (value) {
                if (!value) {
                    return '-';
                } else {
                    return moment(value).format('h:mm a,DD MMM YYYY');
                }
            },
            download : function() {
                var vm=this


                console.log("Modal called once for bet id ")


                $.get({
                    url: config.baseURLWEB+'dash/api/kironn/all?page=1&per_page=10&start=2020-08-01&filter=&end=2020-12-31',
                    type: "GET",
                    success: function (response) {
                        console.log("server response",response.data)
                        vm.exceldata = response.data;


                        const data = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(vm.exceldata)))
                        const wb = XLSX.utils.book_new()
                        XLSX.utils.book_append_sheet(wb, data, 'data')
                        XLSX.writeFile(wb,'report.xlsx');
                    },
                    error: function () {

                    }
                });




            },

            searchLogs: function (data) {

                var vm=this;
                console.log("seach activated");

                if( (vm.search_logs== 0 || !vm.search_logs)){

                    vm.getsummaryData(vm.start,vm.end);
                    this.$refs.vuetable.reload();
                }else {
                    vm.getsummaryData(vm.start,vm.end,vm.search_logs);
                    this.moreParams.filter=this.search_logs;
                    this.$refs.vuetable.reload();
                }

            },



            searchProfile: function (data) {

                // var vm=this;
                // console.log("seach activated");
                //
                // if( (vm.search_profile== 0 || !vm.search_profile)){
                //
                //     vm.getsummaryData(vm.start,vm.end);
                //     this.$refs.vuetable.reload();
                // }else {
                //     vm.getsummaryData(vm.start,vm.end,vm.search_logs);
                //     this.moreParams.filter=this.search_logs;
                //     this.$refs.vuetable.reload();
                // }

            },



            searchMatches: function(){
                this.moreParams.filter = this.search_match;
                this.$refs.vuetable.reload();
            },



            onPaginationData(paginationData) {
                this.$refs.pagination.setPaginationData(paginationData);
                this.$refs.paginationInfo.setPaginationData(paginationData);

            },
            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },
            onLoading() {
                this.loading = true
                console.log('loading... show your spinner here')
            },
            onLoaded() {
                this.loading = false
                console.log('loaded! .. hide your spinner here');
            },
        },
    }

</script>