<template>
    <div class="ui modal" id="user">
        <i class="close icon"></i>

        <div class="header">
             Create Profile
        </div>
        <div class="scrolling content">
            <div class="ui form">


                <div class="field">
                    <label>Mobile</label>
                    <div class="ui fluid input">
                        <input class="" placeholder="Enter mobile " v-model="mobile">
                    </div>
                </div>



                <div class="field">
                    <label>FullNames</label>
                    <div class="ui fluid input">
                        <input class="" placeholder="Enter names" v-model="fname">
                    </div>
                </div>

                <div class="field">
                    <label>Email</label>
                    <div class="ui fluid input">
                        <input class="" placeholder="Enter email" v-model="email">
                    </div>
                </div>


                <select v-model="role"  class="ui  dropdown">
                    <option value="2">Management</option>
                    <option value="3">Resulting</option>
                    <option value="4">Customer Services</option>
                    <option value="5">Consultant</option>
                    <option value="10">Book Makers</option>
                </select>

            </div>
        </div>
        <div class="actions">
            <div class="ui deny button">
                Cancel
            </div>
            <div  @click="createUser" class="ui green left labeled icon button">
                <i class="checkmark icon"></i> Create User
            </div>
        </div>


    </div>


</template>

<script>



    import {config} from '../config/config';

    export default {

        components: {
            config
        },

        props:{
            data:{
                type: Object

            }
        },

        data() {
            return {

                role:'',
                mobile:'',
                selected_bonus_type:'',
                selectedProfile:'',
                user_id:'',
                token:'',
                fname:'',
                email:''

            }
        },

        watch:{
            data(newVal,oldVal){
                this.award_data = newVal;
            }
        },
        computed: {

        },
        created() {

            var vm=this;

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;



        },
        mounted() {
                $('.ui.modal#user').modal({
                    closable: true
                }).modal('hide');


        },

        methods: {

            createUser:function () {

                var vm=this;

                if (!vm.email || !vm.role) {
                    vm.$swal("Missing!", "All fields are REQUIRED!", "warning");
                    return;
                }

                vm.$swal.fire({
                    title: 'Edit Name',
                    text: "If you are sure, Edit Names:!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Edit Name!'
                }).then((result) => {
                    if (result.value) {

                        var bonusData = {
                            username: vm.mobile,
                            email: vm.email,
                            role: vm.role,
                            user_id: vm.user_id,
                            fname: vm.fname,
                            token: vm.token,
                        };
                        $.ajax({
                            url: config.baseURL+'dash/user/create',
                            type: 'POST',
                            data: JSON.stringify(bonusData),
                            success: function (response, textStatus, jQxhr) {
                                $('.ui.modal#user').modal({
                                    closable: true
                                }).modal('hide');

                                if (response.status_code == 200) {
                                    vm.$swal("Success!", response.status_description, "success");

                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }
                })


            }


        },

    }
</script>