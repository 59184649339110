<template>


    <div class="ui modal" id="edit-jackpot">

        <i class="close icon"></i>
        <div class="header">
            Edit Jackpot
        </div>
        <div class="scrolling content">
            <div class="ui grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <label>Jackpot Name</label>
                            <div class="ui fluid input">
                                <input type="text" class="" placeholder="Midweek Jackpot..." v-model="name">
                            </div>
                        </div>
                        <div class="equal width fields">
                            <div class="field">
                                <label>Number of games</label>
                                <div class="ui fluid input">
                                    <input type="number">
                                </div>
                            </div>
                            <div class="field">
                                <label>Winning Amount</label>
                                <div class="ui fluid input">
                                    <input type="number" placeholder="105000000">
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label>Image URL</label>
                            <div class="ui fluid input">
                                <input type="text" placeholder="https://example.com">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="actions">
            <div class="ui deny button">
                Cancel
            </div>
            <div  @click="createJackpot" class="ui green left labeled icon button">
                <i class="save icon"></i> Save
            </div>
        </div>
    </div>

</template>
<script>
    import {config} from '../config/config';

    export default {

        components: {
            config
        },

        props:{
            data:{
                type: Object
            }
        },

        data() {
            return {

                search_matches: '',
                user_id: '',
                token: '',
                name: '',
                start: '',
                end: '',
                date: '',
                time: '',
                resulting_loader: '',
                jackpot_type: 1,




            }
        },


        watch:{

        },




        computed: {


        },

        mounted(){
            var vm = this;

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var id=jsonData.id;
            var tokenn=jsonData.token

            vm.user_id=id;
            vm.token=tokenn;





        },





        methods: {
            createdAt: function (value) {
                if (!value) {
                    return '-';
                } else {
                    return moment(value).format('HH:mm DD/MM/YY');
                }
            },



            createJackpot: function () {
                var vm = this;

                var data = {
                    date:vm.date,
                    time:vm.time,
                    type: vm.jackpot_type,
                    name: vm.name,
                    user_id: vm.user_id,
                    token: vm.token,

                };


                $.ajax({
                    url: config.baseURL+'portal/jackpot/create',
                    type: 'POST',
                    data: JSON.stringify(data),
                    success: function (response, textStatus, jQxhr) {



                        if (response.status_code == 200) {
                            vm.$swal("Success!", response.status_description, "success");

                            $('.ui.modal#jackpot').modal({
                                closable: true
                            }).modal('hide');
                        } else {
                            vm.$swal("Error!", response.status_description, "error");

                            $('.ui.modal#jackpot').modal({
                                closable: true
                            }).modal('hide');
                        }

                    },
                    error: function (jqXhr, textStatus, error) {

                        vm.$swal("Error!", error, "error");
                        $('.ui.modal#jackpot').modal({
                            closable: true
                        }).modal('hide');
                    }
                });
            },


            closeModal(){
                $(".ui.modal#jackpot").modal("hide");
            }
        },
        filters: {
            size: function (val) {
                return val.length;
            }
        },
        created: function () {
            var a = moment([2018, 9, 24]);
            var b = moment();
            var diff = b.diff(a, 'days') + 1;
            this.currentDay = 'DAY ' + diff; // 1
            // this.getsport()
        },

    }
</script>