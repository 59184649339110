<template>
    <div class="l-main">
        <div class="l-main-content">

        <div class="row p-left p-right p-top">
            <div class="col-6">
                <div class="text-title">
                    <h2 class="text-medium">Tax Reconcilation</h2>
                </div>
            </div>
            <div class="col-6 text-right">
                <div class="l-input inline">
                    <i class=" search icon"></i>
                    <input v-on:keyup.enter="searchMatches" type="text" placeholder="Search..." v-model="search_match">
                </div>

                <div class="l-input inline">
                    <i class="icon-calendar"></i>
                    <input name="dates"  @focus="onFocus()"  placeholder="Choose date" />
                </div>

                <button  @click="upload" class="ui blue button margin-left-md"><i class="fa fa-upload"></i></button>


            </div>
        </div>
                <div class="row">
                    <loader v-show='loading' />
                    <div class="col">
                        <vuetable ref="vuetable"
                                  :api-url="vueurl"
                                  :fields="columns"
                                  :sort-order="sortOrder"
                                  :append-params="moreParams"
                                  :per-page="15"
                                  @vuetable:pagination-data="onPaginationData"
                                  @vuetable:loading="onLoading"
                                  @vuetable:loaded="onLoaded"
                        >

                            <template  v-slot:status-slot="props">
                                <div  v-if="props.rowData.type === '1'"><strong>Excise Tax</strong></div>
                                <div  v-if="props.rowData.type === '2'"><strong>Withholding Tax</strong></div>
                                <div  v-if="props.rowData.type === '3'"><strong>Casino Excise Tax</strong></div>
                                <div  v-if="props.rowData.type === '4'"><strong>Casino Withholding Tax</strong></div>
                            </template>

                        </vuetable>
                        <div class="vuetable-pagination ui basic segment grid">
                            <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

                            <vuetable-pagination ref="pagination"
                                                 @vuetable-pagination:change-page="onChangePage"
                            ></vuetable-pagination>
                        </div>
                    </div>
                </div>

        </div>

        <taxupload v-if="deposit_data" :data="deposit_data"/>

    </div>


</template>

<script>

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import loader from "@/components/loader"
    import taxupload from "../modals/taxsubmit.vue"


    import {config} from '../config/config';


    export default {


        components: {
             Vuetable, VuetablePagination, VuetablePaginationInfo,loader,taxupload,config
        },
        name: 'tax',

        data() {
            return {
                vueurl:config.baseURL+'portal/tax/report',
                loading: true,
                selected_data: {},
                deposit_data:'',
                deposit_mpesa:'',
                columns: [
                    {
                        name: 'id',
                        title: 'ID',
                        sortField: 'ID',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'

                    },

                    {
                        name: 'PRN',
                        title: 'PRN',
                        sortField: 'PRN',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'prnAmount',
                        title: 'Amount',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: 'status',
                        title: 'status',
                        titleClass: '',
                        dataClass: '',
                    },
                  {
                    name: 'response_status',
                    title: 'response_status',
                    titleClass: '',
                    dataClass: '',
                  },
                  {
                    name: 'transaction_id',
                    title: 'transaction_id',
                    titleClass: '',
                    dataClass: '',
                  },
                    {
                      name: 'status-slot',
                      title: 'Type',
                      titleClass: '',
                      dataClass: '',

                    },

                    {
                        name: 'agent',
                        title: 'Agent ',
                        titleClass: ''
                    },
                    {
                        name: 'date',
                        title: 'Date',
                        sortField: 'transaction_time',
                        callback: 'depositedAt'
                    },
                ],
                range:'',
                user_id:'',
                role:'',
                token:'',
                search_match:'',
                sortOrder: [{
                    field: 'date',
                    direction: 'desc'
                }],
                is_partial_resulting: false,
                moreParams: {
                    start: '',
                    filter: '',
                    end: '',
                    token: this.token,
                }

            }
        },


        mounted() {

            var vm=this;

            var start = moment();
            var end = moment();

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                $('#reportrange span').html(start.format('D MMM, YYYY') + ' - ' + end.format('D MMM, YYYY'));
            }

            $('#reportrange').daterangepicker({
                startDate: start,
                endDate: end,
                autoApply:true,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);

        },


        created: function(){

            var vm=this;


            vm.checktimer();

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;

            if((vm.role  !=2 && vm.role !=3 && vm.role !=5)){

                vm.$router.push({name: 'login'});
            }

            var start = moment();
            var end = moment();

            vm.moreParams.start=start.format('YYYY-MM-DD');
            vm.moreParams.end=end.format('YYYY-MM-DD');
            vm.moreParams.token=vm.token;
            
        },





        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.reload();
            },
            range(){

                var start = this.range[0];
                var end = this.range[1];
                this.start= moment(start).format('YYYY-MM-DD');
                this.end= moment(end).format('YYYY-MM-DD');
                console.log("am reaching here ")
                this.moreParams.start = this.start;
                this.moreParams.end = this.end;
                this.moreParams.token = this.token;
                this.$refs.vuetable.reload();
            }
        },



        methods: {


            onActionClicked() {


                  var vm=this;
                  var jsondata = {
                    mpesa_code: "e",
                    deposit_amount: "e",
                    status_description: "e"
                  };


                  vm.deposit_mpesa=jsondata;

                      $('.ui.modal#tax').modal({
                          closable: true,
                      }).modal('show');
                },


            upload(){

                var vm = this;

                if (vm.upload_loader) {
                    return;
                }

                var jsondata = {
                    mpesa_code: vm.mpesa_code,
                    deposit_amount: vm.mpesa_amt,
                    status_description: vm.description,
                };

                vm.deposit_data=jsondata;

                $('.ui.modal#taxupload').modal({
                    closable: true,
                }).modal('show');

            },




            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");

                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },

            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                vm.moreParams.start = vm.start;
                vm.moreParams.end = vm.end;
                vm.moreParams.token = vm.token;


                this.$refs.vuetable.reload();

                console.log("start date filter cheacker",vm.start)
                console.log("end date filter cheacker  ",vm.end)
            },

            onFocus: function() {

                var vm = this;

                var start = moment();
                var end = moment();

                function cb(start, end) {
                    vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                    $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
                }

                $('input[name="dates"]').daterangepicker({
                    startDate: start,
                    endDate: end,
                    ranges: {
                        'Today': [moment(), moment()],
                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    }
                }, cb);

                cb(start, end);

            },


            searchMatches:function(){
                this.moreParams.filter = this.search_match;
                this.$refs.vuetable.reload();
            },

            depositedAt: function (value) {
                if (!value) {
                    return '-';
                } else {
                    return moment(value).format('DD MMMM YYYY, h:mm a');
                }
            },

            createdAt: function (value) {
                if (!value) {
                    return '-';
                } else {
                    return moment(value).format('h:mm a,DD MMM YYYY');
                }

            },

            dataManager(sortOrder, pagination) {
                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {
                    console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                    local = _.orderBy(
                        local,
                        sortOrder[0].sortField,
                        sortOrder[0].direction
                    );
                }

                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );
                console.log('pagination:', pagination)
                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },


            onPaginationData(paginationData) {
                this.$refs.paginationInfo.setPaginationData(paginationData)
                this.$refs.pagination.setPaginationData(paginationData)
            },


            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },


            onLoading() {
                this.loading = true
                console.log('loading... show your spinner here')
            },
            onLoaded() {
                this.loading = false
                console.log('loaded! .. hide your spinner here')
            },




        },


    }

</script>




