<template>

    <div class="ui modal" id="blacklist">
        <i class="close icon"></i>

        <div class="header">
           Blacklist Account
        </div>
        <div class="content">
            <div class="ui form">
                <div class="equal width fields">
                    <div class="field">
                        <label>Reason</label>
                        <select  v-model="blacklist_input" class="margin-top-sm">
                            <option>select options</option>
                            <option value="1">Client Requested self exemption</option>
                            <option value="2">Fraud</option>
                            <option value="3">Sms Blacklist</option>
                            <option value="4">Undergoing Investigation</option>
                            <option value="5">Withdraw Fraud</option>
                            <option value="6">Cashout Fraud</option>
                            <option value="7">Bonus Fraud</option>
                            <option value="8">Bet Fraud</option>

                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="ui deny button">
                Cancel
            </div>
            <div  @click="blacklistAccout" class="ui green left labeled icon button">
                <i class="checkmark icon"></i> Blacklist Account
            </div>
        </div>

    </div>

</template>

<script>


    import {config} from '../config/config';

    export default {
        components: {
            config
        },

        props:{
            data:{
                type: Object,

            }
        },

        data() {
            return {

                blacklist_input:'',
                selected_bonus_type:'',
                match_data:this.data,
                selectedProfile:'',
                pending_market: [],
                selected_pending_markets:'',
                selected_pending_markett:[],
                user_id:'',
                role:''

            }
        },


        watch:{
            data(newVal,oldVal){

                this.match_data = newVal;
                this.getWrongMarkets()
            }
        },
        computed: {

        },
        created() {

            // this.getWrongMarkets()

            $('.ui.modal#blastlista').modal({
                closable: true
            }).modal('hide');


        },

        mounted() {


            var vm = this;



            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;

        },


        methods: {

            blacklistAccout () {

                var vm=this;


                vm.$swal.fire({
                    title: 'Blaclist Account?',
                    text: "If you are sure, Blacklist:!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes,Blacklist Account !'
                }).then((result) => {
                    if (result.value) {

                        var bonusData = {
                            description: vm.blacklist_input,
                            msisdn: vm.match_data.msisdn,
                            user_id: vm.user_id,
                            token: vm.token,
                        };

                        $.ajax({
                            url: config.baseURL+'portal/subscribe/blacklist',
                            type: 'POST',
                            data: JSON.stringify(bonusData),
                            success: function (response, textStatus, jQxhr) {

                                if (response.status_code == 200) {
                                    vm.$swal("Success!", response.status_description, "success");

                                    $('.ui.modal#blastlist').modal({
                                        closable: true
                                    }).modal('hide');

                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }
                })


            },


        },

    }
</script>