<template>

    <div class="ui modal" id="jackpotmatches">
        <i class="close icon"></i>
        <div class="header">
            <div class="ui equal width grid">
                <div class="column">
                        Jackpot Matches<br/>

                </div>

            </div>
        </div>

        <div class="scrolling content bg-grey-light">
            <div class="ui segments bg-white" v-for="betslip in selected_data.betslipData" :key="betslip.bet_slip_id">
                <div class="ui segment">
                    <div class="ui grid">
                        <div class="seven wide right aligned column">
                            <h3>{{betslip.home_team}}</h3>
                        </div>
                        <div class="two wide center aligned column">
                            <h3><strong>{{betslip.result}}</strong></h3>
                        </div>
                        <div class="seven wide column">
                            <h3>{{betslip.away_team}}</h3>
                        </div>
                    </div>
                </div>
                <div class="ui horizontal segments">
                    <div class="ui segment">
                        <h4>Start Time</h4>
                        <p>{{betslip.start_time}}</p>
                    </div>
                    <div class="ui segment">
                        <h4>Competition</h4>
                        <p>{{betslip.competition_name}}</p>
                    </div>


                </div>


                <div slot="action" slot-scope="props">

                    <p>
                        <button
                                class="ui small button"
                                @click="onActionWithdraw('reverse', props.rowData)">
                            <i class="blue  reload  icon "></i>
                        </button>

                    </p>

                </div>


            </div>


        </div>

    </div>


</template>

<script>

    import {config} from '../config/config';

    export default {

        components: {
            config
        },

        props:{
            data:{
                type: Object

            }
        },


        data() {
            return {

                final: '',
                sport_id: 1,
                selected_data:this.data

            }
        },

        watch:{
            data(newVal,oldVal){

                this.selected_data = newVal;
            }
        },
        computed: {

        },
        mounted() {

            $('.ui.modal#new-user').modal({
                closable: true
            }).modal('hide');

        },

        methods: {


        },

    }
</script>