<template>

        <div class="ui small modal" style="padding: 25px;" id="modal-resulting">
            <i class="close icon"></i>

        <div class="header" style="padding-top: 0;">
            MATCH RESULTING
            <span style="margin-left: 10px;" class="ui checkbox field">
                <input id="checkbox1" type="checkbox" v-model="is_partial_resulting" style="cursor: pointer;">
                <label for="checkbox1"><strong style="color: red;cursor: pointer;">PARTIAL ?</strong></label>
            </span>

            <span style="margin-left: 10px;" class="ui checkbox field">
                <input id="checkghana" type="checkbox" v-model="is_ghana_resulting" style="cursor: pointer;">
                <label for="checkghana"><strong style="color: red;cursor: pointer;">Ghana /Tannzania ?</strong></label>
            </span>

        </div>
        <div class="padded" style="padding-top: 20px;">
            <div class="ui grid">
                <div class="column">
                    <div class="ui form">
                        <b v-if="matche.sport_id == 1">
                            <div v-show="isExists('5')" class="ui segment">
                                <div class="field">
                                    <label>Which Team kicks off?</label>
                                    <select v-model="selected_competitor_id" class="ui dropdown form-control" data-width="auto">
                                        <option value="-1">Select Team</option>
                                        <option v-for="competitor in competitors" v-bind:value="competitor.competitor_id">{{competitor.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </b>
                        <!--Soccer-->
                        <div v-show="matche.sport_id == 1" class="ui segment">
                            <div class="equal width fields">

                                <div class="field">
                                    <label>FT</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="Fulltime score" v-model="ft_score">
                                    </div>
                                </div>

                                <div class="field">
                                    <label>HT</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="Halftime score" v-model="ht_score">
                                    </div>
                                </div>

                            </div>


                            <div class="equal width fields">


                            </div>
                            <!--Overtime-->
                            <div v-show="matche.overtime == 1 || matche.overtime == 2 || matche.overtime == 3" class="ui field">
                                <label>Overtime</label>
                                <div class="equal width fields">
                                    <div class="field">
                                        <label>HT</label>
                                        <div class="ui fluid input">
                                            <input type="text" placeholder="Overtime HT score" v-model="ht_overtime_score">
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label>FT</label>
                                        <div class="ui fluid input">
                                            <input type="text" placeholder="Overtime FT score" v-model="ft_overtime_score">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Basketball-->
                        <div v-show="matche.sport_id == 2" class="ui segment">
                            <div class="equal width fields">
                                <div class="field">
                                    <label>1st Quarter</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="1st quarter score" v-model="q1t_score">
                                    </div>
                                </div>
                                <div class="field">
                                    <label>2nd Quarter</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="2nd quarter score" v-model="q2t_score">
                                    </div>
                                </div>
                            </div>
                            <div class="equal width fields">
                                <div class="field">
                                    <label>3rd Quarter</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="3rd quarter score" v-model="q3t_score">
                                    </div>
                                </div>
                                <div class="field">
                                    <label>4th Quarter</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="4th quarter score" v-model="q4t_score">
                                    </div>
                                </div>
                            </div>
                            <!--Overtime-->
                            <div v-show="matche.overtime == 1 || matche.overtime == 2 || matche.overtime == 3" class="ui field">
                                <label>FT</label>
                                <div class="ui fluid input">
                                    <input type="text" placeholder="Overtime FT score" v-model="ft_overtime_score">
                                </div>
                            </div>
                        </div>

                        <!--Baseball-->
                        <div v-show="matche.sport_id == 3" class="ui segment">
                            <label>
                                <span style="margin-left: 10px;" class="ui checkbox field">
                                    <input id="checkbox4" type="checkbox" v-model="is_baseball_professional" style="cursor: pointer;">
                                    <label for="checkbox4"><strong style="color: red;cursor: pointer;">Extral Time ?</strong></label>
                                </span>
                            </label>
                            <div class="equal width fields">
                                <div class="field">
                                    <label>1st Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="1st set score" v-model="s1t_score">
                                    </div>
                                </div>
                                <div class="field">
                                    <label>2nd Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="2nd set score" v-model="s2t_score">
                                    </div>
                                </div>
                                <div class="field">
                                    <label>3rd Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="3rd set score" v-model="s3t_score">
                                    </div>
                                </div>
                            </div>
                            <div class="equal width fields">
                                <div class="field">
                                    <label>4th Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="4th set score" v-model="s4t_score">
                                    </div>
                                </div>
                                <div class="field">
                                    <label>5th Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="5th set score" v-model="s5t_score">
                                    </div>
                                </div>

                                <div class="field">
                                    <label>6th Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="6th set score" v-model="s6t_score">
                                    </div>
                                </div>

                            </div>

                            <div class="equal width fields">
                                <div class="field">
                                    <label>7th Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="7th set score" v-model="s7t_score">
                                    </div>
                                </div>
                                <div class="field">
                                    <label>8th Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="8th set score" v-model="s8t_score">
                                    </div>
                                </div>

                                <div class="field">
                                    <label>9th Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="9th set score" v-model="s9t_score">
                                    </div>
                                </div>

                            </div>

                            <div v-show="is_baseball_professional" class="equal width fields">

                                <div class="field">
                                    <label>10th Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="10th set score" v-model="s10t_score">
                                    </div>
                                </div>

                            </div>



                        </div>


                        <!--Cricket-->
                        <div v-show="matche.sport_id == 21 || matche.sport_id == 19 || matche.sport_id == 13 || matche.sport_id == 10" class="ui segment">
                            <!--                            <label>-->
                            <!--                                <span style="margin-left: 10px;" class="ui checkbox field">-->
                            <!--                                            <input id="checkbox5" type="checkbox" v-model="is_cricket_professional" style="cursor: pointer;">-->
                            <!--                                    <label for="checkbox5"><strong style="color: red;cursor: pointer;">Extral Time ?</strong></label>-->
                            <!--                                </span>-->
                            <!--                            </label>-->
                            <div class="equal width fields">
                                <div class="field">
                                    <label>Full time Score</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="Full time Score" v-model="s1t_score">
                                    </div>
                                </div>


                            </div>

                            <!--                            <div v-show="is_cricket_professional" class="equal width fields">-->
                            <!---->
                            <!--                                <div class="field">-->
                            <!--                                    <label>3th Set</label>-->
                            <!--                                    <div class="ui labeled input">-->
                            <!--                                        <input type="text" placeholder="overtime  score" v-model="s3t_score">-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <!---->
                            <!--                            </div>-->



                        </div>


                        <!--Rugby and handball-->
                        <div v-show="matche.sport_id == 12 || matche.sport_id == 6 || matche.sport_id == 29" class="ui segment">
                            <label>
                            <span style="margin-left: 10px;" class="ui checkbox field">
                                        <input id="checkbox5" type="checkbox" v-model="is_Rugby_professional" style="cursor: pointer;">
                                <label for="checkbox5"><strong style="color: red;cursor: pointer;">Extral Time ?</strong></label>
                            </span>
                            </label>
                            <div class="equal width fields">
                                <div class="field">
                                    <label>Full time Score</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="Full time Score" v-model="s1t_score">
                                    </div>
                                </div>

                                <div class="field">
                                    <label>Half time Score</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="Full time Score" v-model="s2t_score">
                                    </div>
                                </div>


                            </div>

                            <div v-show="is_Rugby_professional" class="equal width fields">

                                <div class="field">
                                    <label>Over Time</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="overtime  score" v-model="s3t_score">
                                    </div>
                                </div>

                            </div>

                        </div>


                        <!--Tennis-->
                        <div v-show="matche.sport_id == 5 ||  matche.sport_id == 7  || matche.sport_id == 26 ||   matche.sport_id == 20 || matche.sport_id == 23 || matche.sport_id == 4  || matche.sport_id == 34 || matche.sport_id == 16 || matche.sport_id == 31" class="ui segment">
                            <label>
                                <span style="margin-left: 10px;" class="ui checkbox field">
                                    <input id="checkbox3" type="checkbox" v-model="is_tennis_professional" style="cursor: pointer;">
                                    <label for="checkbox3"><strong style="color: red;cursor: pointer;">PROFESSIONAL ?</strong></label>
                                </span>
                            </label>
                            <div class="equal width fields">
                                <div class="field">
                                    <label>1st Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="1st set score" v-model="s1t_score">
                                    </div>
                                </div>
                                <div class="field">
                                    <label>2nd Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="2nd set score" v-model="s2t_score">
                                    </div>
                                </div>
                                <div class="field">
                                    <label>3rd Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="3rd set score" v-model="s3t_score">
                                    </div>
                                </div>
                            </div>
                            <div v-show="is_tennis_professional" class="equal width fields">
                                <div class="field">
                                    <label>4th Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="4th set score" v-model="s4t_score">
                                    </div>
                                </div>
                                <div class="field">
                                    <label>5th Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="5th set score" v-model="s5t_score">
                                    </div>
                                </div>

                                <div class="field">
                                    <label>6th Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="6th set score" v-model="s6t_score">
                                    </div>
                                </div>

                                <div class="field">
                                    <label>7th Set</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="7th set score" v-model="s7t_score">
                                    </div>
                                </div>

                            </div>
                        </div>

                        <!--Penalties-->

                        <div v-show="matche.overtime == 2 || matche.overtime == 3 && matche.sport_id !== 6 && matche.sport_id !== 12 " class="ui segment">
                            <label>Penalties [{{penalties | size}}]</label>
                            <div class="equal width fields">
                                <div class="field">
                                    <label>#</label>
                                    <div class="ui fluid input">
                                        <input type="text" placeholder="Pos" v-model="penalty_id">
                                    </div>
                                </div>
                                <div class="field">
                                    <label>Team</label>
                                    <select v-model="selected_competitor" class="ui dropdown form-control" data-width="auto">
                                        <option value="">Select Team</option>
                                        <option v-for="competitor in competitors" v-bind:value="competitor.competitor_id">{{competitor.name}}</option>
                                    </select>
                                </div>
                                <div class="ui field">
                                    <label style="visibility: hidden;">Add</label>
                                    <div class="ui checkbox field">
                                        <input id="checkbox" type="checkbox" v-model="is_penalty_scored" style="cursor: pointer;">
                                        <label for="checkbox"><strong style="color: red;cursor: pointer;">SCORED ?</strong></label>
                                    </div>
                                </div>
                                <div class="field">
                                    <label style="visibility: hidden;">Add</label>
                                    <div class="ui fluid input">
                                        <button @click="addPenalty" class="ui button" type="button"><i class="fa fa-plus-circle"></i></button>
                                    </div>
                                </div>
                            </div>
                            <table class="ui celled unstackable table">
                                <tbody>
                                <tr v-for="penalty in penalties">
                                    <td>{{penalty.id}}</td>
                                    <td>{{penalty.team_name}}</td>
                                    <td>{{penalty.scored}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>



                        <!--Goals & Scorers-->
                        <div v-if="matche.sport_id == 1">
                            <div v-show="isExists('1') || isExists('2') " class="ui segment">
                                <label>Goals [{{goals | size}}]</label>
                                <div class="equal width fields">
                                    <div class="field">
                                        <label>#</label>
                                        <div class="ui fluid input">
                                            <input type="text" placeholder="Pos" v-model="goal_id">
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label>Team</label>
                                        <select v-model="selected_competitor" class="ui dropdown form-control" data-width="auto">
                                            <option value="">Select Team</option>
                                            <option v-for="competitor in competitors" v-bind:value="competitor.competitor_id">{{competitor.name}}</option>
                                        </select>
                                    </div>
                                    <div class="ui field">
                                        <label>Minute '</label>
                                        <div class="ui fluid input">
                                            <input type="text" placeholder="Goal minute" v-model="goal_period">
                                        </div>
                                    </div>
                                    <div class="ui field">
                                        <label style="visibility: hidden;">Add</label>
                                        <div class="ui checkbox field">
                                            <input id="checkbox2" type="checkbox" v-model="is_overtime" style="cursor: pointer;">
                                            <label for="checkbox2"><strong style="color: green;cursor: pointer;">Overtime ?</strong></label>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label style="visibility: hidden;">Add</label>
                                        <div class="ui fluid input">
                                            <button @click="addGoal" class="ui button" type="button"><i class="fa fa-plus-circle"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="isExists('2')" class="field">
                                    <label>Scorer</label>
                                    <select v-model="selected_scorer" class="ui dropdown form-control" data-width="auto">
                                        <option value="">Select Scorer</option>
                                        <option value="-1">No Scorer</option>
                                        <option v-for="scorer in scorers" v-bind:value="scorer.outcome_id">{{scorer.outcome_name}}</option>
                                    </select>
                                </div>
                                <table class="ui celled unstackable table">
                                    <tbody>
                                    <tr v-for="goal in goals">
                                        <td>{{goal.id}}</td>
                                        <td>{{goal.team_name}}</td>
                                        <td>{{goal.player_name}}</td>
                                        <td>{{goal.minute}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <!--Corners-->
                        <div v-show="isExists('3')" class="ui segment">
                            <label>Corners</label>

                            <div class="equal width fields">

                                <div class="field">
                                    <label>FT</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="Fulltime score" v-model="ft_corner">
                                    </div>
                                </div>

                                <div class="field">
                                    <label>HT</label>
                                    <div class="ui labeled input">
                                        <input type="text" placeholder="Halftime score" v-model="ht_corner">
                                    </div>
                                </div>

                            </div>
                            <!--                            <label>Corners [{{corners | size}}]</label>-->
                            <!--                            <div class="equal width fields">-->
                            <!--                                <div class="field">-->
                            <!--                                    <label>#</label>-->
                            <!--                                    <div class="ui fluid input">-->
                            <!--                                        <input type="text" placeholder="Pos" v-model="corner_id">-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <!--                                <div class="field">-->
                            <!--                                    <label>Team</label>-->
                            <!--                                    <select v-model="selected_competitor" class="ui dropdown form-control" data-width="auto">-->
                            <!--                                        <option value="">Select Team</option>-->
                            <!--                                        <option v-for="competitor in competitors" v-bind:value="competitor.competitor_id">{{competitor.name}}</option>-->
                            <!--                                    </select>-->
                            <!--                                </div>-->
                            <!--                                <div class="ui field">-->
                            <!--                                    <label>Minute '</label>-->
                            <!--                                    <div class="ui fluid input">-->
                            <!--                                        <input type="text" placeholder="Corner minute" v-model="corner_period">-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <!--                                <div class="field">-->
                            <!--                                    <label style="visibility: hidden;">Add</label>-->
                            <!--                                    <div class="ui fluid input">-->
                            <!--                                        <button @click="addCorner" class="ui button" type="button"><i class="fa fa-plus-circle"></i></button>-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <!--                            <div class="field">-->
                            <!--                                <label>Period</label>-->
                            <!--                                <select v-model="selected_period" class="ui dropdown form-control" data-width="auto">-->
                            <!--                                    <option value="">Select Period</option>-->
                            <!--                                    <option v-for="period in periods" v-bind:value="period.id">{{period.desc}}</option>-->
                            <!--                                </select>-->
                            <!--                            </div>-->
                            <!--                            <table class="ui celled unstackable table">-->
                            <!--                                <tbody>-->
                            <!--                                    <tr v-for="corner in corners">-->
                            <!--                                        <td>{{corner.id}}</td>-->
                            <!--                                        <td>{{corner.team_name}}</td>-->
                            <!--                                        <td>{{corner.period_name}}</td>-->
                            <!--                                        <td>{{corner.minute}}</td>-->
                            <!--                                    </tr>-->
                            <!--                                </tbody>-->
                            <!--                            </table>-->
                        </div>

                        <!--Bookings-->
                        <div v-show="isExists('4')" class="ui segment">
                            <label>Bookings [{{bookings | size}}]</label>
                            <div class="equal width fields">
                                <div class="field">
                                    <label>#</label>
                                    <div class="ui fluid input">
                                        <input type="text" placeholder="Pos" v-model="booking_id">
                                    </div>
                                </div>
                                <div class="field">
                                    <label>Team</label>
                                    <select v-model="selected_competitor" class="ui dropdown form-control" data-width="auto">
                                        <option value="">Select Team</option>
                                        <option v-for="competitor in competitors" v-bind:value="competitor.competitor_id">{{competitor.name}}</option>
                                    </select>
                                </div>
                                <div class="ui field">
                                    <label>Minute '</label>
                                    <div class="ui fluid input">
                                        <input type="text" placeholder="Booking minute" v-model="booking_period">
                                    </div>
                                </div>
                                <div class="field">
                                    <label style="visibility: hidden;">Add</label>
                                    <div class="ui fluid input">
                                        <button @click="addBooking" class="ui button" type="button"><i class="fa fa-plus-circle"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="equal width fields">
                                <div class="field">
                                    <label>Type</label>
                                    <select v-model="selected_card" class="ui dropdown form-control" data-width="auto">
                                        <option value="">Select Card</option>
                                        <option v-for="card in cards" v-bind:value="card.id">{{card.desc}}</option>
                                    </select>
                                </div>
                                <div class="field">
                                    <label>Period</label>
                                    <select v-model="selected_period" class="ui dropdown form-control" data-width="auto">
                                        <option value="">Select Period</option>
                                        <option v-for="period in periods" v-bind:value="period.id">{{period.desc}}</option>
                                    </select>
                                </div>
                                <div class="field">
                                    <label>Scorer</label>
                                    <select v-model="selected_scorer" class="ui dropdown form-control" data-width="auto">
                                        <option value="">Select Scorer</option>
                                        <option value="">No Scorer</option>
                                        <option v-for="scorer in scorers" v-bind:value="scorer.outcome_id">{{scorer.outcome_name}}</option>
                                    </select>
                                </div>
                            </div>
                            <table class="ui celled unstackable table">
                                <tbody>
                                <tr v-for="booking in bookings">
                                    <td>{{booking.id}}</td>
                                    <td>{{booking.team_name}}</td>
                                    <td>{{booking.player_name}}</td>
                                    <td>{{booking.minute}}</td>
                                    <td>{{booking.card}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <!--Partial resulting-->
                        <div v-show='is_partial_resulting' class="equal width fields">
                            <div class="field">
                                <label>Markets</label>
                                <select v-model="selected_pending_market" class="ui multiple dropdown" multiple="multiple">
                                    <option value="">All Markets</option>
                                    <option v-for="market in pending_markets" v-bind:value="market.id">{{market.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="ui deny button">
                Cancel
            </div>
            <div v-bind:class="resulting_loader" @click="resultMatche" class="ui green left labeled icon button">
                <i class="checkmark icon"></i> Result MATCH
            </div>
        </div>

    </div>
</template>
<script>
    import {config} from '../config/config';

    export default {

        components: {
            config
        },

        props:{
            data:{
                type: Object
            }
        },

        data() {
            return {




            search_matches: '',
                user_id: '',
                token: '',
            start: '',
            end: '',
            selected_pending_market: [],
            resulting_loader: '',
            winning_method: 0,
            ht_score: '',
            ft_score: '',
            ht_overtime_score: '',
            ft_overtime_score: '',
            q1t_score: '',
            q2t_score: '',
            q3t_score: '',
            q4t_score: '',
            selected_competitor: '',
            selected_competitor_id: '',
            is_partial_resulting: false,
            is_ghana_resulting: false,
            is_penalty_scored: false,
            is_tennis_professional: false,
            is_baseball_professional: false,
            is_cricket_professional:false,
            is_Rugby_professional:false,
            penalty_id: '',
            penalties: [],
            is_overtime: false,
            goals: [],
            goal_id: '',
            goal_period: '',
            tiers: [],
            overtime: 0,
            scorers: [],
            selected_scorer: '',
            corners: [],
            corner_id: '',
            corner_period: '',
            periods: [{id: 1, desc: '1st Period'}, {id: 2, desc: '2nd Period'}, {id: 3, desc: '3rd Period'}, {id: 4, desc: '4th Period'}],
            selected_period: '',
            bookings: [],
            booking_id: '',
            booking_period: '',
            cards: [{id: 1, desc: 'Yellow'}, {id: 2, desc: 'Red'}],
            selected_card: '',
            s1t_score: '',
            s2t_score: '',
            s3t_score: '',
            ft_corner: '',
            ht_corner: '',
            s4t_score: '',
            s5t_score: '',
            s6t_score: '',
                ghana: 0,
            s7t_score: '',
            s8t_score: '',
            s9t_score: '',
            s10t_score: '',
            edit_promo_loader: '',
            promo_title: '',
            promo_action: '',
            promo_url: '',
            promo_content: '',
            promo_expiry: '',
            promo: null,
            promoo: null,
            selected_competion: null,
            editor: null,
            selected_sport: null,
            competition_info: null,
            sports_info: null,
            message: '',
            des: '',
            final: '',
                sport_id: 1,
                matche:this.data



            }
        },


        watch:{
          data(newVal,oldVal){

              this.matche = newVal;
          }
        },




        computed: {
            competitors: function () {
                var vm = this;
                var teams = [];

                if (vm.matche != null) {
                    teams = [{competitor_id: 10, name: "None"}, {competitor_id: 1, name: vm.matche.home_team}, {competitor_id: 2, name: vm.matche.away_team}];
                }

                return teams;
            },


            pending_markets: function () {
                var vm = this;

                if(vm.matche.markets) {


                    var m = (vm.matche.markets).split('----');
                    var ms = [];

                    for (var count = 0; count < m.length; count++) {
                        var items = m[count].split('#');
                        var item = {
                            id: items[0],
                            name: items[1]
                        };

                        ms.push(item);
                    }
                    $(".ui.dropdown").dropdown();

                    return ms;
                }else {
                    return null
                }
            },




        },

        mounted(){
            var vm = this;

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var id=jsonData.id
            var tokenn=jsonData.token

            vm.user_id=id;
            vm.token=tokenn;


        },





        methods: {
            createdAt: function (value) {
                if (!value) {
                    return '-';
                } else {
                    return moment(value).format('HH:mm DD/MM/YY');
                }
            },
            dateFilter: function (start, end) {
                this.start = start;
                this.end = end;

                this.moreParams[0] = 'start=' + start;
                this.moreParams[1] = 'end=' + end;
                this.$refs.vuetable_matches.$dispatch('vuetable:reload');
            },
            isExists: function (val) {

                var vm=this;

                if (vm.matche.tiers) {
                    return vm.matche.tiers.includes(val);
                }else {
                    return null
                }


            },
            showDetailRow: function (value) {
                var icon = this.$refs.vuetable_matches.isVisibleDetailRow(value) ? 'minus' : 'plus';
                return [
                    '<a class="show-detail-row">',
                    '<i class=" circle ' + icon + ' icon"></i>',
                    '</a>'
                ].join('');
            },
            searchMatches: function () {
                var vm = this;
                vm.moreParams[2] = 'filter=' + vm.search_matches;
                vm.$refs.vuetable_matches.$dispatch('vuetable:reload');
            },
            addPenalty: function () {
                var vm = this;

                if (!vm.penalty_id || !vm.selected_competitor) {
                    swal("Missing!", "All fields are REQUIRED!", "warning");
                    return;
                }

                var penaltyExists = false;

                var teamName = '';
                $.each(vm.competitors, function (index, item) {
                    if (item.competitor_id == vm.selected_competitor) {
                        teamName = item.name;
                        return;
                    }
                });

                var penalty = {
                    id: vm.penalty_id,
                    team_id: vm.selected_competitor,
                    team_name: teamName,
                    scored: vm.is_penalty_scored ? 1 : 0
                };

                $.each(vm.penalties, function (index, item) {
                    var pID = item.id;

                    if (pID == vm.penalty_id) {
                        penaltyExists = true;
                    }
                });

                if (!penaltyExists) {
                    vm.penalties.push(penalty);
                }

                vm.penalty_id = '';
                vm.selected_competitor = '';

                $('#result-matche').modal('reload');

            },
            addGoal: function () {

                var vm = this;

                if (!vm.goal_id || !vm.selected_competitor || !vm.goal_period || (vm.isExists(2) && !vm.selected_scorer)) {
                    swal("Missing!", "All fields are REQUIRED!", "warning");
                    return;
                }

                var playerName = '';
                var teamName = '';

                $.each(vm.competitors, function (index, item) {
                    if (item.competitor_id == vm.selected_competitor) {
                        teamName = item.name;
                        return;
                    }
                });

                $.each(vm.scorers, function (index, item) {
                    if (item.outcome_id == vm.selected_scorer) {
                        playerName = item.outcome_name;
                        return;
                    }
                });

                var goalExists = false;
                var goal = {
                    id: vm.goal_id,
                    team_id: vm.selected_competitor,
                    team_name: teamName,
                    player_id: vm.selected_scorer,
                    player_name: playerName,
                    minute: vm.goal_period,
                    overtime: vm.is_overtime ? 1 : 0
                };

                $.each(vm.goals, function (index, item) {
                    var pID = item.id;
                    if (pID == vm.goal_id) {
                        goalExists = true;
                    }
                });

                if (!goalExists) {
                    vm.goals.push(goal);
                }

                vm.is_overtime = false;
                vm.goal_id = '';
                vm.goal_period = '';
                vm.selected_competitor = '';
                vm.selected_scorer = '';

                $('#result-matche').modal('reload');

            },
            addCorner: function () {
                var vm = this;

                if (!vm.corner_id || !vm.selected_competitor || !vm.corner_period || !vm.selected_period) {
                    swal("Missing!", "All fields are REQUIRED to add a CORNER!", "warning");
                    return;
                }

                var teamName, periodName;

                $.each(vm.competitors, function (index, item) {
                    if (item.competitor_id == vm.selected_competitor) {
                        teamName = item.name;
                        return;
                    }
                });

                $.each(vm.periods, function (index, item) {
                    if (item.id == vm.selected_period) {
                        periodName = item.desc;
                        return;
                    }
                });

                var cornerExists = false;
                var corner = {
                    id: vm.corner_id,
                    team_id: vm.selected_competitor,
                    team_name: teamName,
                    period_id: vm.selected_period,
                    period_name: periodName,
                    minute: vm.corner_period
                };

                $.each(vm.corners, function (index, item) {
                    var pID = item.id;
                    if (pID == vm.corner_id) {
                        cornerExists = true;
                    }
                });

                if (!cornerExists) {
                    vm.corners.push(corner);
                }

                vm.corner_id = '';
                vm.corner_period = '';
                vm.selected_competitor = '';

                $('#result-matche').modal('reload');

            },
            addBooking: function () {
                var vm = this;

                if (!vm.booking_id || !vm.selected_competitor || !vm.selected_card || !vm.booking_period || (vm.isExists(4) && !vm.selected_scorer) || !vm.selected_period) {
                    swal("Missing!", "All fields are REQUIRED to add a BOOKING!", "warning");
                    return;
                }

                var playerName, periodName, teamName;
//            var teamName;

                $.each(vm.competitors, function (index, item) {
                    if (item.competitor_id == vm.selected_competitor) {
                        teamName = item.name;
                        return;
                    }
                });

                $.each(vm.scorers, function (index, item) {
                    if (item.outcome_id == vm.selected_scorer) {
                        playerName = item.outcome_name;
                        return;
                    }
                });

                $.each(vm.periods, function (index, item) {
                    if (item.id == vm.selected_period) {
                        periodName = item.desc;
                        return;
                    }
                });

                var bookingExists = false;
                var booking = {
                    id: vm.booking_id,
                    team_id: vm.selected_competitor,
                    team_name: teamName,
                    player_id: vm.selected_scorer,
                    player_name: playerName,
                    period_id: vm.selected_period,
                    period_name: periodName,
                    minute: vm.booking_period,
                    card: vm.selected_card
                };

                $.each(vm.bookings, function (index, item) {
                    var pID = item.id;
                    if (pID == vm.booking_id) {
                        bookingExists = true;
                    }
                });

                if (!bookingExists) {
                    vm.bookings.push(booking);
                }

                vm.booking_id = '';
                vm.booking_period = '';
                vm.selected_competitor = '';
                vm.selected_scorer = '';

                $('#result-matche').modal('reload');
            },
            getScorers: function (id) {
                var vm = this;
                $.ajax({
                    url: config.baseURL+'resulting/scorers?id=' + id,
                    type: 'GET',
                    success: function (response, textStatus, jQxhr) {


                        if (response != null) {
//                        response.unshift({});
                            vm.scorers = response;
                            $('#result-matche').modal('reload');
                        } else {
                            vm.scorers = [];
                        }
                    },
                    error: function (jqXhr, textStatus, error) {
                        vm.scorers = [];
                    }
                });
            },
            resultMatche: function () {


                var vm = this;
                var periods = [];
                var corner = [];

                if (vm.matche.sport_id == 1 ) {
                    //Soccer
                    if (!vm.ht_score || !vm.ft_score || ((!vm.ht_overtime_score || !vm.ft_overtime_score) && (vm.overtime == 1 || vm.overtime == 2)) || (vm.overtime == 2 && vm.penalties.length == 0) || (vm.goals.length == 0 && (vm.isExists('2') || vm.isExists('1'))) || (vm.isExists(3))) {
                        vm.$swal("Missing!", "All fields are required for RESULTING sport 1!", "warning");
                        return;
                    }
                }

                if (vm.matche.sport_id == 2) {
                    if (!vm.q1t_score || !vm.q2t_score || !vm.q3t_score || !vm.q4t_score || (vm.overtime == 1 && !vm.ft_overtime_score)) {
                        vm.$swal("Missing!", "All fields are required for RESULTING!", "warning");
                        return;
                    }
                }

                if (vm.matche.sport_id == 5 || vm.matche.sport_id == 7 || vm.matche.sport_id == 26 || vm.matche.sport_id == 31) {
                    if (!vm.s1t_score || !vm.s2t_score || (vm.is_tennis_professional && !vm.s4t_score)) {
                        vm.$swal("Missing!", "All fields are required for RESULTING!", "warning");
                        return;
                    }
                }

                if (vm.matche.sport_id == 12 ||vm.matche.sport_id == 6 ) {
                    if (!vm.s1t_score || !vm.s2t_score || (vm.is_Rugby_professional && !vm.s3t_score)) {
                        vm.$swal("Missing!", "All fields are required for RESULTING!", "warning");
                        return;
                    }
                }

                if (vm.matche.sport_id == 21) {
                    if (!vm.s1t_score) {
                        vm.$swal("Missing!", "All fields are required for RESULTING!", "warning");
                        return;
                    }
                }

                if (vm.matche.sport_id == 3) {
                    if (!vm.s1t_score || !vm.s2t_score || !vm.s3t_score || !vm.s4t_score ||!vm.s5t_score ||!vm.s6t_score ||!vm.s7t_score ||!vm.s8t_score ||!vm.s9t_score || (vm.is_baseball_professional && !vm.s10t_score)) {
                        vm.$swal("Missing!", "All fields are required for RESULTING!", "warning");
                        return;
                    }
                }

                if (vm.resulting_loader) {
                    return;
                }

                vm.resulting_loader = 'loading';

                if (vm.matche.sport_id == 1) {


                    //Soccer
                    var p1 = vm.ht_score.split(':');
                    var ft = vm.ft_score.split(':');


                    var winning =vm.winning_method

                    periods.push({
                        number: 1,
                        type: 0,
                        home_score: p1[0],
                        away_score: p1[1]
                    });

                    periods.push({
                        number: 2,
                        type: 0,
                        home_score: ft[0] - p1[0],
                        away_score: ft[1] - p1[1]
                    });

                    if (vm.overtime == 1 || vm.overtime == 2 || vm.overtime == 3) {


                        var p3 = vm.ht_overtime_score.split(':');
                        var p4 = vm.ft_overtime_score.split(':');
                        periods.push({
                            number: 3,
                            type: 1,
                            home_score: p3[0],
                            away_score: p3[1]
                        });

                        periods.push({
                            number: 4,
                            type: 1,
                            home_score: p4[0] - p3[0],
                            away_score: p4[1] - p3[1]
                        });
                    }

                    if(vm.ht_corner && vm.ft_corner){



                        var c1 = vm.ht_corner.split(':');
                        var c2 = vm.ft_corner.split(':');




                        vm.corners.push({
                            number: 1,
                            type: 0,
                            home_score: c2[0],
                            away_score: c2[1]
                        });

                        vm.corners.push({
                            number: 2,
                            type: 0,
                            home_score: c1[0],
                            away_score: c1[1]
                        });

                    }
                }

                if (vm.matche.sport_id == 2) {
                    //Basketball
                    var p1 = vm.q1t_score.split(':');
                    var p2 = vm.q2t_score.split(':');
                    var p3 = vm.q3t_score.split(':');
                    var p4 = vm.q4t_score.split(':');

                    periods.push({
                        number: 1,
                        type: 0,
                        home_score: p1[0],
                        away_score: p1[1]
                    });

                    periods.push({
                        number: 2,
                        type: 0,
                        home_score: p2[0],
                        away_score: p2[1]
                    });
                    periods.push({
                        number: 3,
                        type: 0,
                        home_score: p3[0],
                        away_score: p3[1]
                    });
                    periods.push({
                        number: 4,
                        type: 0,
                        home_score: p4[0],
                        away_score: p4[1]
                    });

                    if (vm.overtime == 1 || vm.overtime == 2 || vm.overtime == 3) {
                        var p5 = vm.ft_overtime_score.split(':');
                        periods.push({
                            number: 1,
                            type: 1,
                            home_score: p5[0],
                            away_score: p5[1]
                        });
                    }
                }


                if (vm.matche.sport_id == 3) {
                    //Basketball
                    var p1 = vm.s1t_score.split(':');
                    var p2 = vm.s2t_score.split(':');
                    var p3 = vm.s3t_score.split(':');
                    var p4 = vm.s4t_score.split(':');
                    var p5 = vm.s5t_score.split(':');
                    var p6 = vm.s6t_score.split(':');
                    var p7 = vm.s7t_score.split(':');
                    var p8 = vm.s8t_score.split(':');
                    var p9 = vm.s9t_score.split(':');

                    periods.push({
                        number: 1,
                        type: 0,
                        home_score: p1[0],
                        away_score: p1[1]
                    });

                    periods.push({
                        number: 2,
                        type: 0,
                        home_score: p2[0],
                        away_score: p2[1]
                    });
                    periods.push({
                        number: 3,
                        type: 0,
                        home_score: p3[0],
                        away_score: p3[1]
                    });
                    periods.push({
                        number: 4,
                        type: 0,
                        home_score: p4[0],
                        away_score: p4[1]
                    });

                    periods.push({
                        number: 5,
                        type: 0,
                        home_score: p5[0],
                        away_score: p5[1]
                    });

                    periods.push({
                        number: 6,
                        type: 0,
                        home_score: p6[0],
                        away_score: p6[1]
                    });

                    periods.push({
                        number: 7,
                        type: 0,
                        home_score: p7[0],
                        away_score: p7[1]
                    });


                    periods.push({
                        number: 8,
                        type: 0,
                        home_score: p8[0],
                        away_score: p8[1]
                    });

                    periods.push({
                        number: 9,
                        type: 0,
                        home_score: p9[0],
                        away_score: p9[1]
                    });

                    if (vm.is_baseball_professional) {
                        var p10 = vm.s10t_score.split(':');

                        periods.push({
                            number: 10,
                            type: 0,
                            home_score: p10[0],
                            away_score: p10[1]
                        });

                    }


                }


                if (vm.matche.sport_id == 12 || vm.matche.sport_id == 6 || vm.matche.sport_id == 29 ) {
                    //Basketball
                    var p1 = vm.s1t_score.split(':');
                    var p2 = vm.s2t_score.split(':');


                    periods.push({
                        number: 1,
                        type: 0,
                        home_score: p1[0],
                        away_score: p1[1]
                    });

                    periods.push({
                        number: 2,
                        type: 0,
                        home_score: p2[0],
                        away_score: p2[1]
                    });


                    if (vm.is_Rugby_professional) {
                        var p3 = vm.s3t_score.split(':');

                        periods.push({
                            number: 3,
                            type: 0,
                            home_score: p3[0],
                            away_score: p3[1]
                        });

                    }


                }


                if (vm.matche.sport_id == 21 || vm.matche.sport_id == 19 || vm.matche.sport_id == 13 || vm.matche.sport_id == 10) {
                    //Basketball
                    var p1 = vm.s1t_score.split(':');
                    var p2 = vm.s1t_score.split(':');


                    periods.push({
                        number: 1,
                        type: 0,
                        home_score: p1[0],
                        away_score: p1[1]
                    });

                }

                if (vm.matche.sport_id == 5  ||  vm.matche.sport_id == 7  ||  vm.matche.sport_id == 31||  vm.matche.sport_id == 26 || vm.matche.sport_id == 20 ||  vm.matche.sport_id == 23  ||  vm.matche.sport_id == 4  ||  vm.matche.sport_id == 34 ||  vm.matche.sport_id == 16) {
                    //Tennis
                    var p1 = vm.s1t_score.split(':');
                    var p2 = vm.s2t_score.split(':');
                    var p3 = vm.s3t_score ? vm.s3t_score.split(':') : null;

                    periods.push({
                        number: 1,
                        type: 0,
                        home_score: p1[0],
                        away_score: p1[1]
                    });

                    periods.push({
                        number: 2,
                        type: 0,
                        home_score: p2[0],
                        away_score: p2[1]
                    });

                    if (p3) {
                        periods.push({
                            number: 3,
                            type: 0,
                            home_score: p3[0],
                            away_score: p3[1]
                        });
                    }

                    if (vm.is_tennis_professional) {
                        var p4 = vm.s4t_score.split(':');


                        var p5 = vm.s5t_score ? vm.s5t_score.split(':') : null;
                        var p6 = vm.s6t_score ? vm.s6t_score.split(':') : null;
                        var p7 = vm.s7t_score ? vm.s7t_score.split(':') : null;



                        periods.push({
                            number: 4,
                            type: 0,
                            home_score: p4[0],
                            away_score: p4[1]
                        });

                        if (p5) {
                            periods.push({
                                number: 5,
                                type: 0,
                                home_score: p5[0],
                                away_score: p5[1]
                            });
                        }

                        if (p6) {
                            periods.push({
                                number: 6,
                                type: 0,
                                home_score: p5[0],
                                away_score: p5[1]
                            });
                        }
                        if (p7) {
                            periods.push({
                                number: 7,
                                type: 0,
                                home_score: p5[0],
                                away_score: p5[1]
                            });
                        }
                    }
                }



                //Penalties
                var h = 0, a = 0;
                $.each(vm.penalties, function (index, item) {
                    if (item.scored) {
                        if (item.team_id == 1) {
                            h = h + 1;
                        } else {
                            a = a + 1;
                        }
                    }
                });

                if (vm.penalties.length != 0) {
                    periods.push({
                        number: 1,
                        type: 2,
                        home_score: h,
                        away_score: a
                    });
                }


                if(vm.is_ghana_resulting === true){
                     vm.ghana=1
                }


                var data = {
                    parent_match_id: vm.matche.parent_match_id,
                    destination: vm.ghana,
                    sport_id: vm.matche.sport_id,
                    tiers: vm.matche.tiers.split(','),
                    overtime: vm.matche.overtime,
                    markets: vm.selected_pending_market,
                    periods: periods,
                    penalties: vm.penalties,
                    goals: vm.goals,
                    corners: vm.corners,
                    bookings: vm.bookings,
                    kickoff: vm.selected_competitor_id,
                    ghana: vm.ghana,
                    user_id: vm.user_id,
                    toke : vm.token
                };


                $.ajax({
                    url: config.baseURLWEB+'odibets/resulting',
                    type: 'POST',
                    data: data,
                    success: function (response, textStatus, jQxhr) {
                        vm.resulting_loader = '';
                        $('#modal-resulting').modal('hide');


                        if (response.status_code == 200) {
                            window.location.reload()
                            vm.$swal("Success!", response.status_description, "success");
                            vm.ht_score = '';
                            vm.ft_score = '';
                            vm.ft_corner = '';
                            vm.ht_corner = '';
                            vm.void_factor = '';
                            vm.goals='';
                            vm.markets='';
                            vm.ghana=''
                            vm.kickoff='';
                        } else {
                            vm.$swal("Error!", response.status_description, "error");
                        }
                    },
                    error: function (jqXhr, textStatus, error) {
                        vm.resulting_loader = '';
                        vm.$swal("Error!", 'Could NOT process the resulting request ' + error, "error");
                    }
                });
            },



            getsport: function () {
                var vm = this;

                var data = {
                    parent_match_id: vm.matche.parent_match_id,

                };


                $.ajax({
                    // url: 'http://35.189.221.84/odibets_dashboard/resulting/sports',
                    url: config.baseURLWEB+'resulting/sports',
                    type: 'POST',
                    data: JSON.stringify(data),
                    success: function (response, textStatus, jQxhr) {

                        vm.sports_info = response.data;
                    },
                    error: function (jqXhr, textStatus, error) {

                        vm.$swal("Error!", error, "error");
                    }
                });
            },


            closeModal(){
                $(".ui.modal#modal-approvals").modal("hide");
            }
        },
        filters: {
            size: function (val) {
                return val.length;
            }
        },
        created: function () {
            var a = moment([2018, 9, 24]);
            var b = moment();
            var diff = b.diff(a, 'days') + 1;
            this.currentDay = 'DAY ' + diff; // 1

        },

    }
</script>