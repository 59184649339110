<template>
    <div class="l-login">

        <h2>Verification Code</h2>
        <div class="l-form">

            <form v-on:submit.prevent="goToDashboard()" method="POST" class="ui form padding-top-lg">
                <div class="field">
                    <label>Enter code</label>
                    <input class="" type="password" placeholder="XXXX" v-model="code" required />
                </div>
                <div class="field">
                    <button  v-if="loginstatus" type="submit" class="ui button fluid bg-primary" :class="loading">Verify Code</button>

                    <button v-if="!loginstatus" disabled class="ui button fluid bg-primary">Please Wait</button>

                    <br /><br />
                    <a @click="back" class="">Back to login </a>
                </div>
            </form>

            <div v-if="alert_visible" class="ui positive message transition visible">
                <i class="close icon"></i>
                {{alert_message}}
            </div>
        </div>

    </div>
</template>



<script>
    import {config} from '../config/config';

    export default {
        components: {
            config
        },
        name: 'Login',
        data() {
            return {
                loginstatus: true,
                username: '',
                code: '',
                password: '',
                loading: [],
                alert_visible: false,
                alert_message: '',
                user_id:'',
                role:''

            }
        },


        mounted(){




        },
        methods: {
            dismiss: function () {
                this.alert_visible = false;
            },
            goToDashboard() {

                this.loginstatus=false;

                var required = [];

                if (!this.code) {
                    required.push('password');
                }


                this.alert_visible = false;
                var vm = this;
                let loginData = {
                    'code': vm.code,
                    'profile_id':window.localStorage.getItem("idd"),
                }

                var now = new Date();
                now.setMinutes(now.getMinutes() + 360); // timestamp
                now = new Date(now); // Date object

                $.post({
                    url: config.baseURL+'portal/verify/acc',
                    type: "POST",
                    data:  JSON.stringify(loginData),
                    success: function(data){
                        vm.loading = [];

                        vm.loginstatus=true;

                        if(data.status_code == 200) {

                            vm.loginstatus=true;

                            let authenticated = data.data.authenticated;
                            if (authenticated) {
                                let user = data.data.data;

                                let obj = JSON.stringify(user);

                                var jsonData = JSON.parse(obj);
                                var role = jsonData.role

                                window.localStorage.setItem('user_info', obj);
                                window.localStorage.setItem('timer', now);

                                const Toast = vm.$swal.mixin({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 9000
                                });
                                Toast.fire({
                                    type: 'success',
                                    title: "Welcome back"
                                })

                                window.localStorage.removeItem("idd");


                                if (role == 2) {

                                    vm.$router.push({name: 'dash-ke'});
                                } else if (role == 4) {
                                    vm.$router.push({name: 'profiles'});
                                }
                                else if (role == 3) {
                                    vm.$router.push({name: 'profiles'});
                                }

                                else if (role == 5) {
                                    vm.$router.push({name: 'profiles'});
                                }
                                else if (role == 10) {
                                    vm.$router.push({name: 'report'});

                                }else if (role == 11) {
                                    vm.$router.push({name: 'virtualReport'});
                                }
                                else if (role == 14) {
                                  vm.$router.push({name: 'instantReport'});
                                }


                                else {
                                    alert("Invalid request")
                                }

                            }else {

                            }
                        }else {

                            vm.loginstatus=true;

                            let desc = data.status_description;
                            const Toast = vm.$swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000
                            });
                            Toast.fire({
                                type: 'error',
                                title: desc
                            })


                        }
                    },
                    error: function(jQxhr, status){

                        vm.loginstatus=true;

                        vm.loading = [];
                        vm.alert_message = 'Error while processing!';
                        vm.alert_visible = true;

                        console.log(jQxhr, status);
                    }
                })

            },


            back(){
                var vm = this;
                vm.$router.push({name: 'login'});
            }

        }
    }



</script>