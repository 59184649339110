<template>

    <div class="ui modal" id="sports">
        <i class="close icon"></i>

        <div class="header">
            Sports   Window
        </div>
        <div class="content">
            <div class="ui form">
                <div class="equal width fields">
                    <div class="field">
                        <label>Sports</label>
                        <select v-model="selectedSport"  class="ui  dropdown">
                            <option value="">All Sports</option>
                            <option v-for="market in pending_market" v-bind:value="market.id">{{market.name}}</option>
                        </select>

                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="ui deny button">
                Cancel
            </div>
            <div  @click="changeSport" class="ui green left labeled icon button">
                <i class="checkmark icon"></i> Reset  Match
            </div>
        </div>


    </div>


</template>

<script>

    import {config} from '../config/config';

    export default {

        components: {
            config
        },
        props:{
            data:{
                type: Object,


            }
        },


        data() {
            return {
                user_id:'',
                role:'',
                token:'',
                match_data:this.data,
                selectedSport:'',
                pending_market: [],
                parent_match_id:'',
                selected_pending_markett:'',

            }
        },


        watch:{
            data(newVal,oldVal){


                this.match_data = newVal;
                this.pending_market=this.match_data.selected_data
                this.parent_match_id=this.match_data.parent_match_id
                // this.getWrongMarkets()
            }
        },
        computed: {


        },
        created() {

            // this.getWrongMarkets()

            $('.ui.modal#sports').modal({
                closable: true
            }).modal('hide');

            this.pending_market=this.match_data.selected_data
            this.parent_match_id=this.match_data.parent_match_id

           // this.pending_market=this.match_data.selected_data



        },

        mounted() {
            //var headerHeight = document.getElementById('header').offsetHeight;
            //document.getElementById('main-content').style.top = headerHeight + 'px';

            var vm = this;


            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=jsonData.token;

            vm.pending_market=vm.match_data.selected_data
            vm.parent_match_id=vm.match_data.parent_match_id


           // vm.pending_market=vm.match_data.selected_data

        },


        methods: {

            changeSport:function () {


                var vm=this;

                if (!vm.selectedSport) {
                    vm.$swal("Missing!", "All fields are REQUIRED!", "warning");
                    return;
                }

                vm.$swal.fire({
                    title: 'Match Reset?',
                    text: "If you are sure, Reset match type resetting:!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor:  '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Reset match!'
                }).then((result) => {
                    if (result.value) {
                        var dataa = {
                            parent_match_id: vm.parent_match_id,
                            id: vm.selectedSport,
                            user_id: vm.user_id,
                            token: vm.token
                        };

                        $.ajax({
                            url: config.baseURL+'match/type',
                            type: 'POST',
                            data: JSON.stringify(dataa),
                            success: function (response, textStatus, jQxhr) {

                                if (response.status_code == 200) {
                                    $('#markets').modal('hide');
                                    vm.$swal("Success!", response.status_description, "success");

                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }
                })


            },

            getWrongMarkets: function () {
                var vm = this;

                var data = {
                    parent_match_id: vm.match_data.parent_match_id,
                    token: vm.token,
                };
                $.ajax({
                    url: config.baseURL+'dash/wrong/markets',
                    type: 'POST',
                    data: JSON.stringify(data),
                    success: function (data, textStatus, jQxhr) {

                        $(".ui.dropdown").dropdown();


                        vm.pending_market = data;
                    },
                    error: function (jqXhr, textStatus, error) {
                        vm.edit_promo_loader = '';
                        vm.$swal("Error!", error, "error");
                    }
                });

            },





        },

    }
</script>