<template>
    <div class="ui tiny modal" id="taxupload">

        <i class="close icon"></i>
        <div class="header">
            Submit Tax
        </div>
        <div class="scrolling content">
            <div class="ui grid">
                <div class="column">
                    <div class="ui form">

                        <div class="equal width fields">
                        <div class="field">
                          <label>Bonus Type</label>
                          <select v-model="selected_type" class="ui dropdown form-control" data-width="auto">
                            <option value="">Select Tax Type</option>
                            <option value="1">EXCISE TAX</option>
                            <option value="2">WITHHOLDING TAX</option>
                            <option value="3">CASINO EXCISE TAX</option>
                            <option value="4">CASINO WITHHOLDING TAX</option>
                          </select>
                        </div>
                      </div>
                        <div class="field">
                            <label>Deposit Amount</label>
                            <div class="ui fluid input">
                                <input type="text" class="" placeholder="Provide Deposited Amount" v-model="amount">
                            </div>
                        </div>
                        <div class="equal width fields">
                            <div class="field">
                                <label>Deposit Date</label>
                                <div class="ui fluid input">
                                    <input type="date" placeholder="Deposit Date" v-model="date">
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

    </div>
        <div class="actions">
            <div class="ui deny button">
                Cancel
            </div>
            <div  @click="raiseDepositIssue" class="ui green left labeled icon button">
                <i class="checkmark icon"></i> Submit Issue
            </div>
        </div>
    </div>
</template>
<script>
    import {config} from '../config/config';
    export default {
        components: {
            config
        },
        props:{
            data:{
                type: Object
            }
        },
        mounted(){
            var vm = this;

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;
            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;
        },

        data() {
            return {
                user_id:'',
                role:'',
                token:'',
                date:'',
                selected_type:'',
                amount:'',
                deposit_data:this.data
            }
        },
        watch:{
            data(newVal,oldVal){
                this.deposit_data = newVal;
            }
        },
        methods:{
            closeModal(){
                $(".ui.modal#taxupload").modal("hide");
            },

            raiseDepositIssue: function () {
                var vm = this;
                console.log("Profile | " + JSON.stringify(vm.profile));
                if (!vm.amount || !vm.date || !vm.selected_type) {
                    vm.$swal("Missing!", "All fields are required!", "warning");
                    return;
                }
                if (vm.dep_loader) {
                    return;
                }
                vm.dep_loader = 'loading';
                var data = {
                    amount: vm.amount,
                    date: vm.date,
                    type: vm.selected_type,
                    token: vm.token
                };
                $.ajax({
                    url: config.baseURL+'portal/tax/submit',
                    type: 'POST',
                    data: JSON.stringify(data),
                    success: function (response, textStatus, jQxhr) {
                          vm.date = '';
                          vm.type = '';
                          vm.amount = '';
                          vm.selected_type = '';
                          vm.deposit_data=''

                          $('.ui.modal#taxupload').modal({
                            closable: true
                          }).modal('hide');



                        if (response.status_code == 200) {
                            vm.$swal("Success!", response.status_description, "success");



                             //location.reload();

                        } else {
                            vm.$swal("Error!", response.status_description, "error");

                            //location.reload();

                          $('#taxupload').modal('hide');
                            $('.ui.modal#taxupload').modal({
                                closable: true
                            }).modal('hide');
                        }
                    },
                    error: function (jqXhr, textStatus, error) {
                        vm.date = '';
                        vm.type = '';
                        vm.amount = '';
                        vm.selected_type = '';
                        vm.deposit_data=''
                         //location.reload();
                        vm.$swal("Error!", error, "error");
                      $('#taxupload').modal('hide');
                      $('.ui.modal#taxupload').modal({
                        closable: true
                      }).modal('hide');

                    }
                });
            },
        }
    }
</script>