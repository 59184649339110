<template>
    <div>
    <div class="ui padded grid row">
        <div class="ui eight wide column" style="border-bottom:2px solid #b1b1b1;">

            <div style="font-size:0.9em;color:#000;">Markets: {{marketCount}}</div>
        </div>
        <div class="ui eight wide column" style="border-bottom:2px solid #b1b1b1;">
            <div style="font-size:0.9em;color:#000;">Bets: {{rowData.bets}}</div>
        </div>
    </div>
    <div class="ui padded grid row">
        <div v-for="market in markets"  v-bind:value="market.sub_type_id" v-bind:key="market.sub_type_id" class="ui eight wide column" style="border-bottom:1px solid #e1e1e1;">
            <div style="font-size:0.9em;color:#000;">{{market}}</div>
        </div>
    </div>
    </div>

</template>

<script>

    export default {


        computed: {
            markets: function () {
                return (this.rowData.markets).split('----');
            },
            marketCount: function () {
                if (!this.rowData.markets) {
                    return 0;
                } else {
                    return this.rowData.markets.split('----').length;
                }
            }
        },

        components: {},

        props: {
            rowData: {
                type: Object,
                required: true
            }
        },

        methods: {}
    }
</script>

<style>
    .custom-actions button.ui.button {
        padding: 8px 8px;
    }

    .custom-actions button.ui.button > i.icon {
        margin: auto !important;
    }
</style>