<template>
    <div class="ui tiny modal" id="deposit">

        <i class="close icon"></i>
        <div class="header">
            Raise DEPOSIT Issue
        </div>
        <div class="scrolling content">
            <div class="ui grid">
                <div class="column">
                    <div class="ui form">
                        <div class="equal width fields">
                            <div class="field">
                                <label>MPESA Code</label>
                                <div class="ui labeled input">
                                    <input type="text" style="text-transform: uppercase;" placeholder="Enter Correct MPESA Code" v-model="mpesa_code">
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label>Deposit Amount</label>
                            <div class="ui fluid input">
                                <input type="text" class="" placeholder="Provide Deposited Amount" v-model="deposit_amount">
                            </div>
                        </div>
                        <div class="equal width fields">
                            <div class="field">
                                <label>Deposit Date</label>
                                <div class="ui fluid input">
                                    <input type="date" placeholder="Deposit Date" v-model="deposit_date">
                                </div>
                            </div>
                            <div class="field">
                                <label>Deposit Time</label>
                                <div class="ui fluid input">
                                    <input type="time" placeholder="Deposit Time" v-model="deposit_time">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </div>
        <div class="actions">
            <div class="ui deny button">
                Cancel
            </div>
            <div  @click="raiseDepositIssue" class="ui green left labeled icon button">
                <i class="checkmark icon"></i> Submit Issue
            </div>
        </div>
    </div>
</template>
<script>
    import {config} from '../config/config';

    export default {

        components: {
            config
        },

        props:{
            data:{
                type: String

            }
        },

        mounted(){
            var vm = this;

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;



        },


        data() {
            return {

                  user_id:'',
                  role:'',
                  token:'',
                'deposit_date':'',
                'deposit_time':'',
                'deposit_amount':'',
                'mpesa_code':'',
                'deposit_data':this.data

            }
        },
        watch:{
            data(newVal,oldVal){

                this.deposit_data = newVal;
            }
        },
        methods:{
            closeModal(){
                $(".ui.modal#deposit").modal("hide");
            },

            raiseDepositIssue: function () {
                var vm = this;
//            console.log("Profile | " + JSON.stringify(vm.profile));
                if (!vm.deposit_data || !vm.mpesa_code || !vm.deposit_amount || !vm.deposit_date || !vm.deposit_time) {
                    vm.$swal("Missing!", "All fields are required!", "warning");
                    return;
                }

                if (vm.dep_loader) {
                    return;
                }

                vm.dep_loader = 'loading';
                var data = {
                    msisdn: vm.deposit_data,
                    mpesa_code: vm.mpesa_code,
                    deposit_amount: vm.deposit_amount,
                    deposit_date: vm.deposit_date,
                    deposit_time: vm.deposit_time,
                    user_id: vm.user_id,
                    token: vm.token
                };

                $.ajax({
                    url: config.baseURL+'portal/depositIssue',
                    type: 'POST',
                    data: JSON.stringify(data),
                    success: function (response, textStatus, jQxhr) {


                        if (response.status_code == 200) {
                            vm.$swal("Success!", response.status_description, "success");
                            vm.$refs.vuetable_deposits.$dispatch('vuetable:reload');

                            vm.mpesa_code = '';
                            vm.deposit_amount = '';
                            vm.deposit_date = '';
                            vm.deposit_time = '';

                            $('.ui.modal#deposit').modal({
                                closable: true
                            }).modal('hide');

                            vm.$refs.withdraw.reload();
                        } else {
                            vm.$swal("Error!", response.status_description, "error");
                            $('#deposit').modal('hide');
                            $('.ui.modal#deposit').modal({
                                closable: true
                            }).modal('hide');
                        }
                    },
                    error: function (jqXhr, textStatus, error) {
                        vm.$swal("Error!", error, "error");
                        $('.ui.modal#deposit').modal({
                            closable: true
                        }).modal('hide');
                    }
                });
            },
        }
    }
</script>