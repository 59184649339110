<template>

    <div class="ui modal" id="addjackpot">
        <i class="close icon"></i>

        <div class="header">
            Jackpot List
        </div>
        <div class="content">
            <div class="ui form">
                <div class="equal width fields">
                    <div class="field">
                        <label>Jackpots</label>
                        <select  v-model="selected_pending_markett" class="margin-top-sm">
                            <option v-for="market in pending_market" v-bind:value="market.jp_id">{{market.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="ui deny button">
                Cancel
            </div>
            <div  @click="awardBonus" class="ui green left labeled icon button">
                <i class="checkmark icon"></i> Add Jackpot
            </div>
        </div>


    </div>


</template>

<script>


    import {config} from '../config/config';

    export default {

        components: {
            config
        },
        props:{
            data:{
                type: Object,


            }
        },


        data() {
            return {

                bonus_amount:'',
                selected_bonus_type:'',
                match_data:this.data,
                selectedProfile:'',
                pending_market: [],
                selected_pending_markets:'',
                selected_pending_markett:[],
                user_id:'',
                role:'',
                token:''

            }
        },


        watch:{
            data(newVal,oldVal){

                this.match_data = newVal;
                this.getWrongMarkets()
            }
        },
        computed: {

        },
        created() {

            // this.getWrongMarkets()

            $('.ui.modal#addjackpot').modal({
                closable: true
            }).modal('hide');


            var vm = this;

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;




        },

        mounted() {


        },

        methods: {

            awardBonus:function () {

                var vm=this;


                vm.$swal.fire({
                    title: 'Jackpot Matches ?',
                    text: "If you are sure, Add Match:!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes,Jackpot Match !'
                }).then((result) => {
                    if (result.value) {

                        var bonusData = {
                            id: vm.selected_pending_markett,
                            parent_match_id: vm.match_data.parent_match_id,
                            user_id: vm.user_id,
                            token: vm.token,
                        };

                        $.ajax({
                            url: config.baseURL+'portal/jackpot/add',
                            type: 'POST',
                            data: JSON.stringify(bonusData),
                            success: function (response, textStatus, jQxhr) {

                                if (response.status_code == 200) {
                                    vm.$swal("Success!", response.status_description, "success");

                                    $('.ui.modal#addjackpot').modal({
                                        closable: true
                                    }).modal('hide');

                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }
                })

            },

            getWrongMarkets: function () {
                var vm = this;


                var data = {
                    id:1,
                    token: vm.token,
                };
                $.ajax({
                    url: config.baseURL+'portal/jackpot/list',
                    type: 'POST',
                    data: JSON.stringify(data),
                    success: function (data, textStatus, jQxhr) {

                        $(".ui.dropdown").dropdown();


                        vm.pending_market = data;
                    },
                    error: function (jqXhr, textStatus, error) {
                        vm.edit_promo_loader = '';
                        vm.$swal("Error!", error, "error");
                    }
                });

            },






        },

    }
</script>