<template>
    <div class="l-container-content">
        <div class="row">
            <div class="col-6">
                <div class="text-title">
                    <h2 class="text-medium">Virtual Resulting</h2>
                </div>
            </div>
            <div class="col-6 text-right">

                <div class="l-input inline">
                    <i class=" search icon"></i>
                    <input v-on:keyup.enter="searchMatches" type="text" placeholder="Search..." v-model="search_match">
                </div>
                <div class="l-input inline">
                    <i class="icon-calendar"></i>
                    <input name="dates" placeholder="Choose date" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <vuetable ref="vuetable"
                          :api-url="vueurl"
                          :fields="columns"
                          :sort-order="sortOrder"
                          track-by="betradar_id"
                          :append-params="moreParams"
                          :per-page="10"
                          detail-row-component="my-detail-row"
                          @vuetable:cell-clicked="onCellClicked"
                          @vuetable:pagination-data="onPaginationData"
                ></vuetable>
                <div class="vuetable-pagination ui basic segment grid">
                    <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

                    <vuetable-pagination ref="pagination"
                                         @vuetable-pagination:change-page="onChangePage"
                    ></vuetable-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import {config} from '../config/config';


    export default {


        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo,config
        },
        name: 'virtual',

        data() {
            return {
                vueurl:config.baseURLWEB+'dash/api/all/competitions',
                columns: [

                    {
                        name: 'competition_id',
                        title: '#',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'

                    },
                    {
                        name: 'competition_name',
                        title: 'Competition',
                        sortField: 'competition_name',
                        titleClass: '',
                        dataClass: ''

                    },

                    {
                        name: 'name',
                        title: 'Custom_Category',
                        sortField: 'name',
                        titleClass: '',
                        dataClass: ''

                    },

                    {
                        name: 'competition_allias',
                        title: 'Allias',
                        sortField: 'competition_allias',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'category',
                        title: 'Category',
                        sortField: 'category',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: 'sport_name',
                        title: 'Sport',
                        sortField: 'sport_name',
                        titleClass: '',
                        dataClass: '',
                        callback: 'sport'
                    },
                    {
                        name: 'max_stake',
                        title: 'Maximum Stake',
                        sortField: 'max_stake',
                        titleClass: '',
                        dataClass: '',
                        callback: 'stakeCallback'

                    },
                    {
                        name: 'priority',
                        title: 'Priority',
                        sortField: 'priority',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'created',
                        title: 'Date Created',
                        sortField: 'created',
                        titleClass: '',
                        dataClass: '',
                        callback: 'createdAt'

                    },
                ],
                range:'',
                user_id:'',
                role:'',
                token:'',
                search_match:'',
                loadOnStart: true,
                sortOrder: [{
                    field: 'competition.priority',
                    direction: 'desc'
                }],
                moreParams: {
                    start: '',
                    filter: '',
                    end: '',
                    token: this.token
                }

            }
        },


        created: function(){
            this.checktimer();

        },



        mounted(){
            //var headerHeight = document.getElementById('header').offsetHeight;
            //document.getElementById('main-content').style.top = headerHeight + 'px';

            var vm = this;


            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=jsonData.token;


            if((vm.role  !=2 && vm.role !=3)){

                vm.$router.push({name: 'login'});
            }

            var start = moment();
            var end = moment();

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
            }

            $('input[name="dates"]').daterangepicker({
                startDate: start,
                endDate: end,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);




        },




        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.reload();
            },
            range(){

                var start = this.range[0];
                var end = this.range[1];
                start= moment(start).format('YYYY-MM-DD');
                end= moment(end).format('YYYY-MM-DD');
                this.moreParams.start = start;
                this.moreParams.end = end;
                this.moreParams.token = this.token;
                this.$refs.vuetable.reload();
            }
        },


        methods: {


            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");


                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },

            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                vm.moreParams.start = start;
                vm.moreParams.end = end;
                vm.moreParams.token = vm.token;

                this.$refs.vuetable.reload();

            },

            searchMatches:function(){
                this.moreParams.filter = this.search_match;
                this.$refs.vuetable.reload();
            },

            dataManager(sortOrder, pagination) {
                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {

                    local = _.orderBy(
                        local,
                        sortOrder[0].sortField,
                        sortOrder[0].direction
                    );
                }

                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );
                console.log('pagination:', pagination)
                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },


            showDetailRow: function (value) {
                var icon = this.$refs.vuetable.isVisibleDetailRow(value) ? 'minus' : 'plus';
                return [
                    '<a class="show-detail-row">',
                    '<i class=" circle ' + icon + ' icon"></i>',
                    '</a>'
                ].join('');
            },

            // onCellClicked (data) {
            //     this.$refs.vuetable.toggleDetailRow(data.parent_match_id)
            //
            // },

            onCellClicked (data, field, event) {
                console.log('cellClicked: ', data)
                this.$refs.vuetable.toggleDetailRow(data)
            },


            onPaginationData(paginationData) {
                this.$refs.paginationInfo.setPaginationData(paginationData)
                this.$refs.pagination.setPaginationData(paginationData)
            },


            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },

            onActionClicked(action, data) {

            }

        },


    }

</script>