<template>

    <div class="ui modal" id="award">
        <i class="close icon"></i>

        <div class="header">
            Customer BONUS Window
        </div>
        <div class="scrolling content">
            <div class="ui form">
                <div class="field">
                    <label>Amount to Award</label>
                    <div class="ui fluid input">
                        <input class="" placeholder="Enter Bonus Amount" v-model="bonus_amount">
                    </div>
                </div>
                <div class="equal width fields">
                    <div class="field">
                        <label>Bonus Type</label>
                        <select v-model="selected_bonus_type" class="ui dropdown form-control" data-width="auto">
                            <option value="">Select Bonus Type</option>
                            <option value="1">FREE_BET</option>
                            <option value="2">DEPOSIT_CASH_BACK</option>
                            <option value="3">VIRTUAL_FREE_BET</option>
                            <option value="4">BOMBA_CASH_BACK</option>
                            <option value="5">FACEBOOK_FREEBET</option>
                            <option value="6">REACTIVATION_BONUS</option>
                            <option value="7">CS_WEEK_BONUS</option>
                            <option value="8">Cash Back bonus</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="ui deny button">
                Cancel
            </div>
            <div  @click="awardBonus" class="ui green left labeled icon button">
                <i class="checkmark icon"></i> Award Bonus
            </div>
        </div>


    </div>


</template>

<script>


    import {config} from '../config/config';

    export default {

        components: {
            config
        },
        props:{
            data:{
                type: Object

            }
        },



        data() {
            return {

                bonus_amount:'',
                selected_bonus_type:'',
                award_data:this.data,
                selectedProfile:'',
                user_id:'',
                role:'',
                token:''

            }
        },


        watch:{
            data(newVal,oldVal){

                this.award_data = newVal;
            }
        },
        computed: {

        },
        mounted() {

            this.selectedProfile=this.award_data.profile_id,
            $('.ui.modal#awarad').modal({
                closable: true
            }).modal('hide');

            var vm = this;

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role
            var userr=jsonData.id
            var tokenn=jsonData.token

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;


        },

        methods: {

            awardBonus:function () {

                var vm=this;

                if (!vm.bonus_amount || !vm.selected_bonus_type || !vm.selectedProfile) {
                    vm.$swal("Missing!", "All fields are REQUIRED!", "warning");
                    return;
                }

                vm.$swal.fire({
                    title: 'Customer BONUS?',
                    text: "If you are sure, Award bonus:!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Bonus award!'
                }).then((result) => {
                    if (result.value) {

                        var bonusData = {
                            bonus_amount: vm.bonus_amount,
                            bonus_type: vm.selected_bonus_type,
                            profile_id: vm.selectedProfile,
                            token: vm.token,
                        };

                        $.ajax({
                            url: config.baseURL+'promos/award',
                            type: 'POST',
                            data: JSON.stringify(bonusData),
                            success: function (response, textStatus, jQxhr) {

                                if (response.status_code == 200) {
                                    vm.$swal("Success!", response.status_description, "success");

                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }
                })


            }


        },

    }
</script>