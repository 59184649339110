<template>
    <div>
        <div class="l-jackpot">
            <div class="l-jackpot-side">
                <div class="ui relaxed padded grid padding-top-md">
                    <div class="row">
                        <div class="column">
                            <button class="ui red fluid button">Create Ticket</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column">
                            <div class="l-jackpot-list">
                                <!--<a  style="color: red" href="#" class="selected">Open Tickets</a>-->
                                <!--<a  style="color: #0ea432" href="#" class="">Closed Tickets</a>-->
                                <router-link  class="selected"  style="color: red" :to="{name: 'ticket'}">Tickets</router-link>
                                <!--<router-link  class="selected" style="color: #0ea432" :to="{name: 'closed'}">Closed Tickets </router-link>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="l-jackpot-content">

                <div class="ui relaxed padded grid padding-top-md">
                    <div class="equal width row">

                        <div class="column">
                            <div class="ui equal width grid">
                                <div class="column border-right" style="color: red">Open Issues: <strong>{{open}}</strong></div>
                                <div class="column border-right" style="color: #0ea432">Closed Issues: <strong>{{close}}</strong> </div>

                                <div class="field">
                                    <label><strong>Status</strong></label>
                                    <select  @change="onstatuschange()" v-model="selectedStatus"  class="ui  dropdown">
                                        <option selected value="2" >All</option>
                                        <option value="11" >Open</option>
                                        <option value="1" >Closed</option>

                                    </select>

                                </div>



                                <!--<div class="column border-right"  > PERFORMANCE <strong> Very Good </strong></div>-->
                                <div class="column border-right">
                                    <i class="icon-calendar"></i>
                                    <input name="dates"  @focus="onFocus()"  placeholder="Choose date" />
                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column">

                            <div class="l-input inline">
                                <i class=" search icon"></i>
                                <input v-on:keyup.enter="searchMatches" type="text" placeholder="Search..." v-model="search_match">
                            </div>

                            <div class="row">
                                <loader v-show='loading' />
                                <div class="col">
                                    <vuetable ref="vuetable"
                                              :api-url="vueurl"
                                              :fields="columns"
                                              :sort-order="sortOrder"
                                              :append-params="moreParams"
                                              :per-page="15"
                                              @vuetable:pagination-data="onPaginationData"
                                              @vuetable:loading="onLoading"
                                              @vuetable:loaded="onLoaded"
                                    >

                                        <template  v-slot:status-slot="props">
                                            <div  v-if="props.rowData.reference_type === '1'"><strong>PENDING Bet</strong></div>
                                            <div  v-if="props.rowData.reference_type === '2'"><strong>Pending Payout</strong></div>
                                            <div  v-if="props.rowData.reference_type === '3'"><strong>Wrong   Resulting</strong></div>
                                            <div  v-if="props.rowData.reference_type === '4'"><strong>Balance  Deducted</strong></div>
                                            <div  v-if="props.rowData.reference_type === '5'"><strong>Failed Withdraw</strong></div>
                                            <div  v-if="props.rowData.reference_type === '6'"><strong>Profile Activate</strong></div>
                                            <div  v-if="props.rowData.reference_type === '7'"><strong>Profile Deactivate</strong></div>
                                            <div  v-if="props.rowData.reference_type === '8'"><strong>Name Change</strong></div>
                                                <div  v-if="props.rowData.reference_type === '9'"><strong>Winner Approval</strong></div>
                                                <div  v-if="props.rowData.reference_type === '10'"><strong>Bet  Review</strong></div>
                                                <div  v-if="props.rowData.reference_type === '11'"><strong>Score  Update</strong></div>

                                        </template>


                                        <template  v-slot:status="props">

                                            <div  style="color: red" v-if="props.rowData.status === '11'"><strong>PENDING</strong></div>
                                            <div  style="color: #0ea432" v-if="props.rowData.status === '1'"><strong>CLOSED</strong></div>


                                        </template>

                                        <template  v-slot:actions="props">
                                            <!--<button class="ui blue button" @click="onActionClicked('close', props.rowData)"><i class="fa fa-pencil"></i></button>-->
                                            <button class="ui green button" @click="onActionClicked('close', props.rowData)"> <i class="fa-check-square"></i></button>
                                        </template>



                                    </vuetable>
                                    <div class="vuetable-pagination ui basic segment grid">
                                        <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

                                        <vuetable-pagination ref="pagination"
                                                             @vuetable-pagination:change-page="onChangePage"
                                        ></vuetable-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import loader from "@/components/loader"
    import {config} from '../config/config';



    export default {


        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo,loader,config
        },
        name: 'ticket',

        data() {
            return {
                vueurl:config.baseURL+'portal/tickets/all',
                loading: true,
                selected_data: {},
                deposit_data:'',
                search_match:'',
                selectedStatus:2,
                deposit_mpesa:'',
                open:0,
                close:0,
                total:0,
                columns: [
                    {
                        name: 'msisdn',
                        title: 'Mobile',
                        sortField: 'msisdn',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'

                    },

                    {
                        name: 'reference_id',
                        title: 'Reference',
                        sortField: 'reference_id',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'status-slot',
                        title: 'Type',
                        titleClass: '',
                        dataClass: '',

                    },

                    {
                        name: 'amount',
                        title: 'Amount',
                        sortField: 'amount',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'status',
                        title: 'status',
                        titleClass: '',
                        dataClass: '',

                    },

                    {
                        name: 'raised_by',
                        title: 'Agent ',
                        titleClass: ''
                    },

                    {
                        name: 'solved_by',
                        title: 'Support ',
                        titleClass: ''
                    },
                    {
                        name: 'created',
                        title: 'created',
                        sortField: 'created',
                        callback: 'depositedAt'
                    },
                    {
                        name: 'solved_date',
                        title: 'solved',
                        sortField: 'created',
                        callback: 'depositedAt'
                    },
                    'actions'


                ],
                range:'',
                user_id:'',
                role:'',
                token:'',
                search_match:'',
                sortOrder: [{
                    field: 'created',
                    direction: 'desc'
                }],
                is_partial_resulting: false,
                moreParams: {
                    start: '',
                    filter: this.selectedStatus,
                    status: this.selectedStatus,
                    end: '',
                    token: this.token,
                }

            }
        },


        mounted() {

            var vm=this;

            var start = moment();
            var end = moment();

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                $('#reportrange span').html(start.format('D MMM, YYYY') + ' - ' + end.format('D MMM, YYYY'));
            }

            $('#reportrange').daterangepicker({
                startDate: start,
                endDate: end,
                autoApply:true,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);

            vm.getticketSummaryData();

        },


        created: function(){

            var vm=this;


            vm.checktimer();

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;

            if((vm.role  !=2 && vm.role !=3 && vm.role !=5  && vm.role !=4)){

                vm.$router.push({name: 'login'});
            }

            var start = moment();
            var end = moment();

            vm.moreParams.start=start.format('YYYY-MM-DD');
            vm.moreParams.end=end.format('YYYY-MM-DD');
            vm.moreParams.token=vm.token;
            vm.moreParams.status = vm.selectedStatus;

        },





        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.reload();
            },
            range(){

                var start = this.range[0];
                var end = this.range[1];
                this.start= moment(start).format('YYYY-MM-DD');
                this.end= moment(end).format('YYYY-MM-DD');
                console.log("am reaching here ")
                this.moreParams.start = this.start;
                this.moreParams.end = this.end;
                this.moreParams.token = this.token;
                this.moreParams.status = this.selectedStatus;
                this.getticketSummaryData();
                this.$refs.vuetable.reload();
            }
        },



        methods: {




            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");

                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },


            searchMatches:function(){
                this.moreParams.filter = this.search_match;
                this.moreParams.status = this.selectedStatus;
                this.$refs.vuetable.reload();
            },

            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                vm.moreParams.start = vm.start;
                vm.moreParams.end = vm.end;
                vm.moreParams.token = vm.token;
                vm.moreParams.status = vm.selectedStatus;
                vm.getticketSummaryData();


                 this.$refs.vuetable.reload();

                console.log("start date filter cheacker",vm.start)
                console.log("end date filter cheacker  ",vm.end)
            },

            onFocus: function() {

                var vm = this;

                var start = moment();
                var end = moment();

                function cb(start, end) {
                    vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                    $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
                }

                $('input[name="dates"]').daterangepicker({
                    startDate: start,
                    endDate: end,
                    ranges: {
                        'Today': [moment(), moment()],
                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    }
                }, cb);

                cb(start, end);

            },


            onstatuschange:function(){

                var vm = this;

                console.log("ticket sttaus",vm.selectedStatus)

                vm.moreParams.start = vm.start;
                vm.moreParams.end = vm.end;
                vm.moreParams.token = vm.token;
                vm.moreParams.status = vm.selectedStatus;
                vm.getticketSummaryData();
                vm.$refs.vuetable.reload();
            },




            getticketSummaryData: function () {

                var vm = this;
                $.get({
                    url: config.baseURL+'portal/tickets/count?start='+vm.start+'&end='+vm.end+'&token='+vm.token+'&status='+vm.selectedStatus+'&filter='+vm.search_match,
                    type: "GET",
                    success: function (response) {


                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.close=json[key].closed_ticket;
                                vm.open=json[key].open_ticket;
                                vm.total=json[key].total;


                            }
                        }


                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },



            createdAt: function (value) {
                if (!value) {
                    return '-';
                } else {
                    return moment(value).format('h:mm a,DD MMM YYYY');
                }

            },




            onActionClicked(action, data) {
                var vm =this;

                if (action == 'close') {
                    vm.$swal.fire({
                        title: 'Close  Ticket?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Close Ticket!'
                    }).then((result) => {

                        if (result.value) {
                            var Voiddata = {
                                id: data.ticket_id,
                                token: vm.token,
                            };

                            $.ajax({
                                url: config.baseURL+'portal/close/ticket',
                                type: 'POST',
                                data: JSON.stringify(Voiddata),
                                success: function (response, textStatus, jQxhr) {

                                    if (response.status_code == 200) {

                                        vm.$refs.vuetable.reload();

                                        vm.$swal("Success!", response.status_description, "success");


                                        $('#switch-modall').modal('hide');

                                    } else {

                                        vm.$refs.vuetable.reload();


                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }else {

                        }
                    })
                }
            },



            onPaginationData(paginationData) {
                this.$refs.paginationInfo.setPaginationData(paginationData)
                this.$refs.pagination.setPaginationData(paginationData)
            },


            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },


            onLoading() {
                this.loading = true
                console.log('loading... show your spinner here')
            },
            onLoaded() {
                this.loading = false
                console.log('loaded! .. hide your spinner here')
            },




        },


    }

</script>