<template>
    <div class="l-navigation-container" id="navigation">
        <div class="l-navigation">
            <ul class="nav">
                <li @click="hideSidebar" v-if="(role === '2' || role === '3')" :class="{'selected': this.$route.name === 'dash-ke' || this.$route.name === 'dash-gh'}">
                    <router-link :to="{name: 'dash-ke'}"><i class="icon-chart-bar"></i><span>Dashboard</span></router-link>
                </li>

                <li @click="hideSidebar" v-if="(role === '2' || role === '3')" :class="{'selected': this.$route.name === 'referral'}">
                    <router-link :to="{name: 'referral'}"><i class="icon-list"></i><span>Referrals</span></router-link>
                </li>

                <!--<li @click="hideSidebar" v-if="(role === '2' || role === '3')" :class="{'selected': this.$route.name === 'affiliate'}">-->
                    <!--<router-link :to="{name: 'affiliate'}"><i class="icon-chart-bar"></i><span>Affiliate</span></router-link>-->
                <!--</li>-->

                <li @click="hideSidebar" v-if="(role === '2' || role === '3')" :class="{'selected': this.$route.name === 'jackpot'}">
                    <router-link :to="{name: 'jackpot'}"><i class="icon-chart-line"></i><span>Jackpot</span></router-link>
                </li>
                <li @click="hideSidebar" v-if="(role === '2' || role === '3')" :class="{'selected': this.$route.name === 'bets-approvals' || this.$route.name == 'bets-rejected' || this.$route.name == 'bets-free'}">
                    <router-link :to="{name: 'bets-approvals'}"><i class="icon-ticket"></i><span>Bets</span></router-link>
                </li>

                <li @click="hideSidebar" v-if="(role === '2' || role === '3')" :class="{'selected': this.$route.name === 'sms'}">
                    <router-link :to="{name: 'sms'}"><i class="icon-chart-bar"></i><span>Sms</span></router-link>
                </li>

                <li @click="hideSidebar" v-if="(role === '2' || role === '3')"  :class="{'selected': this.$route.name === 'matches' || this.$route.name === 'matches.vue-virtuals' || this.$route.name === 'matches.vue-resulting' || this.$route.name === 'matches.vue-print' || this.$route.name === 'matches.vue-booklet' || this.$route.name === 'matches.vue-real'}">
                    <router-link :to="{name: 'matches.vue-resulting'}"><i class="icon-doc-text"></i><span>Matches</span></router-link>
                </li>
                <li @click="hideSidebar" v-if="(role === '2' || role === '3' || role === '5' || role === '4')"  :class="{'selected': this.$route.name == 'profiles'}">
                    <router-link :to="{name: 'profiles'}"><i class="icon-users"></i><span>Profiles</span></router-link>
                </li>

                <!--<li @click="hideSidebar" v-if="(user_id === '1' || user_id === '18')"  :class="{'selected': this.$route.name == 'profiles1'}">-->
                    <!--<router-link :to="{name: 'profiles1'}"><i class="icon-users"></i><span>ProfilesNew</span></router-link>-->
                <!--</li>-->


                <li @click="hideSidebar"  v-if="(role === '2' || role === '3' || role === '5' || role === '4')"  :class="{'selected': this.$route.name === 'ticket'}">
                    <router-link :to="{name: 'ticket'}"><i class="icon-ticket"></i><span>Tickets</span></router-link>
                </li>


                <li @click="hideSidebar" v-if="(role === '10')"  :class="{'selected': this.$route.name == 'report'}">
                    <router-link :to="{name: 'report'}"><i class="icon-users"></i><span>Report</span></router-link>
                </li>


                <li @click="hideSidebar" v-if="(role === '11')"  :class="{'selected': this.$route.name == 'virtualReport'}">
                    <router-link :to="{name: 'virtualReport'}"><i class="icon-users"></i><span>Virtual Report</span></router-link>
                </li>

              <li @click="hideSidebar" v-if="(role === `14`)"  :class="{'selected': this.$route.name == 'instantReport'}">
                <router-link :to="{name: 'instantReport'}"><i class="icon-users"></i><span>Instant Report</span></router-link>
              </li>

                <li @click="hideSidebar" v-if="(role === '2' || role === '3' || role === '5')"  :class="{'selected': this.$route.name == 'mpesa'}">
                    <router-link :to="{name: 'mpesa'}"><i class="icon-star"></i><span>mpesa-reconciliation</span></router-link>
                </li>

              <li @click="hideSidebar" v-if="(role === '2')"  :class="{'selected': this.$route.name == 'tax'}">
                <router-link :to="{name: 'tax'}"><i class="icon-star"></i><span>Tax-reconciliation</span></router-link>
              </li>
                <!--<li class="p-top p-bottom"></li>-->
                <li @click="hideSidebar" v-if="(role === '2' || role === '3')"  :class="{'selected': this.$route.name == 'transactions' ||  this.$route.name == 'transactions-deposit' || this.$route.name == 'transactions-withdraw'}">
                    <router-link :to="{name: 'transactions-logs'}"><i class="icon-star"></i><span>Transactions Logs</span></router-link>
                </li>

                <li @click="hideSidebar" v-if="(role === '2' || role === '3')"  :class="{'selected': this.$route.name == 'admin' || this.$route.name == 'users'}">
                    <router-link :to="{name: 'users'}"><i class="icon-star"></i><span>Admin</span></router-link>
                </li>
            </ul>
            <div class="l-navigation-options">
                <router-link :to="{name: 'login'}">Logout</router-link>
            </div>
        </div>
        <div class="l-navigation-side" @click="$('#navigation').removeClass('show')">
            <!--<SubNav class="mobile" />-->
        </div>
    </div>
</template>



<script>

import SubNav from "./sub-navigation";
import $ from "jquery";
    export default {
        components: {
            SubNav
        },
        name: 'Navigation',


        mounted(){
            var vm = this;

            vm.checktimer();


            var obj = window.localStorage.getItem('user_info');
            if (!obj) {
                this.$router.push({name: 'login'});
            }

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role
            var userr=jsonData.id;

            vm.role=rolee;
            vm.user_id=userr;







            console.log("role at navigation",vm.role);

            $(".l-navigation ul.nav li").each(function(){
                console.log("clicked nav")
                $(this).find('a').click(function(){
                    $('#navigation').removeClass('show');
                });
            });
        },

        created(){
            this.checktimer()
            var obj = window.localStorage.getItem('user_info');
            if (!obj) {
                this.$router.push({name: 'login'});
            }
        },

        methods: {
            hideSidebar(){
                $('#navigation').removeClass('show')
            },
            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");


                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },
        },
        data() {
            return {
                role: '',
                user_id: '',

            }
        }


    }




</script>