<template>
    <div class="ui tiny modal" id="modal-approvals">
        <div class="header">
            <h3>Approve content</h3>
        </div>
        <i class="icon close"></i>
        <div class="scrolling content">
            sdkjh
        </div>
        <div class="actions">
            <button class="button" @click="closeModal()">Cancel</button>
            <button class="primary button">Approve</button>
        </div>
    </div>
</template>
<script>
export default {
    methods:{
        closeModal(){
            $(".ui.modal#modal-approvals").modal("hide");
        }
    }
}
</script>