<template>
   <div class="l-page">
        <navigation/>
        <div class="l-content">
            <div class="l-header" id="header">
                <div class="l-header-container">
                    <div class="l-header-menu" @click="showNavFn">
                        <i class="fa fa-bars"></i>
                    </div>
                    <div class="l-header-title">
                        <h3 class="title">{{this.$route.meta.name}}</h3>
                    </div>
                    <ul class="l-header-options" v-show="this.$route.name =='dash-ke' ||this.$route.name =='dash-gh' ">
                        <li :class="{'active': this.$route.name === 'dash-ke'}"><router-link :to="{name: 'dash-ke'}">KE</router-link></li>
                        <li :class="{'active': this.$route.name === 'dash-gh'}"><router-link :to="{name: 'dash-gh'}">GH</router-link></li>
                    </ul>
                </div>
            </div>
            <router-view id="main-content"></router-view>
        </div>
    </div>
</template>
<script>
import jquery from "jquery";
import daterangepicker from "daterangepicker";
import "../../node_modules/daterangepicker/daterangepicker.css"
import navigation from "@/components/navigation";
import moment from "moment";
export default {
    components: {
        navigation
    },
    mounted(){
        //var headerHeight = document.getElementById('header').offsetHeight;
        //document.getElementById('main-content').style.top = headerHeight + 'px';


        var start = moment().subtract(29, 'days');
        var end = moment();

        function cb(start, end) {
            $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
        }

        $('input[name="dates"]').daterangepicker({
            startDate: start,
            endDate: end,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }, cb);

        cb(start, end);
    },
    methods: {
        showNavFn(){
            $("#navigation").addClass("show");
        },
        checkauth(){
            var obj = window.localStorage.getItem('user_info');
            if (!obj) {
                this.$router.push({name: 'login'});
            }
        }
    }
}
</script>