<template>

  <div class="ui modal" id="instant">
    <i class="close icon"></i>
    <div class="header">
      <div class="ui equal width grid">
        <div class="column">
          Customer's Bet ID: #{{selected_data.betData.bet_reference}}<br/>
          System ID: {{selected_data.betData.bet_id}}<br/>
          <h6>Stake {{selected_data.betData.bet_amount}}/-</h6>
          <span>ODDS: {{selected_data.betData.total_odd}}</span>
        </div>
        <div class="right aligned column">
          <strong v-show="selected_data.betData.status==1">PENDING</strong>
          <strong v-show="selected_data.betData.status==3" style="color:#DB2828;">LOST</strong>
          <strong v-show="selected_data.betData.status==7 || selected_data.betData.status==17 || selected_data.betData.status==19" style="color:#964B00;">SPAMMING</strong>
          <strong v-show="selected_data.betData.status==6 || selected_data.betData.status==2 || selected_data.betData.status==12" styel="color:#e1e1e1;">VOIDED</strong>
          <strong v-show="selected_data.betData.status==4 || selected_data.betData.status==24" styel="color:#2185D0;">CANCELLED</strong>
          <strong v-show="selected_data.betData.status==5" style="color:#21BA45;">WON <div class="detail">Ksh. {{selected_data.betData.possible_win}}</div></strong>
          <strong v-show="selected_data.betData.status==16" style="color:#90EE90;">CASHOUT <div class="detail">Ksh. {{selected_data.betData.possible_win}}</div></strong>
        </div>
      </div>
    </div>

    <div class="scrolling content bg-grey-light">
      <div class="ui segments bg-white" v-for="betslip in selected_data.betslipData" :key="betslip.bet_slip_id">
        <div class="ui segment">
          <div class="ui grid">
            <div class="seven wide right aligned column">
              <h3>{{betslip.home_team}}</h3>
            </div>
            <div class="two wide center aligned column">
              <h3><strong>{{betslip.result}}</strong></h3>
            </div>
            <div class="seven wide column">
              <h3>{{betslip.away_team}}</h3>
            </div>
          </div>
        </div>
        <div class="ui horizontal segments">
          <div class="ui segment">
            <h4>Start Time</h4>
            <p>{{selected_data.start_time}}</p>
          </div>
          <div class="ui segment">
            <h4>Competition</h4>
            <p>{{betslip.competition_id}}</p>
          </div>
          <div class="ui segment">
            <h4 >Type: <strong >Live</strong></h4>
            <p>Market: {{betslip.market_id}}</p>
          </div>
          <div class="ui segment">
            <h4>Pick:{{betslip.outcome_name}}</h4>
            <p>Odd Value: {{betslip.odd_value}}</p>
          </div>
          <div class="ui inverted segment" v-bind:class="[betslip.bet_status]">
            <!--<p>{{betslip.winning_outcome}}</p>-->
          </div>

        </div>


        <div slot="action" slot-scope="props">

          <p>
            <button
                class="ui small button"
                @click="onActionWithdraw('reverse', props.rowData)">
              <i class="blue  reload  icon "></i>
            </button>

          </p>

        </div>


      </div>


    </div>

  </div>


</template>

<script>

export default {
  components: {},
  props:{
    data:{
      type: Object

    }
  },


  data() {
    return {

      final: '',
      sport_id: 1,
      selected_data:this.data

    }
  },

  watch:{
    data(newVal,oldVal){

      this.selected_data = newVal;

    }
  },
  computed: {

  },
  mounted() {

    $('.ui.modal#instant').modal({
      closable: true
    }).modal('hide');

  },

  methods: {


  },

}
</script>