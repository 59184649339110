<template>


    <div class="ui tiny modal" id="jackpot">

        <i class="close icon"></i>
        <div class="header">
            Add Matchday
        </div>
        <div class="scrolling content">
            <div class="ui grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <label>Select Jackpot</label>
                            <div class="four wide computer sixteen wide mobile right aligned column">
                                <select  v-model="jackpot_type" class="margin-top-sm">
                                    <option v-for="jackpot in jackpot_type_data" v-bind:value="jackpot.jp_id">{{jackpot.jp_name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="field">
                            <label>Matchday Name</label>
                            <div class="ui fluid input">
                                <input type="text" class="" placeholder="Jackpot year-week  2021-01" v-model="name">
                            </div>
                        </div>
                        <div class="equal width fields">
                            <div class="field">
                                <label>Expiry Date</label>
                                <div class="ui fluid input">
                                    <input type="date" placeholder="Jackpot expiry date" v-model="date">
                                </div>
                            </div>
                            <div class="field">
                                <label>Expiry Time</label>
                                <div class="ui fluid input">
                                    <input type="time" placeholder="Jackpot expiry time" v-model="time">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="actions">
            <div class="ui deny button">
                Cancel
            </div>
            <div  @click="createJackpot" class="ui green left labeled icon button">
                <i class="checkmark icon"></i> Add Matchday
            </div>
        </div>
    </div>

</template>
<script>
    import {config} from '../config/config';

    export default {

        components: {
            config
        },

        props:{
            data:{
                type: Object
            }
        },

        data() {
            return {


                jackpot_type_data:[],
                jackpot_type:'',
                search_matches: '',
                user_id: '',
                name: '',
                start: '',
                token: '',
                end: '',
                date: '',
                time: '',
                resulting_loader: '',




            }
        },


        watch:{

        },




        computed: {


        },


        created: function () {

            var vm = this;
            var a = moment([2018, 9, 24]);
            var b = moment();
            var diff = b.diff(a, 'days') + 1;
            vm.currentDay = 'DAY ' + diff; // 1



            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=jsonData.token;;

            vm.getJackpots()
        },

        mounted(){



        },





        methods: {
            createdAt: function (value) {
                if (!value) {
                    return '-';
                } else {
                    return moment(value).format('HH:mm DD/MM/YY');
                }
            },


            getJackpots: function () {
                var vm = this;


                var data = {
                    id:1,
                    token: vm.token,
                };
                $.ajax({
                    url: config.baseURL+'portal/jackpot/types',
                    type: 'POST',
                    data: JSON.stringify(data),
                    success: function (data, textStatus, jQxhr) {

                        $(".ui.dropdown").dropdown();

                        console.log("resposes",data)
                        vm.jackpot_type_data = data;
                    },
                    error: function (jqXhr, textStatus, error) {
                        vm.edit_promo_loader = '';
                        vm.$swal("Error!", error, "error");
                    }
                });

            },



            createJackpot: function () {
                var vm = this;

                var data = {
                    date:vm.date,
                    time:vm.time,
                    type: vm.jackpot_type,
                    name: vm.name,
                    user_id: vm.user_id,
                    token: vm.token,

                };
                console.log("date from server",JSON.stringify(data))

                $.ajax({
                    url: config.baseURL+'portal/jackpot/create',
                    type: 'POST',
                    data: JSON.stringify(data),
                    success: function (response, textStatus, jQxhr) {
                        console.log("getsport Matche: " + JSON.stringify(response));


                        if (response.status_code == 200) {
                            vm.$swal("Success!", response.status_description, "success");

                            $('.ui.modal#jackpot').modal({
                                closable: true
                            }).modal('hide');
                        } else {
                            vm.$swal("Error!", response.status_description, "error");

                            $('.ui.modal#jackpot').modal({
                                closable: true
                            }).modal('hide');
                        }

                    },
                    error: function (jqXhr, textStatus, error) {

                        vm.$swal("Error!", error, "error");
                        $('.ui.modal#jackpot').modal({
                            closable: true
                        }).modal('hide');
                    }
                });
            },


            closeModal(){
                $(".ui.modal#jackpot").modal("hide");
            }
        },
        filters: {
            size: function (val) {
                return val.length;
            }
        },


    }
</script>