<template>
  <div class="l-main-content">
    <div class="row">
      <div class="col-6">
        <div class="text-title">
          <h2 class="text-medium">Instant Bets Dashboard</h2>
        </div>
      </div>

      <div class="col-6 text-right">

        <div class="col">
          <div v-on:keyup.enter="download" ><button class="ui button bg-primary" @click="download">Download Excel</button></div>
        </div>
        <div class="l-input inline">
          <i class="icon-calendar"></i>
          <input name="dates"  @focus="onFocus()"  placeholder="Choose date" />
        </div>

        <!--<json-excel :data="dataForExcel"></json-excel>-->
        <!--<button v-on:keyup.enter="download" class="ui button bg-primary" @click="download">Excel</button>-->
      </div>

    </div>

    <div class="row">
      <loader v-show='loading' />
      <vuetable ref="vuetable"
                :api-url="vueurl"
                :fields="columns"
                :sort-order="sortOrder"
                track-by="created"
                :append-params="moreParams"
                :per-page="31"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="onLoading"
                @vuetable:loaded="onLoaded"
      >



      </vuetable>
      <div class="vuetable-pagination ui basic segment grid">
        <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

        <vuetable-pagination ref="pagination"
                             @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
      </div>

    </div>
  </div>
</template>

<style>
td.vuetable-slot{
  overflow: visible !important;
}
</style>

<script>

import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import loader from "@/components/loader"
import markets from "../modals/markets";
import XLSX from 'xlsx';
import  moment from 'moment';
import {config} from '../config/config';


export default {
  components: {
    Vuetable, VuetablePagination, VuetablePaginationInfo,loader,markets,XLSX,config
  },
  name: 'virtualreport',

  data() {
    return {
      vueurl:config.baseURLWEB+'dash/api/instant/report',
      items: [
        { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
        { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
        { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
        { age: 38, first_name: 'Jami', last_name: 'Carney' }
      ],

      loading: true,
      selected_data: {},
      columns: [

        {
          name: 'created',
          title: 'Date',
          sortField: 'created',
          titleClass: '',
          dataClass: '',
          callback: 'createdAt'
        },

        {
          name: 'stake',
          title: 'Stake',
          sortField: 'stake',
          titleClass: '',
          dataClass: ''
        },

        {
          name: "payout",
          title: 'Payout',
          sortField: 'payout',
        },

        {
          name: 'pnl',
          title: 'GGR',
          sortField: 'PNL',
          titleClass: '',
          dataClass: '',

        },

        {
          name: "bets",
          title: 'Bet Count',
          sortField: 'bets',
        },

        {
          name: "pay_count",
          title: 'Pay Count',
        },

        {
          name: "average_bet",
          title: 'Average  bet',
        },

        {
          name: "average_pay",
          title: 'Average  PAY',
        },
      ],
      exceldata:[],
      range:'',
      user_id:'',
      start:'',
      end:'',
      role:'',
      token:'',
      search_match:'',
      loadOnStart: true,
      sortOrder: [{
        field: 'created',
        direction: 'asc'
      }],
      is_partial_resulting: false,

      moreParams: {
        start: '',
        filter: '',
        end: '',
        token: ''
      },

    }
  },

  created: function(){

    var vm = this;

    var obj = window.localStorage.getItem('user_info');

    var jsonData = JSON.parse(obj);
    var rolee=jsonData.role;
    var userr=jsonData.id;
    var tokenn=jsonData.token;

    vm.role=rolee;
    vm.user_id=userr;
    vm.token=tokenn;

    if((vm.role  !=14)){



      vm.$router.push({name: 'login'});
    }

    vm.moreParams.token=vm.token;


    var start = moment();
    var end = moment();

    var dateInput = $('input[name="dates"]');

    function cb(start, end) {
      vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
      dateInput.val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
    }

    dateInput.daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);



    cb(start, end);

    var start = moment();
    var end = moment();

    vm.moreParams.start=start.format('YYYY-MM-DD');
    vm.moreParams.end=end.format('YYYY-MM-DD');

    vm.checktimer();

  },

  mounted(){
    var vm = this;

    var start = moment();
    var end = moment();

    var dateInput = $('input[name="dates"]');

    function cb(start, end) {
      vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
      dateInput.val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
    }

    dateInput.daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);

    cb(start, end);
  },
  watch: {
    data(newVal, oldVal) {
      this.$refs.vuetable.reload();
    },
    range(){
      var start = this.range[0];
      var end = this.range[1];
      start= moment(start).format('YYYY-MM-DD');
      end= moment(end).format('YYYY-MM-DD');
      this.moreParams.start = start;
      this.moreParams.end = end;
      this.$refs.vuetable.reload();
    }
  },
  methods: {


    checktimer:function(){
      var vm=this;
      const itemStr = localStorage.getItem("timer")
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null
      }
      const item = itemStr

      var checktimer = new Date(item);

      const now = new Date()
      // compare the expiry time of the item with the current time
      if (now.getTime() > checktimer.getTime()) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem("timer");
        localStorage.removeItem("user_info");


        const Toast = vm.$swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 9000
        });
        Toast.fire({
          type: 'error',
          title: "Your session expired, Please login again "
        })

        vm.$router.push({name: 'login'});
        return null
      }

    },

    onFocus: function() {

      var vm = this;

      var start = moment();
      var end = moment();

      function cb(start, end) {
        vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
        $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
      }

      $('input[name="dates"]').daterangepicker({
        startDate: start,
        endDate: end,
        ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
      }, cb);

      cb(start, end);

      vm.start = start;
      vm.end = end;


      //
      //  var start = this.range[0];
      //  var end = this.range[1];
      //  start= moment(start).format('YYYY-MM-DD');
      //  end= moment(end).format('YYYY-MM-DD');
      //  this.moreParams.start = start;
      //  this.moreParams.end = end;
      // this.$refs.vuetable.reload();
    },

    createdAt: function (value) {
      if (!value) {
        return '-';
      } else {
        return moment(value).format('h:mm a,DD MMM YYYY');
      }
    },
    download : function() {
      var vm=this


      $.get({
        url: config.baseURLWEB+'dash/api/instant/download?page=1&per_page=10&start='+vm.start+'&filter=&end='+vm.end+'&token='+vm.token,
        type: "GET",
        success: function (response) {

          vm.exceldata = response.data;

          const data = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(vm.exceldata)))
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, data, 'data')
          XLSX.writeFile(wb,'report.xlsx');
        },
        error: function () {

        }
      });




    },
    dateFilter: function (start, end) {
      var vm = this;
      vm.start = start;
      vm.end = end;

      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.$refs.vuetable.reload();

    },
    searchMatches: function(){
      this.moreParams.filter = this.search_match;
      this.$refs.vuetable.reload();
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;
      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {

        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onPaginationData(paginationData) {
      this.$refs.paginationInfo.setPaginationData(paginationData)
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading() {
      this.loading = true
      console.log('loading... show your spinner here')
    },
    onLoaded() {
      this.loading = false
      console.log('loaded! .. hide your spinner here');
    },
  },
}

</script>