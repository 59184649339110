<template>
    <div class="l-container-content"  v-show="role==2 || role==3 ">
        <div class="row" >
            <div class="col-6">
                <div class="text-title">
                    <h2 class="text-medium">Matches</h2>
                </div>
            </div>
            <div class="col-6 text-right">
                <div class="l-input inline">
                    <i  class=" search icon"></i>
                    <input v-on:keyup.enter="searchMatches" type="text" placeholder="Search..." v-model="search_match">
                </div>
                <div class="l-input inline">
                    <i class="icon-calendar"></i>
                    <input name="dates" placeholder="Choose date" />
                </div>
            </div>
        </div>
        <div class="row" >
            <div class="col">
                <loader v-show='loading' />
                <!--api-url="http://35.189.221.84/dashboard-api/v1/dash/api/matches/all"-->
                <vuetable ref="vuetable"
                          :api-url="vueurl"
                          :fields="columns"
                          :sort-order="sortOrder"
                          track-by="match_id"
                          :append-params="moreParams"
                          :per-page="10"
                          @vuetable:pagination-data="onPaginationData"
                          @vuetable:loading="onLoading"
                          @vuetable:loaded="onLoaded"
                >
                    <div class="ui tiny buttons" slot="Actions" slot-scope="props">
                        <button class="ui green button">All Actions</button>
                        <div class="ui green left floating dropdown icon button">
                            <i class="icon caret down"></i>
                            <div class="menu">
                                <div class="item" @click="onActionClicked('match-priority', props.rowData)" > <i class="exchange icon"  ></i>Match priority</div>
                                <div class="item" @click="onActionJackpot('view-itemm', props.rowData)" > <i class="exchange icon"  ></i>Jackpot</div>
                                <div class="item" @click="onActionClicked('match-result', props.rowData)" > <i class="exchange icon"  ></i>Match Schedule</div>
                                <div class="item" @click="getAllSports(props.rowData)" > <i class="exchange icon"  ></i>Match Type</div>
                                <div class="item" @click="onActionClicked('match-resultt', props.rowData)" > <i class="exchange icon"  ></i>Match Result</div>
                                <div class="item" @click="onActionClicked('sms-priority', props.rowData)" > <i class="exchange icon"  ></i>Sms priority</div>
                                <div class="item" @click="onActionVoid('view-item', props.rowData)" > <i class="exchange icon"  ></i>Match Voiding</div>
                                <div class="item" @click="onActionBoost('view-itemm', props.rowData)" > <i class="exchange icon"  ></i>Add Freebet</div>
                                <div class="item" @click="onActionBoost('view-item', props.rowData)" > <i class="exchange icon"  ></i>Match Boost</div>
                                <div class="item" @click="onActionMarkets('view-itemm', props.rowData)" > <i class="exchange icon"  ></i>Wrong Resulting</div>
                                <div class="item" @click="onActionResulting('view-itemm', props.rowData)" > <i class="exchange icon"  ></i>NEW Resulting</div>
                                <div class="item" @click="onActionNewBoost('view-item', props.rowData)" > <i class="exchange icon"  ></i>Match new Boast</div>
                            </div>
                        </div>
                    </div>


                </vuetable>
                <div class="vuetable-pagination ui basic segment grid">
                    <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

                    <vuetable-pagination ref="pagination"
                                         @vuetable-pagination:change-page="onChangePage"
                    ></vuetable-pagination>
                </div>
            </div>
        </div>
        <modalApprove />
        <markets v-if="selected_data" :data="selected_data"/>
        <sports v-if="selected_sdata" :data="selected_sdata"/>
        <addjackpot v-if="selected_data" :data="selected_data"/>
    </div>
</template>

<style>
    td.vuetable-slot{
        overflow: visible !important;
    }
</style>

<script>

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import modalApprove from "../bets/modal-approve";
    import loader from "@/components/loader"
    import markets from "../modals/markets";
    import sports from "../modals/sport";
    import addjackpot from "../modals/addjackpot";
    import {config} from '../config/config';


    export default {


        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo,modalApprove,loader,markets,addjackpot,sports,config
        },
        name: 'approvals',

        data() {
            return {
                vueurl:config.baseURL+'odibets/all/matches',
                loading: true,
                selected_data: {},
                selected_sdata: {},
                columns: [
                    {
                        name: 'match_id',
                        title: 'GameID',
                        sortField: 'match_id',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'

                    },

                    {
                        name: 'home_team',
                        title: '<span class="orange glyphicon glyphicon-user"></span>  Home team',
                        sortField: 'home_team',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'away_team',
                        title: 'Away team',
                        sortField: 'away_team',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'start_time',
                        title: 'Start Time',
                        sortField: 'start_time',
                        titleClass: '',
                        dataClass: '',
                        callback: 'createdAt'

                    },
                    {
                        name: 'parent_match_id',
                        title: 'Parent Match Id',
                        sortField: 'parent_match_id',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: "priority",
                        title: 'Priority',
                        sortField: 'priority',
                    },
                    {
                        name: "sms_priority",
                        title: 'Sms Priority',
                        sortField: 'sms_priority',
                    },
                    'Actions'
                ],
                range:'',
                user_id:'',
                token:'',
                role:'',
                search_match:'',
                loadOnStart: true,
                sortOrder: [{
                    field: 'start_time',
                    direction: 'asc'
                }],
                is_partial_resulting: false,

                moreParams: {
                    start: '',
                    filter: '',
                    end: '',
                    token:this.token,
                },

            }
        },


        created: function(){
            this.checktimer();

        },


        mounted() {


            var vm = this;


            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;

            if((vm.role  !=2 && vm.role !=3)){

                vm.$router.push({name: 'login'});
            }


            var start = moment();
            var end = moment();

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
            }

            $('input[name="dates"]').daterangepicker({
                startDate: start,
                endDate: end,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);



        },




        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.reload();
            },
            range(){

                var start = this.range[0];
                var end = this.range[1];
                start= moment(start).format('YYYY-MM-DD');
                end= moment(end).format('YYYY-MM-DD');
                this.moreParams.start = start;
                this.moreParams.end = end;
                this.moreParams.token = token;
                this.$refs.vuetable.reload();
            }
        },


        methods: {


            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");


                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },


            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                vm.moreParams.start = start;
                vm.moreParams.end = end;
                vm.moreParams.token = vm.token;

                this.$refs.vuetable.reload();


            },

            searchMatches:function(){
                this.moreParams.filter = this.search_match;
                this.$refs.vuetable.reload();
            },

            dataManager(sortOrder, pagination) {
                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {

                    local = _.orderBy(
                        local,
                        sortOrder[0].sortField,
                        sortOrder[0].direction
                    );
                }

                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );

                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },


            onPaginationData(paginationData) {
                this.$refs.paginationInfo.setPaginationData(paginationData)
                this.$refs.pagination.setPaginationData(paginationData)
            },


            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },

            onActionClicked(action, data) {
                // $('#modal-approvals').modal({centered: false}).modal("show");



                var vm=this;

                if (action == 'match-priority') {
                    vm.$swal({
                        title: "Change Match Priority?",
                        text: "If you are sure, type in the new Match priority:",
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Update',
                        showLoaderOnConfirm: true,
                        preConfirm: (priority) => {

                            if (!priority) {
                                return;
                            }
                            var dataa = {
                                parent_match_id: data.parent_match_id,
                                priority: priority,
                                user_id: vm.user_id,
                                token:vm.token,
                            };

                            $.ajax({
                                url: config.baseURL+'dash/match/priority',
                                type: 'POST',
                                data: JSON.stringify(dataa),
                                success: function (response, textStatus, jQxhr) {

                                    if (response.status_code == 200) {
                                        vm.$swal("Success!", response.status_description, "success");

                                        $('#switch-modall').modal('hide');
                                    } else {
                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }
                    });
                }

                if (action == 'match-result') {
                    vm.$swal({
                        title: "Change Match Schedule?",
                        text: "If you are sure, type in the new Match Schedule:",
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Update',
                        showLoaderOnConfirm: true,
                        preConfirm: (priority) => {

                            if (!priority) {
                                return;
                            }
                            var dataa = {
                                parent_match_id: data.parent_match_id,
                                result: priority,
                                user_id: vm.user_id,
                                token:vm.token,
                            };
                            $.ajax({
                                url: config.baseURL+'match/schedule',
                                type: 'POST',
                                data: JSON.stringify(dataa),
                                success: function (response, textStatus, jQxhr) {

                                    if (response.status_code == 200) {
                                        vm.$swal("Success!", response.status_description, "success");

                                        $('#switch-modall').modal('hide');
                                    } else {
                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }
                    });
                }


                if (action == 'match-type') {
                    vm.$swal({
                        title: "Change Match Type?",
                        text: "If you are sure, type in the new Match Type:",
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Update',
                        showLoaderOnConfirm: true,
                        preConfirm: (priority) => {

                            if (!priority) {
                                return;
                            }
                            var dataa = {
                                parent_match_id: data.parent_match_id,
                                id: priority,
                                user_id: vm.user_id,
                                token:vm.token,
                            };

                            $.ajax({
                                url: config.baseURL+'match/type',
                                type: 'POST',
                                data: JSON.stringify(dataa),
                                success: function (response, textStatus, jQxhr) {

                                    if (response.status_code == 200) {
                                        vm.$swal("Success!", response.status_description, "success");

                                        $('#switch-modall').modal('hide');
                                    } else {
                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }
                    });
                }

                if (action == 'match-resultt') {
                    vm.$swal({
                        title: "Change Match Result?",
                        text: "If you are sure, type in the new Match Result:",
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Update',
                        showLoaderOnConfirm: true,
                        preConfirm: (priority) => {

                            if (!priority) {
                                return;
                            }
                            var dataa = {
                                parent_match_id: data.parent_match_id,
                                result: priority,
                                user_id: vm.user_id,
                                token:vm.token,
                            };

                            $.ajax({
                                url: config.baseURL+'match/result',
                                type: 'POST',
                                data: JSON.stringify(dataa),
                                success: function (response, textStatus, jQxhr) {

                                    if (response.status_code == 200) {
                                        vm.$swal("Success!", response.status_description, "success");

                                        $('#switch-modall').modal('hide');
                                    } else {
                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }
                    });
                }


                if (action == 'sms-priority') {
                    vm.$swal({
                        title: "Change Sms Priority?",
                        text: "If you are sure, type in the new Match priority:",
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Update',
                        showLoaderOnConfirm: true,
                        preConfirm: (priority) => {

                            if (!priority) {
                                return;
                            }

                            var dataa = {
                                parent_match_id: data.parent_match_id,
                                priority: priority,
                                user_id: vm.user_id,
                                token:vm.token,
                            };

                            $.ajax({
                                url: config.baseURL+'dash/match/sms/priority',
                                type: 'POST',
                                data: JSON.stringify(dataa),
                                success: function (response, textStatus, jQxhr) {

                                    if (response.status_code == 200) {
                                        vm.$swal("Success!", response.status_description, "success");

                                        $('#switch-modall').modal('hide');
                                    } else {
                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }
                    });
                }


            },

            onActionVoid(action, data) {

                var vm = this;

                if (action == 'view-item') {

                    vm.$swal({
                        title: "Match Voiding",
                        text: "If you are sure, type in the  Void Factor:",
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Void Match',
                        showLoaderOnConfirm: true,
                        preConfirm: (priority) => {

                            if (!priority) {
                                return;
                            }
                            var Voiddata = {
                                parent_match_id: data.parent_match_id,
                                void_factor: priority,
                                user_id: vm.user_id,
                                token:vm.token,
                            };

                            $.ajax({
                                url: config.baseURLWEB+'odibets/voiding',
                                type: 'POST',
                                data: JSON.stringify(Voiddata),
                                success: function (response, textStatus, jQxhr) {

                                    if (response.status_code == 200) {
                                        vm.$swal("Success!", response.status_description, "success");

                                        $('#switch-modall').modal('hide');
                                    } else {
                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }
                    });

                } else {


                }
            },

            onActionBoost(action, data) {

                var vm = this;

                if (action == 'view-item') {

                    vm.$swal.fire({
                        title: 'Boost Match?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Boost Match!'
                    }).then((result) => {

                        if (result.value) {
                            var Voiddata = {
                                parent_match_id: data.parent_match_id,
                                user_id: vm.user_id,
                                status: 1,
                                token:vm.token,
                            };

                            $.ajax({
                                url: config.baseURL+'dash/match/boost',
                                type: 'POST',
                                data: JSON.stringify(Voiddata),
                                success: function (response, textStatus, jQxhr) {

                                    if (response.status_code == 200) {
                                        vm.$swal("Success!", response.status_description, "success");

                                        $('#switch-modall').modal('hide');
                                    } else {
                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }else {

                        }
                    })

                 }else {

                    vm.$swal.fire({
                        title: 'Add Free bet Match?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Free bet Match!'
                    }).then((result) => {
                        if (result.value) {
                            var Voiddata = {
                                parent_match_id: data.parent_match_id,
                                user_id: vm.user_id,
                                token:vm.token,
                            };

                            $.ajax({
                                 //url: 'http://104.155.92.75/v1/dash/free/match',
                                url: config.baseURL+'portal/update/freebetmatch',
                                 type: 'POST',
                                data: JSON.stringify(Voiddata),
                                success: function (response, textStatus, jQxhr) {

                                    if (response.status_code == 200) {
                                        vm.$swal("Success!", response.status_description, "success");

                                        $('#switch-modall').modal('hide');

                                    } else {
                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }else {

                        }
                    })

                }
            },



            onActionNewBoost(action, data) {

                var vm = this;

                if (action == 'view-item') {

                    vm.$swal.fire({
                        title: 'Boost Match?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Boost Match!'
                    }).then((result) => {

                        if (result.value) {
                            var Voiddata = {
                                parent_match_id: data.parent_match_id,
                                user_id: vm.user_id,
                                status: 1,
                                token:vm.token,
                            };

                            $.ajax({
                                url: config.baseURLWEB+'dash/match/boast',
                                type: 'POST',
                                data: JSON.stringify(Voiddata),
                                success: function (response, textStatus, jQxhr) {

                                    if (response.status_code == 200) {
                                        vm.$swal("Success!", response.status_description, "success");

                                        $('#switch-modall').modal('hide');
                                    } else {
                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        } else {

                        }
                    })

                }
            },


            onActionMarkets(action, data) {

                var vm = this;

                var myObj = {
                    match: data,
                }

                vm.selected_data=data;

                $('#markets').modal("show")




            },


            getAllSports: function (Apidata) {
                var vm = this;

                $.ajax({
                    url: config.baseURLWEB+'dash/api/all/sports',
                    type: 'GET',
                    success: function (data, textStatus, jQxhr) {


                        var myObj = {
                            selected_data: data,
                            parent_match_id: Apidata.parent_match_id,
                            token:vm.token,
                        }


                        vm.selected_sdata = myObj;

                        $('#sports').modal("show")
                    },
                    error: function (jqXhr, textStatus, error) {
                        vm.edit_promo_loader = '';
                        vm.$swal("Error!", error, "error");
                    }
                });

            },


            onActionJackpot(action, data) {

                var vm = this;

                var myObj = {
                    match: data,
                }

                vm.selected_data=data;


                $('#addjackpot').modal("show")




            },

            onActionResulting(action, data) {


                var vm=this;


                vm.$swal.fire({
                    title: 'New  Resulting?',
                    text: "If you are sure, add to new resulting!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, New Resulting!'
                }).then((result) => {

                    if (result.value) {
                        var Voiddata = {
                            parent_match_id: data.parent_match_id,
                            user_id: vm.user_id,
                            token:vm.token,
                        };

                        $.ajax({
                            url: config.baseURL+'dash/match/new',
                            type: 'POST',
                            data: JSON.stringify(Voiddata),
                            success: function (response, textStatus, jQxhr) {

                                if (response.status_code == 200) {
                                    vm.$swal("Success!", response.status_description, "success");

                                    $('#switch-modall').modal('hide');
                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }else {

                    }
                })

            },

            onLoading() {
                this.loading = true

            },
            onLoaded() {
                this.loading = false

                $(".ui.dropdown").dropdown();
            },




        },


    }

</script>