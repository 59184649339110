import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "../node_modules/normalize.css/normalize.css"
import "@/assets/scss/app.scss"
import detailed from './components/resultingMarkets'
import resulting from './components/resultingModal'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import jquery from "jquery";

Vue.config.productionTip = false

Vue.component('my-detail-row',detailed )
Vue.use(VueSweetalert2);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
