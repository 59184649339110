<template>
    <div class="l-container-content">
        <div class="row">
            <div class="col-6">
                <div class="text-title">
                    <h2 class="text-medium">Winner Approvals</h2>
                </div>
            </div>
            <div class="col-6 text-right">
                <div class="l-input inline">
                    <i class=" search icon"></i>
                    <input v-on:keyup.enter="searchMatches" type="text" placeholder="Search..." v-model="search_match">
                </div>
                <div class="l-input inline">
                    <i class="icon-calendar"></i>
                    <input name="dates" placeholder="Choose date" />
                    <i class="icon-right-bold hidden"></i>
                </div>
            </div>
        </div>
        <div class="row">
            <!--<loader />-->
            <vuetable ref="vuetable"
                       :api-url="vueurl"
                      :fields="columns"
                      :sort-order="sortOrder"
                      track-by="match_id"
                      :append-params="moreParams"
                      :per-page="10"
                      @vuetable:pagination-data="onPaginationData"
            >
                <div slot="gender-slot" slot-scope="props">
                    <button  v-if="props.rowData.status === '0'" class="ui green button" @click="onActionClicked('match-priority', props.rowData)"><i class="fa fa-check-square"></i></button>

                </div>


            </vuetable>

            <div class="vuetable-pagination ui basic segment grid">
                <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

                <vuetable-pagination ref="pagination"
                                     @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
            </div>

        </div>
        <modalApprove />
    </div>
</template>

<script>

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo';
    import modalApprove from "./modal-approve";
    import loader from "@/components/loader";
    import {config} from '../config/config';

    export default {
        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo, modalApprove, loader,config
        },
        name: 'approvals',

        data() {
            return {
                vueurl:config.baseURL+'portal/dash/winners',
                columns: [
                    {
                        name: 'bet_id',
                        title: 'Bet id',
                        sortField: 'match_id',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'

                    },
                    {
                        name: 'msisdn',
                        title: 'Phone Number',
                        sortField: 'msisdn',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'first_name',
                        title: 'First Name',
                        titleClass: '',
                        dataClass: '',
                        callback: 'createdAt'

                    },
                    {
                        name: 'middle_name',
                        title: 'Last Name',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: 'stake',
                        title: 'Stake',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: 'amount',
                        title: 'Possible Win ',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: 'username',
                        title: 'Approved By',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: 'created',
                        title: 'Date Resulted',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: "gender-slot",
                        title: 'Approve',
                        titleClass: "center aligned",
                        dataClass: "center aligned",
                        width: "15%",
                    },
                    // 'priotity'
                ],
                range:'',
                user_id:'',
                role:'',
                token:'',
                parent_match_id:'',
                search_match:'',
                loadOnStart: true,
                sortOrder: [{
                    field: 'created',
                    direction: 'desc'
                }],
                moreParams: {
                    start: '',
                    filter: '',
                    end: '',
                    token: ''
                }

            }
        },

        created: function(){

            var vm = this;
            vm.checktimer();

            var obj = window.localStorage.getItem('user_info');
            if (!obj) {
                this.$router.push({name: 'login'});
            }

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;

            vm.moreParams.token = vm.token;

        },


        mounted(){
            //var headerHeight = document.getElementById('header').offsetHeight;
            //document.getElementById('main-content').style.top = headerHeight + 'px';

            var vm = this;

            var start = moment();
            var end = moment();

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
            }

            $('input[name="dates"]').daterangepicker({
                startDate: start,
                endDate: end,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);





        },
        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.reload();
            },
            range(){

                 var start = this.range[0];
                 var end = this.range[1];
                 start= moment(start).format('YYYY-MM-DD');
                 end= moment(end).format('YYYY-MM-DD');
                this.moreParams.start = start;
                this.moreParams.end = end;
                this.moreParams.token = this.token;
                this.$refs.vuetable.reload();
            }
        },
        methods: {

            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");


                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },

            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                vm.moreParams.start = start;
                vm.moreParams.end = end;

                this.$refs.vuetable.reload();


            },

            searchMatches:function(){
                this.moreParams.filter = this.search_match;
                this.$refs.vuetable.reload();
            },

            dataManager(sortOrder, pagination) {
                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {
                    local = _.orderBy(
                        local,
                        sortOrder[0].sortField,
                        sortOrder[0].direction
                    );
                }

                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );
                console.log('pagination:', pagination)
                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },


            onPaginationData(paginationData) {
                this.$refs.paginationInfo.setPaginationData(paginationData)
                this.$refs.pagination.setPaginationData(paginationData)
            },


            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },



            onActionClicked(action, data) {
                var vm =this;




                    vm.$swal.fire({
                        title: 'APPROVE WINNER?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Approve Winner!'
                    }).then((result) => {

                        if (result.value) {
                            var Voiddata = {
                                bet_id: data.bet_id,
                                user_id: vm.user_id,
                                status: 1,
                                token: vm.token
                            };
                            $.ajax({
                                url: config.baseURL+'portal/winnerApproval',
                                type: 'POST',
                                data: JSON.stringify(Voiddata),
                                success: function (response, textStatus, jQxhr) {
                                    if (response.status_code == 200) {
                                        vm.$swal("Success!", response.status_description, "success");

                                        $('#switch-modall').modal('hide');
                                    } else {
                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        }else {

                        }
                    })




            },

            showModalApprove(){
                $('#modal-approvals').modal({centered: false}).modal("show");
            }

        },


    }

</script>

