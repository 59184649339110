<template>
    <div class="container">

        <div  class="row bg-white bg-shadow margin-bottom-md padding-top-md">
            <div class="col">
                <div id="reportrange" class="left aligned column" style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ddd; width: 100%; border-radius: 4px;">
                    <i class="fa fa-calendar" style="margin-right: .75em;"></i>
                    <span></span> <i class="fa fa-caret-down" style="float: right"></i>
                </div>
                <!--<h6><strong>Previous Dates</strong> {{start_relative}} &#45;&#45; {{end_relative}}</h6>-->
            </div>

            <div >
                <div class="rsum">
                    <div class="sum green">
                        <loader v-show='loading1' />
                        <div class="h">
                            <div class="l">App Downloads</div>
                        </div>

                        <div class="t">{{app_profiles}}</div>
                        <!--<div class="s">+ ({{deposit}}/{{deposit}}*100)</div>-->
                    </div>
                    <div class="sum red">
                        <div class="h">
                            <div class="l">Stake</div>
                            <!--<div class="r">- 2.01%</div>-->
                        </div>
                        <div class="t">0</div>
                        <!--<div class="s">+ 0.18%</div>-->
                    </div>
                </div>
            </div>


        </div>

        <div class="row">
            <loader v-show='loading' />
            <div class="col">
                <vuetable ref="vuetable"
                          :api-url="vueurl"
                          :fields="columns"
                          :sort-order="sortOrder"
                          :append-params="moreParams"
                          :per-page="15"
                          @vuetable:pagination-data="onPaginationData"
                          @vuetable:loading="onLoading"
                          @vuetable:loaded="onLoaded"
                >


                </vuetable>
                <div class="vuetable-pagination ui basic segment grid">
                    <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

                    <vuetable-pagination ref="pagination"
                                         @vuetable-pagination:change-page="onChangePage"
                    ></vuetable-pagination>
                </div>
            </div>
        </div>

    </div>






</template>

<script>

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import loader from "@/components/loader"
    import {config} from '../config/config';


    function formatNumber(num) {

        if(num){
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        }else{
            return num;
        }

    }



    export default {


        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo,loader,config
        },
        name: 'mpesa',

        data() {
            return {
                vueurl:config.baseURL+'portal/api/referral',
                loading: true,
                selected_data: {},
                deposit_data:'',
                deposit_mpesa:'',
                columns: [
                    {
                        name: 'firstname',
                        title: 'Name',
                        sortField: 'u.firstname',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'

                    },

                    {
                        name: 'count',
                        title: 'App Profiles',
                        sortField: 'count',
                        titleClass: '',
                        dataClass: ''

                    }


                ],
                range:'',
                user_id:'',
                role:'',
                token:'',
                loading1: true,
                app_profiles:'',
                search_match:'',
                sortOrder: [{
                    field: 'count',
                    direction: 'desc'
                }],
                is_partial_resulting: false,
                moreParams: {
                    start: '',
                    filter: '',
                    end: '',
                    token: this.token,
                }

            }
        },


        mounted() {

            var vm=this;

            var start = moment();
            var end = moment();

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                $('#reportrange span').html(start.format('D MMM, YYYY') + ' - ' + end.format('D MMM, YYYY'));
            }

            $('#reportrange').daterangepicker({
                startDate: start,
                endDate: end,
                autoApply:true,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);

        },


        created: function(){

            var vm=this;


            vm.checktimer();

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;

            if((vm.role  !=2 && vm.role !=3 && vm.role !=5)){

                vm.$router.push({name: 'login'});
            }

            var start = moment();
            var end = moment();

            vm.moreParams.start=start.format('YYYY-MM-DD');
            vm.moreParams.end=end.format('YYYY-MM-DD');
            vm.moreParams.token=vm.token;

        },





        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.reload();
            },
            range(){

                var start = this.range[0];
                var end = this.range[1];
                this.start= moment(start).format('YYYY-MM-DD');
                this.end= moment(end).format('YYYY-MM-DD');
                console.log("am reaching here ")
                this.moreParams.start = this.start;
                this.moreParams.end = this.end;
                this.moreParams.token = this.token;
                this.getappsData(start,end);
                this.$refs.vuetable.reload();
            }
        },



        methods: {




            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");

                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },

            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                vm.moreParams.start = vm.start;
                vm.moreParams.end = vm.end;
                vm.moreParams.token = vm.token;


                vm.getappsData(start,end);


                this.$refs.vuetable.reload();

                console.log("start date filter cheacker",vm.start)
                console.log("end date filter cheacker  ",vm.end)
            },


            getappsData: function (start,end) {


                var vm = this;


                $.get({
                    url: config.baseURL+'dash/app/downloads?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.app_profiles=formatNumber(json[key].profiles);

                            }
                        }


                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },


            onFocus: function() {

                var vm = this;

                var start = moment();
                var end = moment();

                function cb(start, end) {
                    vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                    $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
                }

                $('input[name="dates"]').daterangepicker({
                    startDate: start,
                    endDate: end,
                    ranges: {
                        'Today': [moment(), moment()],
                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    }
                }, cb);

                cb(start, end);

            },


            searchMatches:function(){
                this.moreParams.filter = this.search_match;
                this.$refs.vuetable.reload();
            },

            depositedAt: function (value) {
                if (!value) {
                    return '-';
                } else {
                    return moment(value).format('DD MMMM YYYY, h:mm a');
                }
            },

            createdAt: function (value) {
                if (!value) {
                    return '-';
                } else {
                    return moment(value).format('h:mm a,DD MMM YYYY');
                }

            },

            dataManager(sortOrder, pagination) {
                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {
                    console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                    local = _.orderBy(
                        local,
                        sortOrder[0].sortField,
                        sortOrder[0].direction
                    );
                }

                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );
                console.log('pagination:', pagination)
                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },


            onPaginationData(paginationData) {
                this.$refs.paginationInfo.setPaginationData(paginationData)
                this.$refs.pagination.setPaginationData(paginationData)
            },


            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },


            onLoading() {
                this.loading = true
                console.log('loading... show your spinner here')
            },
            onLoaded() {
                this.loading = false
                console.log('loaded! .. hide your spinner here')
            },




        },


    }

</script>




