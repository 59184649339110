<template>
    <div class="ui tiny modal" id="reconcile">

        <i class="close icon"></i>
        <div class="header">
            Edit DEPOSIT Issue
        </div>
        <div class="scrolling content">
            <div class="ui grid">
                <div class="column">
                    <div class="ui form">
                        <div class="equal width fields">
                            <div class="field">
                                <label>MPESA Code</label>
                                <div class="ui labeled input">
                                    <input type="text" style="text-transform: uppercase;" placeholder="Enter Correct MPESA Code"  v-model="mpesa_code" >
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label>Status Description</label>
                            <div class="ui fluid input">
                                <input type="text" class="" placeholder="Provide status description" v-model="description">
                            </div>
                        </div>

                        <div class="field">
                            <label>Deposit Amount</label>
                            <div class="ui fluid input">
                                <input type="text" class="" placeholder="Provide Deposited Amount" v-model="mpesa_amt">
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <div class="actions">
            <div class="ui deny button">
                Cancel
            </div>
            <div  @click="raiseDepositIssue" class="ui green left labeled icon button">
                <i class="checkmark icon"></i> Submit Issue
            </div>
        </div>
    </div>
</template>
<script>
    import {config} from '../config/config';

    export default {

        components: {
            config
        },

        props:{
            data:{
                type: Object

            }
        },

        data() {
            return {

                mpesa_amt:'',
                id:'',
                deposit_time:'',
                deposit_amount:'',
                mpesa_code:'',
                description:'',
                status:'',
                user_id:'',
                role:'',
                token:'',
                deposit_data:this.data

            }
        },

        mounted(){
            var vm = this;

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;



        },
        created:function(){

            var vm=this;
            vm.id=vm.deposit_data.id;
            vm.mpesa_amt=vm.deposit_data.mpesa_amt;
            vm.description=vm.deposit_data.description;
            vm.mpesa_code=vm.deposit_data.mpesa_code;


        },
        watch:{
            data(newVal,oldVal){
                console.log("DAT! "+JSON.stringify(oldVal)+" new "+JSON.stringify(newVal));
                this.deposit_data = newVal;
                var vm=this;
                vm.mpesa_amt=newVal.mpesa_amt;
                vm.description=newVal.description;
                vm.mpesa_code=newVal.mpesa_code;
                console.log("final chnaged! "+JSON.stringify(this.deposit_data));
            }
        },
        methods:{
            closeModal(){
                $(".ui.modal#reconcile").modal("hide");
            },

            raiseDepositIssue: function () {
                var vm = this;

                if (!vm.mpesa_code || !vm.description || !vm.mpesa_amt) {
                    vm.$swal("Missing!", "All fields are required!", "warning");
                    return;
                }


                var jsondata = {
                    id: vm.id,
                    mpesa_code: vm.mpesa_code,
                    deposit_amount: vm.mpesa_amt,
                    status_description: vm.description,
                    user_id: vm.user_id,
                    token: vm.token
                };

                 console.log("Payload | " + JSON.stringify(jsondata));

                $.ajax({
                    url: config.baseURL+'portal/editDepositIssue',
                    type: 'POST',
                    data: JSON.stringify(jsondata),
                    success: function (response, textStatus, jQxhr) {
                        console.log("Raise DEPOSIT Issue: " + JSON.stringify(response));

                        if (response.status_code == 200) {
                            vm.$swal("Success!", response.status_description, "success");
                            vm.mpesa_code = '';
                            vm.deposit_amount = '';
                            vm.mpesa_amt = '';
                            vm.description = '';
                            vm.deposit_date = '';
                            vm.deposit_time = '';

                            $('.ui.modal#reconcile').modal({
                                closable: true,
                            }).modal('hide');
                            vm.$refs.vuetable.reload();

                        } else {
                            vm.$swal("Error!", response.status_description, "error");
                            $('.ui.modal#reconcile').modal({
                                closable: true,
                            }).modal('hide');
                            vm.mpesa_code = '';
                            vm.deposit_amount = '';
                            vm.mpesa_amt = '';
                            vm.description = '';
                            vm.deposit_date = '';
                            vm.deposit_time = '';
                        }
                    },
                    error: function (jqXhr, textStatus, error) {
                        vm.$swal("Error!", error, "error");
                        $('.ui.modal#reconcile').modal({
                            closable: true,
                        }).modal('hide');
                        vm.mpesa_code = '';
                        vm.deposit_amount = '';
                        vm.mpesa_amt = '';
                        vm.description = '';
                        vm.deposit_date = '';
                        vm.deposit_time = '';
                    }
                });
            },
        }
    }
</script>