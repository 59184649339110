<template>

    <div class="ui modal" id="markets">
        <i class="close icon"></i>

        <div class="header">
            Wrongly Resulted Markets Window
        </div>
        <div class="content">
            <div class="ui form">
                <div class="equal width fields">
                    <div class="field">
                        <label>Markets</label>
                        <select  v-model="selected_pending_markett" class="ui multiple dropdown" multiple="multiple">
                            <option value="">All Markets</option>
                            <option v-for="market in pending_market" v-bind:value="market.sub_type_id">{{market.name}}</option>
                        </select>

                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="ui deny button">
                Cancel
            </div>
            <div  @click="WrongMarkets" class="ui green left labeled icon button">
                <i class="checkmark icon"></i> Reset  Match
            </div>
        </div>


    </div>


</template>

<script>

    import {config} from '../config/config';


    export default {
        components: {config},
        props:{
            data:{
                type: Object,


            }
        },


        data() {
            return {
                user_id:'',
                role:'',
                token:'',
                bonus_amount:'',
                selected_bonus_type:'',
                match_data:this.data,
                selectedProfile:'',
                pending_market: [],
                selected_pending_markets:'',
                selected_pending_markett:[],

            }
        },


        watch:{
            data(newVal,oldVal){

                console.log("DAT! "+JSON.stringify(oldVal)+" new "+JSON.stringify(newVal));
                this.match_data = newVal;
                this.getWrongMarkets()
            }
        },
        computed: {

        },
        created() {

            // this.getWrongMarkets()
            console.log("modal is being loaded everytime",this.match_data);
                $('.ui.modal#markets').modal({
                    closable: true
                }).modal('hide');



        },

        mounted() {
            //var headerHeight = document.getElementById('header').offsetHeight;
            //document.getElementById('main-content').style.top = headerHeight + 'px';

            var vm = this;


            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.role=rolee;
            vm.token=tokenn;



        },


        methods: {

            WrongMarkets:function () {



                var vm=this;

                if (!vm.selected_pending_markett) {
                    vm.$swal("Missing!", "All fields are REQUIRED!", "warning");
                    return;
                }

                vm.$swal.fire({
                    title: 'Match Reset?',
                    text: "If you are sure, Reset match resetting:!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor:  '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Reset match!'
                }).then((result) => {
                    if (result.value) {

                        var bonusData = {
                            parent_match_id: vm.match_data.parent_match_id,
                            markets: vm.selected_pending_markett.join(),
                            user_id: vm.user_id,
                            token: vm.token,
                        };
                        console.log("Payload | " + JSON.stringify(bonusData));
                        $.ajax({
                            url: config.baseURLWEB +'odibets/match/reset',
                            type: 'POST',
                            data: JSON.stringify(bonusData),
                            success: function (response, textStatus, jQxhr) {
                                console.log("Swap Matche: " + JSON.stringify(response));
                                if (response.status_code == 200) {
                                    $('#markets').modal('hide');
                                    vm.$swal("Success!", response.status_description, "success");

                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }
                })


            },

            getWrongMarkets: function () {
                var vm = this;
                console.log("get wrong markets",vm.match_data)

                var data = {
                     parent_match_id: vm.match_data.parent_match_id,
                     token: vm.token,
                 };
                $.ajax({
                    url: config.baseURL + 'dash/wrong/markets',
                    type: 'POST',
                    data: JSON.stringify(data),
                    success: function (data, textStatus, jQxhr) {

                        $(".ui.dropdown").dropdown();

                        console.log("resposes",data)
                        vm.pending_market = data;
                    },
                    error: function (jqXhr, textStatus, error) {
                        vm.edit_promo_loader = '';
                        vm.$swal("Error!", error, "error");
                    }
                });

            },





        },

    }
</script>