<template>
    <div class="l-main-content">
        <div class="l-jackpot">
            <div class="l-jackpot-side">
                <div class="ui relaxed padded grid padding-top-md">
                    <div class="row">
                        <div class="column">
                            <button class="ui red fluid button" @click="createJackpot">Create Jackpot</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column">
                            <div class="l-jackpot-list">

                                <router-link  class="selected" :to="{name: 'jackpot'}">Jackpot</router-link>
                                <router-link  class="selected" :to="{name: 'type'}">SubJackpot </router-link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="l-jackpot-content">
                <div class="ui relaxed padded grid padding-top-md">
                    <div class="equal width row">
                        <div class="column">
                            <h3><strong>Jackpot's Weeks</strong></h3>
                        </div>
                        <div class="right aligned column">
                            <!--<button class="ui small button" @click="editJackpot">Edit</button>-->
                            <button class="ui primary small button" @click="activateAccout">Add Matchday</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column">
                            <loader v-show='loading' />
                            <vuetable ref="vuetable"
                                    :api-url="vueurl"
                                    :fields="columns"
                                    :sort-order="sortOrder"
                                    track-by="created"
                                    :append-params="moreParams"
                                    :per-page="10"
                                    @vuetable:pagination-data="onPaginationData"
                                    @vuetable:loading="onLoading"
                                    @vuetable:loaded="onLoaded"
                            >

                                <div slot="Matches" scope="props">
                                    <div class="custom-actions">
                                        <div>
                                            <div style="max-width:200px;">
                                                <div @click="itemAction(props.rowData)" style="cursor:pointer;color:blue;"><strong>View Matches</strong></div>
                                                <span style="color:black;">{{props.rowData.total}} Matches(s)</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <div slot="status-slot" slot-scope="props">

                                    <div  v-if="props.rowData.jp_status === '0'"><strong>Inactive</strong></div>
                                    <div v-if="props.rowData.jp_status === '1'"><strong style="color:#21BA45;">Active</strong></div>
                                    <div v-if="props.rowData.jp_status === '2'"><strong style="color:#21BA45;">Pending</strong></div>
                                    <div v-if="props.rowData.jp_status === '3'"><strong style="color:#21BA45;">Ended</strong></div>

                                </div>


                                <div slot="action" slot-scope="props">

                                    <b v-if="((role == 2 || role==3) && (props.rowData.jp_status ==='0'))">
                                        <button class="ui green button"
                                                @click="onActionClicked('jackpot-approve', props.rowData)"
                                        >
                                            <i class="exchange icon"></i>
                                        </button>
                                    </b>

                                </div>

                                <div slot="action" slot-scope="props">

                                    <b v-if="((role == 2 || role==3) && (props.rowData.jp_status ==='1' ))">
                                        <button class="ui green button"
                                                @click="onActionClicked('jackpot-disable', props.rowData)"
                                        >
                                            <i class="exchange icon"></i>
                                        </button>
                                    </b>

                                </div>

                            </vuetable>
                            <div class="vuetable-pagination ui basic segment grid">
                                <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

                                <vuetable-pagination ref="pagination"
                                                    @vuetable-pagination:change-page="onChangePage"
                                ></vuetable-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <jackpot v-if="selected_data" :data="selected_data"/>
        <jackpotmatches v-if="selected_dataa" :data="selected_dataa"/>
        <CreateJackpot />
        <EditJackpot />
    </div>
</template>

<style>
    td.vuetable-slot{
        overflow: visible !important;
    }
</style>

<script>

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import loader from "@/components/loader"
    import markets from "../modals/markets";
    import XLSX from 'xlsx';
    import  moment from 'moment';
    import jackpot from "../components/jackpotModal";
    import jackpotmatches from "../modals/matches";
    import CreateJackpot from '../components/create-jackpot.vue';
    import EditJackpot from '../components/edit-jackpot.vue';
    import {config} from '../config/config';

    function formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }


    export default {
        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo,loader,markets,XLSX,jackpot,jackpotmatches,CreateJackpot,EditJackpot,config
        },
        name: 'report',

        data() {
            return {
                vueurl:config.baseURL+'portal/jackpot/all',
                loading: true,
                user_id:'',
                role:'',
                token:'',
                selected_data: {},
                selected_dataa: {},
                columns: [

                    {
                        name: 'jp_name',
                        title: 'Type',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'

                    },

                    {
                        name: 'name',
                        title: 'Name',
                        titleClass: '',
                        dataClass: '',
                    },
                    {
                        name: 'jp_prize',
                        title: 'Prize',
                        titleClass: '',
                        dataClass: '',
                    },

                    {
                        name: 'jp_expiry',
                        title: 'Expiry Date',
                        titleClass: '',
                        dataClass: '',
                    },

                    {
                        name: 'status-slot',
                        title: 'Status',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned',
                    },
                    'action',
                    'Matches'




                ],
                exceldata:[],
                range:'',
                deposit:'',
                stake:'',
                signups:'',
                search_logs:'',
                bets:'',
                search_match:'',
                loadOnStart: true,

                sortOrder: [{
                    field: 'created',
                    direction: 'asc'
                }],



                is_partial_resulting: false,

                moreParams: {
                    start: '',
                    filter: '',
                    end: '',
                    token: this.token
                },

            }
        },

        created: function(){

            var vm = this;

            vm.checktimer();
            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;
            vm.moreParams.token = vm.token;


            if((vm.role  !=2 && vm.role !=3)){

                vm.$router.push({name: 'login'});
            }


        },


        mounted() {
            var vm = this;


            var start = moment();
            var end = moment();

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                $('#reportrange span').html(start.format('D MMM, YYYY') + ' - ' + end.format('D MMM, YYYY'));
            }

            $('#reportrange').daterangepicker({
                startDate: start,
                endDate: end,
                autoApply:true,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);

        },
        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.reload();
            },
            range(){

                var start = this.range[0];
                var end = this.range[1];
                start= moment(start).format('YYYY-MM-DD');
                end= moment(end).format('YYYY-MM-DD');
                 this.moreParams.token = this.token;
                // this.moreParams.end = end;
                console.log('date range changed to ',start)

                vm.getsummaryData(start,end,this.search_logs);

            },
        },
        methods: {

            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                vm.moreParams.start = start;
                vm.moreParams.end = end;
                vm.moreParams.token = vm.token;

                vm.checktimer();
                vm.getsummaryData(vm.start,vm.end);

                this.$refs.vuetable.reload();


            },


            onActionClicked: function (action,dataa) {
                var vm = this;


                if(action == 'jackpot-approve') {

                    vm.$swal.fire({
                        title: 'Jackpot Matches Appoval?',
                        text: "If you are sure, proceed to approval!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Approve Jackpot!'
                    }).then((result) => {

                        if (result.value) {
                            var Voiddata = {
                                status: 1,
                                user_id: vm.user_id,
                                jp_week_id: dataa.jp_week_id,
                                token: vm.token
                            };

                            $.ajax({
                                url: config.baseURL+'portal/jackpot/approve',
                                type: 'POST',
                                data: JSON.stringify(Voiddata),
                                success: function (response, textStatus, jQxhr) {

                                    if (response.status_code == 200) {
                                        window.location.reload()
                                        vm.$swal("Success!", response.status_description, "success");

                                        $('#switch-modall').modal('hide');
                                        window.location.reload()
                                    } else {
                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        } else {

                        }
                    })
                }

                if(action == 'jackpot-disable') {
                    vm.$swal.fire({
                        title: 'Jackpot Matches Disable?',
                        text: "If you are sure, proceed to Disable Jackpot!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Disable Jackpot!'
                    }).then((result) => {

                        if (result.value) {
                            var Voiddata = {
                                status: 0,
                                user_id: vm.user_id,
                                jp_week_id: dataa.jp_week_id,
                                token: vm.token
                            };
                            $.ajax({
                                url: config.baseURL+'portal/jackpot/approve',
                                type: 'POST',
                                data: JSON.stringify(Voiddata),
                                success: function (response, textStatus, jQxhr) {

                                    if (response.status_code == 200) {
                                        window.location.reload()
                                        vm.$swal("Success!", response.status_description, "success");

                                        $('#switch-modall').modal('hide');
                                    } else {
                                        vm.$swal("Error!", response.status_description, "error");
                                    }
                                },
                                error: function (jqXhr, textStatus, error) {

                                    vm.$swal("Error!", error, "error");
                                }
                            });
                        } else {

                        }
                    })
                }
            },


            itemAction: function (data) {
                var vm = this;

                window.localStorage.setItem('jackpot', data.jp_week_id);

                vm.$router.push({name: 'mjackpot'});



            },



            activateAccout(){
                var vm=this;


                vm.selected_data=vm.user_id

                // console.log("slot actions: on-click"+JSON.stringify(data));
                 $('#jackpot').modal("show");//.modal({centered: false}).modal("show");


            },
            createJackpot(){
                var vm=this;
                 $('#create-jackpot').modal("show");//.modal({centered: false}).modal("show");
            },
            editJackpot(){
                var vm=this;
                 $('#edit-jackpot').modal("show");//.modal({centered: false}).modal("show");
            },
            getsummaryData: function (start,end) {
                // console.log("am here start",start)
                // console.log("am here end",end)



            },


            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");


                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },



            createdAt: function (value) {
                if (!value) {
                    return '-';
                } else {
                    return moment(value).format('h:mm a,DD MMM YYYY');
                }
            },


            searchLogs: function (data) {

                var vm=this;


                if( (vm.search_logs== 0 || !vm.search_logs)){

                    vm.getsummaryData(vm.start,vm.end);
                    this.$refs.vuetable.reload();
                }else {
                    vm.getsummaryData(vm.start,vm.end,vm.search_logs);
                    this.moreParams.filter=this.search_logs;
                    this.$refs.vuetable.reload();
                }

            },



            searchMatches: function(){
                this.moreParams.filter = this.search_match;
                this.$refs.vuetable.reload();
            },


            onPaginationData(paginationData) {
                this.$refs.paginationInfo.setPaginationData(paginationData)
                this.$refs.pagination.setPaginationData(paginationData)
            },


            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },


            onLoading() {
                this.loading = true

            },
            onLoaded() {
                this.loading = false
            },



        },
    }

</script>