<template>
    <div class="l-container-content">
        <div class="row">
            <div class="col-6">
                <div class="text-title">
                    <h2 class="text-medium">Portal User</h2>
                </div>
            </div>
            <div class="col-6 text-right">
                <button  v-if="user_id ==18 " @click="createUser" class="ui blue button margin-left-md">Create User<i class="fa fa-user"></i></button>
                <div class="l-input inline">
                    <i class=" search icon"></i>
                    <input v-on:keyup.enter="searchMatches" type="text" placeholder="Search..." v-model="search_match">
                </div>
            </div>


            <button v-show="user_id==18 || user_id== 46" @click="uploadd" class="ui red button margin-left-md"><i class="fa fa-upload"></i></button>
        </div>

        <div class="row">
            <div class="col">
                <vuetable ref="vuetable"
                          :api-url="vueurl"
                          :fields="columns"
                          :sort-order="sortOrder"
                          :append-params="moreParams"
                          :per-page="10"
                          @vuetable:pagination-data="onPaginationData"
                >


                    <div slot="status-slot" slot-scope="props">

                        <div v-if="props.rowData.status === '0'"><strong style="color:#DB2828;">DEACTIVATED</strong></div>
                        <div v-if="props.rowData.status === '1'"><strong style="color:#21BA45;">ACTIVE</strong></div>

                    </div>

                    <div slot="role-slot" slot-scope="props">
                        <div v-if="props.rowData.role === '1'"><strong style="color:#21BA45;">Admim</strong></div>
                        <div v-if="props.rowData.role === '2'"><strong style="color:#21BA45;">Management</strong></div>
                        <div v-if="props.rowData.role === '3'"><strong style="color:#21BA45;">Resulting</strong></div>
                        <div v-if="props.rowData.role === '4'"><strong style="color:#21BA45;">Customer Services</strong></div>
                        <div v-if="props.rowData.role === '5'"><strong style="color:#21BA45;">Finances</strong></div>
                        <div v-if="props.rowData.role === '10'"><strong style="color:#21BA45;">Providers</strong></div>

                    </div>

                    <div  slot="email-slot" slot-scope="props">
                        <button class="ui small button" @click="onEmailUpdateClicked('user-email', props.rowData)" > <i class="exchange icon"  ></i>Email</button>

                    </div>


                    <div slot="Actions" slot-scope="props">
                        <b v-if="((user_id == 18))">
                            <button
                                    class="ui small button"
                                    @click="onActionClicked('bet-cancel', props.rowData)"
                            >
                                <i class=" red setting icon"></i>
                            </button>
                        </b>

                    </div>

                </vuetable>
                <div class="vuetable-pagination ui basic segment grid">
                    <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

                    <vuetable-pagination ref="pagination"
                                         @vuetable-pagination:change-page="onChangePage"
                    ></vuetable-pagination>
                </div>
            </div>
        </div>


        <div>
            <user></user>
        </div>

        <bonus v-if="deposit_mpesa" :data="deposit_mpesa" />

    </div>

</template>

<script>

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import bonus from "../modals/bonus"
    import user from "../modals/user"
    import {config} from '../config/config';


    export default {


        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo,bonus,user,config
        },
        name: 'virtual',

        data() {
            return {
                vueurl:config.baseURL+'portal/api/users',
                columns: [

                    {
                        name: 'email',
                        title: 'Email',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'
                    },
                    {
                        name: 'status-slot',
                        title: 'Status',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned',
                    },
                    {
                        name: 'username',
                        title: 'Phone Number',
                        sortField: 'username',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'role-slot',
                        title: 'Role',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned',
                    },
                    {
                        name: 'created',
                        title: 'Date Created',
                        sortField: 'created',
                        titleClass: '',
                        dataClass: '',
                        callback: 'createdAt'

                    },
                    'Actions',

                    'email-slot'
                ],

                deposit_mpesa:'',
                deposit_data:'',
                range:'',
                user_id:'',
                role:'',
                token:'',
                search_match:'',
                loadOnStart: true,
                sortOrder: [{
                    field: 'created',
                    direction: 'asc'
                }],
                moreParams: {
                    start: '',
                    filter: '',
                    end: '',
                    token: ''
                }

            }
        },

        created: function(){
            var obj = window.localStorage.getItem('user_info');
            if (!obj) {
                this.$router.push({name: 'login'});
            }
            this.checktimer();

        },


        mounted(){


            var vm = this;

            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;

            if((vm.role  !=2 && vm.role !=3)){

                vm.$router.push({name: 'login'});
            }


            var start = moment();
            var end = moment();

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
            }

            $('input[name="dates"]').daterangepicker({
                startDate: start,
                endDate: end,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);



        },




        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.reload();
            },
            range(){

                var start = this.range[0];
                var end = this.range[1];
                start= moment(start).format('YYYY-MM-DD');
                end= moment(end).format('YYYY-MM-DD');
                this.moreParams.start = start;
                this.moreParams.end = end;
                this.moreParams.token = token;
                this.$refs.vuetable.reload();
            }
        },


        methods: {

            createUser:function(){
                $('#user').modal("show");
            },

            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");


                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },


            uploadd(){

                var vm=this;

                var vm = this;

                if (vm.upload_loader) {
                    return;
                }

                var jsondata = {
                    mpesa_code: vm.mpesa_code,
                    deposit_amount: vm.mpesa_amt,
                    status_description: vm.description,
                };

                // vm.upload_loader = 'loading';

                vm.deposit_mpesa=jsondata;


                $('.ui.modal#bonus').modal({
                    closable: true,
                }).modal('show');

            },

            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                vm.moreParams.start = start;
                vm.moreParams.end = end;
                vm.moreParams.token = vm.token;

                vm.$refs.vuetable.reload();


            },

            searchMatches:function(){
                this.moreParams.filter = this.search_match;
                this.moreParams.token = this.token;
                this.$refs.vuetable.reload();
            },


            onCellClicked (data, field, event) {
                console.log('cellClicked: ', data)
                this.$refs.vuetable.toggleDetailRow(data)
            },


            onPaginationData(paginationData) {
                this.$refs.paginationInfo.setPaginationData(paginationData)
                this.$refs.pagination.setPaginationData(paginationData)
            },



            onEmailUpdateClicked(action, data) {


                var vm=this;

                vm.$swal({
                    title: "Change Email ?",
                    text: "If you are sure, type in the new Email:",
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Update',
                    showLoaderOnConfirm: true,
                    preConfirm: (priority) => {

                        if (!priority) {
                            return;
                        }
                        var dataa = {
                            id: data.id,
                            email: priority,
                            token:vm.token,
                        };

                        $.ajax({
                            url: config.baseURL+'portal/account/email/reset',
                            type: 'POST',
                            data: JSON.stringify(dataa),
                            success: function (response, textStatus, jQxhr) {

                                if (response.status_code == 200) {
                                    vm.$swal("Success!", response.status_description, "success");

                                    $('#switch-modall').modal('hide');
                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }
                });

            },


            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },

            onActionClicked(action, data) {
                var vm=this;


                vm.$swal.fire({
                    title: 'Reset  password?',
                    text: "If you are sure!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Reset Password!'
                }).then((result) => {

                    if (result.value) {
                        var Voiddata = {
                            id: data.id,
                            token:vm.token,
                        };

                        $.ajax({
                            url: config.baseURL+'portal/account/reset',
                            type: 'POST',
                            data: JSON.stringify(Voiddata),
                            success: function (response, textStatus, jQxhr) {

                                if (response.status_code == 200) {
                                    vm.$swal("Success!", response.status_description, "success");

                                    $('#switch-modall').modal('hide');
                                } else {
                                    vm.$swal("Error!", response.status_description, "error");
                                }
                            },
                            error: function (jqXhr, textStatus, error) {

                                vm.$swal("Error!", error, "error");
                            }
                        });
                    }else {

                    }
                })
            }

        },


    }

</script>