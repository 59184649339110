<template>
    <div class="l-main-content" v-show="role==2 || role==3 " >
        <div class="container">
            <div  class="row bg-white bg-shadow margin-bottom-md padding-top-md">
                    <div class="col">
                    <div id="reportrange" class="left aligned column" style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ddd; width: 100%; border-radius: 4px;">
                        <i class="fa fa-calendar" style="margin-right: .75em;"></i>
                        <span></span> <i class="fa fa-caret-down" style="float: right"></i>
                    </div>
                    <!--<h6><strong>Previous Dates</strong> {{start_relative}} &#45;&#45; {{end_relative}}</h6>-->
                </div>

                <div v-show="user_id==18 || user_id==17  || user_id==18 ||user_id==25 ||user_id==26 || user_id==1 ">
                    <div class="rsum">
                        <div class="sum green">
                            <loader v-show='loading1' />
                            <div class="h">
                                <div class="l">Deposits</div>
                            </div>

                            <div class="t">{{deposit}}</div>
                            <!--<div class="s">+ ({{deposit}}/{{deposit}}*100)</div>-->
                        </div>
                        <div class="sum red">
                            <div class="h">
                                <div class="l">Withdrawals</div>
                                <!--<div class="r">- 2.01%</div>-->
                            </div>
                            <div class="t">{{withdraw}}</div>
                            <!--<div class="s">+ 0.18%</div>-->
                        </div>
                    </div>
                    <div class="rsum">
                        <div class="sum sum-3 blue">
                            <div class="h">
                                <div class="l">Stake</div>
                            </div>
                            <div class="t">{{stake}}</div>
                            <!--<div class="s">= 0.00</div>-->
                        </div>
                        <div class="sum sum-3 yellow">
                            <div class="h">
                                <div class="l">Winnings</div>
                            </div>
                            <div class="t">{{winnings}}</div>
                            <!--<div class="s">+ 23.90%</div>-->
                        </div>
                        <div class="sum sum-3 teal">
                            <div class="h">
                                <div class="l">P&L</div>
                                <!--<div class="r">- 2.01%</div>-->
                            </div>
                            <div class="t">{{pnl}}</div>
                            <!--<div class="s">- 3.90%</div>-->
                        </div>
                    </div>
                </div>

                <div class="padding-sm"></div>
                <div class="l-tabs" v-show="user_id==18 || user_id==17  || user_id==18 ||user_id==25 ||user_id==26 ||user_id==45 || user_id==21 || user_id==37 || user_id==1">
                    <ul>
                        <li :class="{'selected': selectedTab1 == 'real'}" @click="selectedTab1 = 'real'">Real Bets</li>
                        <li :class="{'selected': selectedTab1 == 'cashout'}" @click="selectedTab1 = 'cashout'">Cashout</li>
                        <li :class="{'selected': selectedTab1 == 'kiron'}" @click="selectedTab1 = 'kiron'">Kiron</li>
                        <li :class="{'selected': selectedTab1 == 'virtual'}" @click="selectedTab1 = 'virtual'">LigiNare</li>
                        <li :class="{'selected': selectedTab1 == 'gr'}" @click="selectedTab1 = 'gr'">GoldenRace</li>
                        <li :class="{'selected': selectedTab1 == 'imoon'}" @click="selectedTab1 = 'imoon'">Imoon</li>
                        <li :class="{'selected': selectedTab1 == 'casino'}" @click="selectedTab1 = 'casino'">Aviator</li>
                        <li :class="{'selected': selectedTab1 == 'stp'}" @click="selectedTab1 = 'stp'">Zoza</li>
                        <li :class="{'selected': selectedTab1 == 'ecasino'}" @click="selectedTab1 = 'ecasino'">Elbet</li>
                        <li :class="{'selected': selectedTab1 == 'pcasino'}" @click="selectedTab1 = 'pcasino'">pragmatic</li>
                        <li :class="{'selected': selectedTab1 == 'aviatrix'}" @click="selectedTab1 = 'aviatrix'">Aviatrix</li>
                        <li :class="{'selected': selectedTab1 == 'smartsoft'}" @click="selectedTab1 = 'smartsoft'">Smartsoft</li>
                        <li :class="{'selected': selectedTab1 == 'instant'}" @click="selectedTab1 = 'instant'">Instant</li>
                      <li :class="{'selected': selectedTab1 == 'evolution'}" @click="selectedTab1 = 'evolution'">Evolution</li>
                        <!--<li :class="{'selected': selectedTab1 == 'jackpot'}" @click="selectedTab1 = 'jackpot'">OmokaJackpot</li>-->
                        <!--<li :class="{'selected': selectedTab1 == 'mjackpot'}" @click="selectedTab1 = 'mjackpot'">MidWeekJackpot</li>-->
                        <li :class="{'selected': selectedTab1 == 'djackpot'}" @click="selectedTab1 = 'djackpot'">DailyJackpot</li>
                        <!--<li :class="{'selected': selectedTab1 == 'mbogi'}" @click="selectedTab1 = 'mbogi'">Mbogi Refferals</li>-->
                        <li :class="{'selected': selectedTab1 == 'bet'}" @click="selectedTab1 = 'bet'">Bet Shareable</li>
                        <li :class="{'selected': selectedTab1 == 'app'}" @click="selectedTab1 = 'app'">Influencer App Downloads</li>
                        <li :class="{'selected': selectedTab1 == 'betmaster'}" @click="selectedTab1 = 'betmaster'">Customer Tickets</li>


                    </ul>
                </div>

                <div v-show="user_id==18 || user_id==17  || user_id==18 ||user_id==25 ||user_id==26 ||user_id==45 || user_id==21 || user_id==21 || user_id==37 || user_id==1">
                <div v-show="selectedTab1 == 'real'" class="col">
                    <div class="overflow" v-show="user_id==18 || user_id==17  || user_id==18 ||user_id==25 ||user_id==26 ||user_id==45 || user_id==21 || user_id==37 || user_id==1" >
                        <table class="table simple">
                            <tbody>
                            <tr class="sm">
                                <td class="text-grey text-left">Real Bets</td>
                                <td class="text-grey text-right">{{bets}}</td>
                                <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                            </tr>

                            <tr class="sm">
                                <td class="text-grey text-left">Stake</td>
                                <td class="text-grey text-right">KES {{stake}}</td>
                                <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Winnings</td>
                                <td class="text-grey text-right">KES {{winnings}}</td>
                                <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">P&L</td>
                                <td class="text-grey text-right">KES {{pnl}}</td>
                                <!--<td class="long text-grey-light text-medium text-right"><div class="ui green tiny label">= 0.00%</div></td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Resulted Bets</td>
                                <td class="text-grey text-right">{{percent}}%</td>
                                <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Unsettled Bets</td>
                                <td class="text-grey text-right">{{pending}}</td>
                                <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">SMS Bets</td>
                                <td class="text-grey text-right">{{sms_bets}}</td>
                                <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                    <div v-show="selectedTab1 == 'cashout'" class="col">
                        <div class="overflow" v-show="user_id==18 || user_id==17  || user_id==18 ||user_id==25 ||user_id==26 ||user_id==45 || user_id==37 || user_id==1" >
                            <table class="table simple">
                                <tbody>
                                <tr class="sm">
                                    <td class="text-grey text-left">Stake</td>
                                    <td class="text-grey text-right">KES {{cashout_stake}}</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Possible Winnings</td>
                                    <td class="text-grey text-right">KES {{cashout_possible_win}}</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Cashed Out</td>
                                    <td class="text-grey text-right">KES {{cashout_amount}}</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">P&L</td>
                                    <td class="text-grey text-right">KES {{cashout_pnl}}</td>
                                    <!--<td class="long text-grey-light text-medium text-right"><div class="ui green tiny label">= 0.00%</div></td>-->
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                <div v-show="selectedTab1 == 'kiron'" class="col">
                    <div class="overflow">
                        <table class="table simple">
                            <tbody>
                            <tr class="sm">
                                <td class="text-grey text-left">Total Bets</td>
                                <td class="text-grey text-right">{{kiron_bet}}</td>
                                <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Stake</td>
                                <td class="text-grey text-right">KES {{kiron_stake}}</td>
                                <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Winnings</td>
                                <td class="text-grey text-right">KES {{kiron_winnings}}</td>
                                <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">P&L</td>
                                <td class="text-grey text-right">KES {{kiron_pnl}}</td>
                                <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Bonus total</td>
                                <td class="text-grey text-right">{{virtual_bonus_total}}</td>
                                <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Claimed Bonus</td>
                                <td class="text-grey text-right">{{virtual_bonus_claimed}}</td>
                                <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                            </tr>

                            <tr class="sm">
                                <td class="text-grey text-left">Unclaimed Bonus</td>
                                <td class="text-grey text-right">{{virtual_bonus_unclaimed}}</td>
                                <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                  <div v-show="selectedTab1 == 'virtual'" class="col">
                    <div class="overflow">
                      <table class="table simple">
                        <tbody>

                        <tr class="sm">
                          <td class="text-grey text-left">Total Profiles</td>
                          <td class="text-grey text-right">{{virtualnew_profiles}}</td>
                          <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">Total Bets</td>
                          <td class="text-grey text-right">{{virtualnew_bets}}</td>
                          <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">Stake</td>
                          <td class="text-grey text-right">KES {{virtualnew_stake}}</td>
                          <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">Winnings</td>
                          <td class="text-grey text-right">KES {{virtualnew_winnings}}</td>
                          <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">P&L</td>
                          <td class="text-grey text-right">KES {{virtualnew_pnl}}</td>
                          <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>


                    <div v-show="selectedTab1 == 'gr'" class="col">
                        <div class="overflow">
                            <table class="table simple">
                                <tbody>

                                <tr class="sm">
                                    <td class="text-grey text-left">Total Profiles</td>
                                    <td class="text-grey text-right">{{gr_profiles}}</td>
                                    <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Bets</td>
                                    <td class="text-grey text-right">{{gr_bets}}</td>
                                    <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Stake</td>
                                    <td class="text-grey text-right">KES {{gr_stake}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Winnings</td>
                                    <td class="text-grey text-right">KES {{gr_winnings}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">P&L</td>
                                    <td class="text-grey text-right">KES {{gr_pnl}}</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                  <div v-show="selectedTab1 == 'instant'" class="col">
                    <div class="overflow">
                      <table class="table simple">
                        <tbody>

                        <tr class="sm">
                          <td class="text-grey text-left">Total Profiles</td>
                          <td class="text-grey text-right">{{instant_profiles}}</td>
                          <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">Total Bets</td>
                          <td class="text-grey text-right">{{instant_bets}}</td>
                          <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">Stake</td>
                          <td class="text-grey text-right">KES {{instant_stake}}</td>
                          <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">Winnings</td>
                          <td class="text-grey text-right">KES {{instant_winnings}}</td>
                          <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">P&L</td>
                          <td class="text-grey text-right">KES {{instant_pnl}}</td>
                          <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                        </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div v-show="selectedTab1 == 'evolution'" class="col">
                    <div class="overflow">
                      <table class="table simple">
                        <tbody>

                        <tr class="sm">
                          <td class="text-grey text-left">Total Profiles</td>
                          <td class="text-grey text-right">{{evolution_profiles}}</td>
                          <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">Total Bets</td>
                          <td class="text-grey text-right">{{evolution_bets}}</td>
                          <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">Stake</td>
                          <td class="text-grey text-right">KES {{evolution_stake}}</td>
                          <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">Winnings</td>
                          <td class="text-grey text-right">KES {{evolution_winnings}}</td>
                          <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">P&L</td>
                          <td class="text-grey text-right">KES {{evolution_pnl}}</td>
                          <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                        </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>

                    <div v-show="selectedTab1 == 'imoon'" class="col">
                        <div class="overflow">
                            <table class="table simple">
                                <tbody>

                                <tr class="sm">
                                    <td class="text-grey text-left">Total Profiles</td>
                                    <td class="text-grey text-right">{{imoon_profiles}}</td>
                                    <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Bets</td>
                                    <td class="text-grey text-right">{{imoon_bets}}</td>
                                    <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Stake</td>
                                    <td class="text-grey text-right">KES {{imoon_stake}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Winnings</td>
                                    <td class="text-grey text-right">KES {{imoon_winnings}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">P&L</td>
                                    <td class="text-grey text-right">KES {{imoon_pnl}}</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                  <div v-show="selectedTab1 == 'aviatrix'" class="col">
                    <div class="overflow">
                      <table class="table simple">
                        <tbody>

                        <tr class="sm">
                          <td class="text-grey text-left">Total Profiles</td>
                          <td class="text-grey text-right">{{aviatrix_profiles}}</td>
                          <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">Total Bets</td>
                          <td class="text-grey text-right">{{aviatrix_bets}}</td>
                          <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">Stake</td>
                          <td class="text-grey text-right">KES {{aviatrix_stake}}</td>
                          <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">Winnings</td>
                          <td class="text-grey text-right">KES {{aviatrix_winnings}}</td>
                          <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">P&L</td>
                          <td class="text-grey text-right">KES {{aviatrix_pnl}}</td>
                          <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                        </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div v-show="selectedTab1 == 'smartsoft'" class="col">
                    <div class="overflow">
                      <table class="table simple">
                        <tbody>

                        <tr class="sm">
                          <td class="text-grey text-left">Total Profiles</td>
                          <td class="text-grey text-right">{{smartsoft_profiles}}</td>
                          <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">Total Bets</td>
                          <td class="text-grey text-right">{{smartsoft_bets}}</td>
                          <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">Stake</td>
                          <td class="text-grey text-right">KES {{smartsoft_stake}}</td>
                          <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">Winnings</td>
                          <td class="text-grey text-right">KES {{smartsoft_winnings}}</td>
                          <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                        </tr>
                        <tr class="sm">
                          <td class="text-grey text-left">P&L</td>
                          <td class="text-grey text-right">KES {{smartsoft_pnl}}</td>
                          <!--<td class="long text-red text-medium text-right">- 0.00%</td>-->
                        </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>

                    <div v-show="selectedTab1 == 'stp'" class="col">
                        <div class="overflow">
                            <table class="table simple">

                                <tbody>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Profiles</td>
                                    <td class="text-grey text-right">{{zoza_profiles}}</td>
                                    <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Bets</td>
                                    <td class="text-grey text-right">{{zoza_bets}}</td>
                                    <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Stake</td>
                                    <td class="text-grey text-right">KES {{zoza_bet_amount}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                                </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div v-show="selectedTab1 == 'app'" class="col">
                        <div class="overflow">
                            <table class="table simple">

                                <tbody>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Profiles</td>
                                    <td class="text-grey text-right">{{app_profiles}}</td>
                                    <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div v-show="selectedTab1 == 'casino'" class="col">
                        <div class="overflow">
                            <table class="table simple">
                                <tbody>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Bets</td>
                                    <td class="text-grey text-right">{{casiono_bets}}</td>
                                    <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Stake</td>
                                    <td class="text-grey text-right">KES {{casiono_bet_amount}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Winnings</td>
                                    <td class="text-grey text-right">KES {{casiono_won}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Pnl</td>
                                    <td class="text-grey text-right">KES {{casiono_pnl}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div v-show="selectedTab1 == 'ecasino'" class="col">
                        <div class="overflow">
                            <table class="table simple">
                                <tbody>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Bets</td>
                                    <td class="text-grey text-right">{{casiono_ebets}}</td>
                                    <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Stake</td>
                                    <td class="text-grey text-right">KES {{casiono_ebet_amount}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Winnings</td>
                                    <td class="text-grey text-right">KES {{casiono_ewon}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Pnl</td>
                                    <td class="text-grey text-right">KES {{casiono_epnl}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div v-show="selectedTab1 == 'pcasino'" class="col">
                        <div class="overflow">
                            <table class="table simple">
                                <tbody>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Bets</td>
                                    <td class="text-grey text-right">{{casiono_pbets}}</td>
                                    <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Stake</td>
                                    <td class="text-grey text-right">KES {{casiono_pbet_amount}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Winnings</td>
                                    <td class="text-grey text-right">KES {{casiono_pwon}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Pnl</td>
                                    <td class="text-grey text-right">KES {{casiono_ppnl}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 0.00%</td>-->
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                   <div v-show="selectedTab1 == 'jackpot'" class="col">
                    <div class="overflow">
                        <table class="table simple">
                            <tbody>
                            <tr class="sm">
                                <td class="text-grey text-left">Total Bets</td>
                                <td class="text-grey text-right"> {{jackpot_bets}}</td>
                                <!--<td class="long text-red text-medium text-right">- 0.45%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Unique Players</td>
                                <td class="text-grey text-right">{{jackpot_players}}</td>
                                <!--<td class="long text-green text-medium text-right">+ 2.45%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Stake</td>
                                <td class="text-grey text-right">KES {{jackpot_stake}}</td>
                                <!--<td class="long text-red text-medium text-right">- 0.45%</td>-->
                            </tr>

                            <tr class="sm">
                                <td class="text-grey text-left">Winnings</td>
                                <td class="text-grey text-right">KES 0.0</td>
                                <!--<td class="long text-red text-medium text-right">- 0.45%</td>-->
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">P&L</td>
                                <td class="text-grey text-right">KES {{jackpot_stake}}</td>
                                <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                    <div v-show="selectedTab1 == 'mjackpot'" class="col">
                        <div class="overflow">
                            <table class="table simple">
                                <tbody>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Bets</td>
                                    <td class="text-grey text-right"> {{midweek_bets}}</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.45%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Unique Players</td>
                                    <td class="text-grey text-right">{{midweek_profiles}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 2.45%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Stake</td>
                                    <td class="text-grey text-right">KES {{midweek_stake}}</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.45%</td>-->
                                </tr>

                                <tr class="sm">
                                    <td class="text-grey text-left">Winnings</td>
                                    <td class="text-grey text-right">KES 0.0</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.45%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">P&L</td>
                                    <td class="text-grey text-right">KES {{midweek_stake}}</td>
                                    <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div v-show="selectedTab1 == 'djackpot'" class="col">
                        <div class="overflow">
                            <table class="table simple">
                                <tbody>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Bets</td>
                                    <td class="text-grey text-right"> {{daily_bets}}</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.45%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Unique Players</td>
                                    <td class="text-grey text-right">{{daily_profiles}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 2.45%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Stake</td>
                                    <td class="text-grey text-right">KES {{daily_stake}}</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.45%</td>-->
                                </tr>

                                <tr class="sm">
                                    <td class="text-grey text-left">Winnings</td>
                                    <td class="text-grey text-right">KES 0.0</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.45%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">P&L</td>
                                    <td class="text-grey text-right">KES {{daily_stake}}</td>
                                    <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div v-show="selectedTab1 == 'mbogi'" class="col">
                        <div class="overflow">
                            <table class="table simple">
                                <tbody>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Signups</td>
                                    <td class="text-grey text-right"> {{total_referal}}</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.45%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Redeemed</td>
                                    <td class="text-grey text-right">{{total_redeemed}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 2.45%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Stake</td>
                                    <td class="text-grey text-right">KES {{total_stake}}</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.45%</td>-->
                                </tr>

                                <tr class="sm">
                                    <td class="text-grey text-left">Winnings</td>
                                    <td class="text-grey text-right">KES 0.0</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.45%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">P&L</td>
                                    <td class="text-grey text-right">KES {{daily_stake}}</td>
                                    <!--<td class="long text-grey-light text-medium text-right">= 0.00%</td>-->
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>



                    <div v-show="selectedTab1 == 'bet'" class="col">
                        <div class="overflow">
                            <table class="table simple">
                                <tbody>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Bets</td>
                                    <td class="text-grey text-right"> {{shareable_bets}}</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.45%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total stake</td>
                                    <td class="text-grey text-right">KES {{shareable_stake}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 2.45%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Profiles</td>
                                    <td class="text-grey text-right">{{shareable_profiles}}</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.45%</td>-->
                                </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div v-show="selectedTab1 == 'betmaster'" class="col">
                        <div class="overflow">
                            <table class="table simple">
                                <tbody>

                                <tr class="sm">
                                    <td class="text-grey text-left">Total Tickets</td>
                                    <td class="text-grey text-right">{{total_tickets}}</td>
                                    <!--<td class="long text-green text-medium text-right">+ 2.45%</td>-->
                                </tr>
                                <tr class="sm">
                                    <td class="text-grey text-left">Total Closed</td>
                                    <td class="text-grey text-right">{{total_closed}}</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.45%</td>-->
                                </tr>

                                <tr class="sm">
                                    <td class="text-red text-left">Total open</td>
                                    <td class="text-red text-right">{{total_open}}</td>
                                    <!--<td class="long text-red text-medium text-right">- 0.45%</td>-->
                                </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row bg-white bg-shadow margin-bottom-md" v-show="user_id==18 || user_id==17  || user_id==18 ||user_id==25 ||user_id==26 ||user_id==45 || user_id==21 || user_id==37 || user_id==1">
                <div class="l-tabs">
                    <ul>
                        <li :class="{'selected': selectedTab2 == 'liabilities'}" @click="selectedTab2 = 'liabilities'">Liabilities</li>
                        <li :class="{'selected': selectedTab2 == 'balance'}" @click="selectedTab2 = 'balance'">Balance</li>
                    </ul>
                </div>
                <div v-show="selectedTab2 == 'liabilities'" class="col">
                    <div class="overflow">
                        <table class="table simple">
                            <tbody>
                            <tr class="sm">
                                <td class="text-grey text-left">Total Liabilities</td>
                                <td class="text-grey text-right">KES {{balance}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Total Points</td>
                                <td class="text-grey text-right">{{points}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Active</td>
                                <td class="text-grey text-right">KES {{active}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">Last Month</td>
                                <td class="text-grey text-right">KES {{last_3}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">3 Months</td>
                                <td class="text-grey text-right">KES {{last_5}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">1 Year</td>
                                <td class="text-grey text-right">KES {{last_year}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-show="selectedTab2 == 'balance'" class="col">
                    <div class="overflow">
                        <table class="table simple">
                            <tbody>
                            <tr class="sm">
                                <td class="text-grey text-left">100 -1000</td>
                                <td class="text-grey text-right">{{balance1}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">1,001-10,000</td>
                                <td class="text-grey text-right">{{balance2}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">10,001-100,000</td>
                                <td class="text-grey text-right">{{balance3}}</td>
                            </tr>

                            <tr class="sm">
                                <td class="text-grey text-left">70,000+</td>
                                <td class="text-grey text-right">{{balance5}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">100,000+</td>
                                <td class="text-grey text-right">{{balance4}}</td>
                            </tr>
                            <tr class="sm">
                                <td class="text-grey text-left">1,000,000+</td>
                                <td class="text-grey text-right">{{balance6}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <div class="row bg-white bg-shadow margin-bottom-md">
                <div class="col padding-top-md padding-bottom-sm">
                    <h3 class="text-medium">Acquisition</h3>
                    <small class="text-grey-light hidden">Today, 14 September 2020</small>
                </div>
                <div class="col-6">
                    <h5 class="text-grey">Signups</h5>
                    <h3 class="text-green text-medium">{{acquisition}}</h3>
                </div>
                <div class="col-6 border-left">
                    <h5 class="text-grey">Inactives</h5>
                    <h3 class="text-medium text-grey-light text-red">{{dropoffs}}</h3>
                </div>



                <div class="l-tabs">
                    <ul>
                        <li :class="{'selected': selectedTab4 == 'attrubution'}" @click="selectedTab4 = 'attrubution'">Attribution Bets</li>
                        <li :class="{'selected': selectedTab4 == 'attrubution1'}" @click="selectedTab4 = 'attrubution1'">Radio Attribution</li>


                    </ul>
                </div>



                <div v-show="selectedTab4 == 'attrubution'" class="col">
                    <div class="overflow padding-top-sm">
                        <div class="tabular">
                            <loader v-show='loading' />
                            <vuetable ref="vuetable"
                                      :api-url="vueurl"
                                      :fields="columns"
                                      :multi-sort="true"
                                      :append-params="moreParams"
                                      :sort-order="sortOrder"
                                      :per-page="10"
                                      @vuetable:pagination-data="onPaginationData"
                                      @vuetable:loading="onLoading"
                                      @vuetable:loaded="onLoaded"
                                      :css="{tableClass: 'ui blue selectable unstackable celled table',}">
                            </vuetable>


                            <div class="col vuetable-pagination" style="background: #fff !important;">
                                <vuetable-pagination-info style="font-size: .8em" class="text-center p-top p-bottom" ref="paginationInfo">

                                </vuetable-pagination-info>
                                <vuetable-pagination ref="pagination" style="font-size: .75em;"
                                                     @vuetable-pagination:change-page="onChangePage"
                                ></vuetable-pagination>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="selectedTab4 == 'attrubution1'" class="col">
                    <div class="overflow padding-top-sm">
                        <div class="tabular">
                            <loader v-show='loading' />
                            <vuetable ref="vuetableq"
                                      :api-url="vueurl"
                                      :fields="attricolumns"
                                      :multi-sort="true"
                                      :append-params="moreParams1"
                                      :sort-order="sortOrder1"
                                      :per-page="30"
                                      @vuetable:pagination-data="onPaginationDataa"
                                      @vuetable:loading="onLoading"
                                      @vuetable:loaded="onLoaded"
                                      :css="{tableClass: 'ui blue selectable unstackable celled table',}">
                            </vuetable>


                            <div class="col vuetable-pagination" style="background: #fff !important;">
                                <vuetable-pagination-info style="font-size: .8em" class="text-center p-top p-bottom" ref="paginationInfoo">

                                </vuetable-pagination-info>
                                <vuetable-pagination ref="paginationn" style="font-size: .75em;"
                                                     @vuetable-pagination:change-page="onChangePagee"
                                ></vuetable-pagination>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>




    </div>

</template>




<script>
    import $ from  "jquery";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import loader from "@/components/loader"
    import {config} from '../config/config';

    function formatNumber(num) {

        if(num){
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        }else{
            return num;
        }

    }

    export default {
        data(){
            return{
                vueurl:config.baseURLWEB+'dash/api/attribution',
                start_relative: '',
                vuetableq: '',
                end_relative: '',
                selectedTab1: 'real',
                selectedTab4: 'attrubution',
                selectedTab2: 'liabilities',
                selectedTabj: 'jackpot',
                summary: [],
                liability: [],
                liabilityy: [],
                liability1: [],
                liability2: [],
                liability3: [],
                liability4: [],
                liability5: [],
                liability6: [],
                dashtop_loader: '',
                deposit: 0,
                virtual_bonus_total: 0,
                virtual_bonus_unclaimed: 0,
                virtual_bonus_claimed: 0,
                points: 0,
                casiono_bet_amount: 0,
                casiono_won: 0,
                casiono_bets: 0,
                app_profiles: 0,


                casiono_ebet_amount: 0,
                casiono_ewon: 0,
                casiono_ebets: 0,
                casiono_epnl: 0,


                casiono_pbet_amount: 0,
                casiono_pwon: 0,
                casiono_pbets: 0,
                casiono_ppnl: 0,

                range:'',
                vuetable: '',
                casiono_pnl: 0,
                depositors: 0,
                withdraw: 0,
                withdrawals: 0,
                bets: 0,
                virtual_bet: 0,
                kiron_bet: 0,
                jackpot_bets: 0,
                jackpot_players: 0,
                jackpot_stake: 0,
                pnl: 0,
                virtual_pnl: 0,
                kiron_pnl: 0,
                stake: 0,
                user: 0,
                cashout_possible_win: 0,
                cashout_amount: 0,
                cashout_stake: 0,
                cashout_pnl: 0,
                acquisition: 0,
                dropoffs: 0,
                winnings: 0,
                virtual_stake: 0,
                kiron_stake: 0,
                master_winnings: 0,
                master_stake: 0,
                master_profiles: 0,
                virtual_winnings: 0,

                total_tickets: 0,
                total_closed: 0,
                total_open: 0,



                kiron_winnings: 0,
                balance: 0,


                stp_stake: 0,
                stp_winnings: 0,
                stp_bets: 0,
                stp_pnl: 0,



                pending: 0,
                percent: 0,
                active: 0,
                last_3: 0,
                last_5: 0,
                numbers: 0,
                last_year: 0,
                total1: 0,
                balance1: 0,
                sms_bets: 0,
                ldeposit: 0,
                ldepositors: 0,
                lwithdraw: 0,
                lwithdrawals: 0,
                lbets: 0,
                lvirtual_bet: 0,
                lkiron_bet: 0,
                lpnl:0,
                lvirtual_pnl: 0,
                lkiron_pnl: 0,
                lstake: 0,
                lvirtual_stake: 0,
                lkiron_stake: 0,
                lacquisition: 0,
                ldropoffs: 0,
                lwinnings: 0,
                lvirtual_winnings: 0,
                lkiron_winnings: 0,
                lpending: 0,
                lsms_bets: 0,
                ljackpot_stake: 0,
                ljackpot_players: 0,

                total2: 0,
                balance2: 0,
                loading: true,
                loading1: true,

                gr_winnings: 0,
                gr_bets: 0,
                gr_profiles: 0,
                gr_stake: 0,
                gr_pnl: 0,

                instant_winnings: 0,
                instant_bets: 0,
                instant_profiles: 0,
                instant_stake: 0,
                instant_pnl: 0,


              evolution_winnings: 0,
              evolution_bets: 0,
              evolution_profiles: 0,
              evolution_stake: 0,
              evolution_pnl: 0,

                imoon_winnings: 0,
                imoon_bets: 0,
                imoon_profiles: 0,
                imoon_stake: 0,
                imoon_pnl: 0,


                aviatrix_winnings: 0,
                aviatrix_bets: 0,
                aviatrix_profiles: 0,
                aviatrix_stake: 0,
                aviatrix_pnl: 0,

              smartsoft_winnings: 0,
              smartsoft_bets: 0,
              smartsoft_profiles: 0,
              smartsoft_stake: 0,
              smartsoft_pnl: 0,

                virtualnew_winnings: 0,
                virtualnew_bets: 0,
                virtualnew_profiles: 0,
                virtualnew_stake: 0,
                virtualnew_pnl: 0,






                total3: 0,
                balance3: 0,
                total_referal: 0,
                total_redeemed: 0,
                total_stake: 0,

                total4: 0,
                balance4: 0,
                midweek_profiles: 0,
                midweek_stake: 0,
                midweek_bets: 0,


                shareable_stake: 0,
                shareable_profiles: 0,
                shareable_bets: 0,

                daily_profiles: 0,
                daily_stake: 0,
                daily_bets: 0,

                zoza_profiles: 0,
                zoza_bets: 0,
                zoza_bet_amount: 0,



                total5: 0,
                balance5: 0,

                total6: 0,
                balance6: 0,



                token: '',
                user_id: '',
                role: '',

                columns: [
                    {
                        name: 'name',
                        title: 'Attribution',
                    },
                    {
                        name: 'count',
                        title: 'Count',
                        sortField: 'count',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'
                    },
                ],


                attricolumns: [
                    {
                        name: 'attribution',
                        title: 'Attribution',
                    },
                    {
                        name: 'total',
                        title: 'Count',
                        sortField: 'total',
                        titleClass: 'center aligned',
                        dataClass: 'center aligned'
                    },
                ],
                moreParams: {
                    filter: '',
                    start: '',
                    end: '',
                    token: this.token,

                },


                moreParams1: {
                    filter: '',
                    start: '',
                    end: '',
                    token: this.token,

                },
                sortOrder: [{
                    field: 'a.profile_id',
                    direction: 'desc'
                }],

                sortOrder1: [{
                    field: 'total',
                    direction: 'desc'
                }],
            }
        },
        components: { DatePicker,Vuetable, VuetablePagination, VuetablePaginationInfo,loader,config },


        mounted() {

            var vm = this;


            var start = moment();
            var end = moment();

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                $('#reportrange span').html(start.format('D MMM, YYYY') + ' - ' + end.format('D MMM, YYYY'));
            }

            $('#reportrange').daterangepicker({
                startDate: start,
                endDate: end,
                autoApply:true,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);


            vm.checktimer();

            vm.getliability();
            vm.getpointsliability();
            vm.getliabilitysta();
            vm.getliability1();
            vm.getliability2();
            vm.getliability3();
            vm.getliability4();
            vm.getliability5();
            vm.getliability6();

        },

        beforeCreate: function () {
            var vm=this;
            const itemStr = localStorage.getItem("timer")
            // if the item doesn't exist, return null
            if (!itemStr) {
                return null
            }
            const item = itemStr

            var checktimer = new Date(item);

            const now = new Date()
            // compare the expiry time of the item with the current time
            if (now.getTime() > checktimer.getTime()) {
                // If the item is expired, delete the item from storage
                // and return null
                localStorage.removeItem("timer");
                localStorage.removeItem("user_info");


                const Toast = vm.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 9000
                });
                Toast.fire({
                    type: 'error',
                    title: "Your session expired, Please login again "
                })

                vm.$router.push({name: 'login'});
                return null
            }


        },


        created(){
            var vm=this;


            var obj = window.localStorage.getItem('user_info');

            var jsonData = JSON.parse(obj);
            var rolee=jsonData.role;
            var userr=jsonData.id;
            var tokenn=jsonData.token;

            vm.role=rolee;
            vm.user_id=userr;
            vm.token=tokenn;

            if((vm.role  !=2 && vm.role !=3 && vm.role !=5)){



                vm.$router.push({name: 'login'});
            }


            var start = moment();
            var end = moment();

            vm.moreParams.start=start.format('YYYY-MM-DD');
            vm.moreParams.end=end.format('YYYY-MM-DD');





            vm.moreParams1.start=start.format('YYYY-MM-DD');
            vm.moreParams1.end=end.format('YYYY-MM-DD');




            vm.moreParams.token=vm.token;
            vm.moreParams1.token=vm.token;



            // this.$refs.vuetable.reload()
            // this.$refs.vuetable.reload();





            if (performance.navigation.type == 1) {
                if (this.$route.path == '/page3') {
                    if (confirm('Reload site?. Change you made may not be saved.')) {
                        vm.checktimer();
                    } else {

                    }
                }

            }



        },


        methods:{


            checktimer:function(){
                var vm=this;
                const itemStr = localStorage.getItem("timer")
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = itemStr

                var checktimer = new Date(item);

                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > checktimer.getTime()) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("timer");
                    localStorage.removeItem("user_info");


                    const Toast = vm.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 9000
                    });
                    Toast.fire({
                        type: 'error',
                        title: "Your session expired, Please login again "
                    })

                    vm.$router.push({name: 'login'});
                    return null
                }

            },

            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                var start_relative;
                var end_relative;




                // start_relative=moment(start).subtract(1, 'month').format('YYYY-MM-DD');
                // end_relative= moment(end).subtract(1, 'month').format('YYYY-MM-DD');
                //
                // vm.start_relative=start_relative;
                // vm.end_relative=end_relative;



                vm.moreParams.start = start;
                vm.moreParams.end = end;

                vm.moreParams1.start = start;
                vm.moreParams1.end = end;
                vm.moreParams.token = vm.token;

                vm.checktimer();
                vm.getsummaryData(start,end);
                vm.getmbogiData(start,end);
                vm.getstpData(start,end);
                vm.getbetshareablebetsData(start,end);
                vm.getbetshareablebetstakeData(start,end);
                vm.getEuroJackpotData(start,end);
                vm.getdailyJackpotData(start,end);
                vm.getmasterbetsData(start,end);
                vm.getVirtualBonusData(start,end);
                vm.getcasinoData(start,end);
                vm.getzozaData(start,end);
                vm.getappsData(start,end);
                vm.getecasinoData(start,end);
                vm.getpcasinoData(start,end);
                vm.getgoldenraceData(start,end);
                vm.getInstantData(start,end);
                vm.getEvolutionData(start,end);
                vm.getAviatrixData(start,end);
                vm.getSmartsoftData(start,end);
                vm.getImoonData(start,end);
                vm.getVirtualNewData(start,end);

                this.$refs.vuetable.reload();
                this.$refs.vuetableq.reload();


            },


            getsummaryData: function (start,end) {


                var vm = this;

                this.loading1=true;

                $.get({
                    url: config.baseURL+'dash/summary?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {



                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.deposit=formatNumber(json[key].deposit);
                                vm.depositors=formatNumber(json[key].depositors);
                                vm.withdraw=formatNumber(json[key].withdraw);
                                vm.withdrawals=formatNumber(json[key].withdrawals);
                                vm.bets=formatNumber(json[key].bets);
                                vm.virtual_bet=formatNumber(json[key].virtual_bet);
                                vm.kiron_bet=formatNumber(json[key].kiron_bet);
                                vm.pnl=formatNumber(json[key].pnl);
                                vm.virtual_pnl=formatNumber(json[key].virtual_pnl);
                                vm.kiron_pnl=formatNumber(json[key].kiron_pnl);
                                vm.stake=formatNumber(json[key].stake);
                                vm.virtual_stake=formatNumber(json[key].virtual_stake);
                                vm.kiron_stake=formatNumber(json[key].kiron_stake);
                                vm.acquisition=formatNumber(json[key].acquisition);
                                vm.dropoffs=formatNumber(json[key].dropoffs);
                                vm.winnings=formatNumber(json[key].winnings);

                                vm.virtual_winnings=formatNumber(json[key].virtual_winnings);
                                vm.kiron_winnings=formatNumber(json[key].kiron_winnings);
                                vm.pending=formatNumber(json[key].pending);
                                vm.sms_bets=formatNumber(json[key].sms_bets);

                                vm.jackpot_bets=formatNumber(json[key].jackpot_bets);
                                vm.jackpot_stake=formatNumber(json[key].jackpot_stake);
                                vm.jackpot_players=formatNumber(json[key].jackpot_players);

                                var b = Math.floor(json[key].bets); //floor aut
                                var p = Math.floor(json[key].pending); //floor aut
                                var r= b-p;

                                vm.percent= Math.round(r/b * 100);



                                 vm.cashout_possible_win=formatNumber(json[key].cashout_possible_win);
                                 vm.cashout_amount=formatNumber(json[key].cashout_amount);
                                 vm.cashout_stake=formatNumber(json[key].cashout_stake);

                                var cs = Math.floor(json[key].cashout_stake);
                                var ca = Math.floor(json[key].cashout_amount);
                                var cpnl= cs-ca;
                                vm.cashout_pnl= cpnl;

                                this.loading1=false;

                            }
                        }


                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },

            getbetshareablebetsData: function (start,end) {


                var vm = this;


                $.get({
                    url: config.baseURL+'dash/shareable/bets?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.shareable_bets=formatNumber(json[key].bets);
                                vm.shareable_profiles=formatNumber(json[key].profiles);

                            }
                        }


                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },


            getgoldenraceData: function (start,end) {


                var vm = this;


                $.get({
                    url: config.baseURL+'dash/grreport?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.gr_profiles=formatNumber(json[key].profiles);
                                vm.gr_bets=formatNumber(json[key].bets);
                                vm.gr_stake=formatNumber(json[key].stake);
                                vm.gr_winnings=formatNumber(json[key].winnings);



                                vm.gr_pnl=formatNumber(Math.floor(json[key].stake)-Math.floor(json[key].winnings));

                            }
                        }


                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },

          getInstantData: function (start,end) {


            var vm = this;


            $.get({
              url: config.baseURL+'dash/instant/report?start='+start+'&end='+end+'&token='+vm.token,
              type: "GET",
              success: function (response) {


                vm.summary = response;

                var json=vm.summary


                for (var key in json) {
                  if (json.hasOwnProperty(key)) {

                    vm.loading1=false;
                    vm.instant_profiles=formatNumber(json[key].profiles);
                    vm.instant_bets=formatNumber(json[key].bets);
                    vm.instant_stake=formatNumber(json[key].stake);
                    vm.instant_winnings=formatNumber(json[key].winnings);



                    vm.instant_pnl=formatNumber(Math.floor(json[key].stake)-Math.floor(json[key].winnings));

                  }
                }


              },
              error: function () {

                vm.profile_loader = '';
                vm.profile_data = '';

              }
            })

          },

          getEvolutionData: function (start,end) {


            var vm = this;


            $.get({
              url: config.baseURL+'dash/evolution/report?start='+start+'&end='+end+'&token='+vm.token,
              type: "GET",
              success: function (response) {


                vm.summary = response;

                var json=vm.summary


                for (var key in json) {
                  if (json.hasOwnProperty(key)) {

                    vm.loading1=false;
                    vm.evolution_profiles=formatNumber(json[key].profiles);
                    vm.evolution_bets=formatNumber(json[key].bets);
                    vm.evolution_stake=formatNumber(json[key].stake);
                    vm.evolution_winnings=formatNumber(json[key].winnings);



                    vm.evolution_pnl=formatNumber(Math.floor(json[key].stake)-Math.floor(json[key].winnings));

                  }
                }


              },
              error: function () {

                vm.profile_loader = '';
                vm.profile_data = '';

              }
            })

          },



          getImoonData: function (start,end) {


                var vm = this;


                $.get({
                    url: config.baseURL+'dash/imoonreport?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.imoon_profiles=formatNumber(json[key].profiles);
                                vm.imoon_bets=formatNumber(json[key].bets);
                                vm.imoon_stake=formatNumber(json[key].stake);
                                vm.imoon_winnings=formatNumber(json[key].winnings);



                                vm.imoon_pnl=formatNumber(Math.floor(json[key].stake)-Math.floor(json[key].winnings));

                            }
                        }


                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },


          getVirtualNewData: function (start,end) {

            var vm = this;

            $.get({
              url: config.baseURL+'virtual/new/report?start='+start+'&end='+end+'&token='+vm.token,
              type: "GET",
              success: function (response) {


                vm.summary = response;

                var json=vm.summary


                for (var key in json) {
                  if (json.hasOwnProperty(key)) {

                    vm.loading1=false;


                    vm.virtualnew_profiles=formatNumber(json[key].profiles);
                    vm.virtualnew_winnings=formatNumber(json[key].bets);
                    vm.virtualnew_stake=formatNumber(json[key].stake);
                    vm.virtualnew_winnings=formatNumber(json[key].winnings);


                    vm.virtualnew_pnl=formatNumber(Math.floor(json[key].stake)-Math.floor(json[key].winnings));

                  }
                }


              },
              error: function () {

                vm.profile_loader = '';
                vm.profile_data = '';

              }
            })

          },

            getAviatrixData: function (start,end) {


            var vm = this;


            $.get({
              url: config.baseURL+'dash/aviatrixreport?start='+start+'&end='+end+'&token='+vm.token,
              type: "GET",
              success: function (response) {


                vm.summary = response;

                var json=vm.summary


                for (var key in json) {
                  if (json.hasOwnProperty(key)) {

                    vm.loading1=false;
                    vm.aviatrix_profiles=formatNumber(json[key].profiles);
                    vm.aviatrix_bets=formatNumber(json[key].bets);
                    vm.aviatrix_stake=formatNumber(json[key].stake);
                    vm.aviatrix_winnings=formatNumber(json[key].winnings);



                    vm.aviatrix_pnl=formatNumber(Math.floor(json[key].stake)-Math.floor(json[key].winnings));

                  }
                }


              },
              error: function () {

                vm.profile_loader = '';
                vm.profile_data = '';

              }
            })

          },


          getSmartsoftData: function (start,end) {


            var vm = this;
            $.get({
              url: config.baseURL+'dash/smartsoft?start='+start+'&end='+end+'&token='+vm.token,
              type: "GET",
              success: function (response) {


                vm.summary = response;

                var json=vm.summary


                for (var key in json) {
                  if (json.hasOwnProperty(key)) {

                    vm.loading1=false;
                    vm.smartsoft_profiles=formatNumber(json[key].profiles);
                    vm.smartsoft_bets=formatNumber(json[key].bets);
                    vm.smartsoft_stake=formatNumber(json[key].stake);
                    vm.smartsoft_winnings=formatNumber(json[key].winnings);



                    vm.smartsoft_pnl=formatNumber(Math.floor(json[key].stake)-Math.floor(json[key].winnings));

                  }
                }


              },
              error: function () {

                vm.profile_loader = '';
                vm.profile_data = '';

              }
            })

          },


            getappsData: function (start,end) {


                var vm = this;


                $.get({
                    url: config.baseURL+'dash/app/downloads?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.app_profiles=formatNumber(json[key].profiles);

                            }
                        }


                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },




            getcasinoData: function (start,end) {


                var vm = this;


                $.get({
                    url: config.baseURL+'casino/summary?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.casiono_bets=formatNumber(json[key].bets);
                                vm.casiono_bet_amount=formatNumber(json[key].bet_amount);
                                vm.casiono_won=formatNumber(json[key].won);

                                vm.casiono_pnl= formatNumber((json[key].bet_amount-json[key].won).toFixed(0));

                            }
                        }

                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },

            getzozaData: function (start,end) {


                var vm = this;


                $.get({
                    url: config.baseURL+'zoza/summary?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.zoza_profiles=formatNumber(json[key].profiles);
                                vm.zoza_bets=formatNumber(json[key].bets);
                                vm.zoza_bet_amount=formatNumber(json[key].bet_amount);

                            }
                        }

                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },



            getecasinoData: function (start,end) {


                var vm = this;


                $.get({
                    url: config.baseURL+'elbet_bet/summary?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.casiono_ebets=formatNumber(json[key].bets);
                                vm.casiono_ebet_amount=formatNumber(json[key].bet_amount);
                                vm.casiono_ewon=formatNumber(json[key].won);

                                vm.casiono_epnl= formatNumber((json[key].bet_amount-json[key].won).toFixed(0));

                            }
                        }

                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },


            getpcasinoData: function (start,end) {


                var vm = this;


                $.get({
                    url: config.baseURL+'pragmatic_bet/summary?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.casiono_pbets=formatNumber(json[key].bets);
                                vm.casiono_pbet_amount=formatNumber(json[key].bet_amount);
                                vm.casiono_pwon=formatNumber(json[key].won);

                                vm.casiono_ppnl= formatNumber((json[key].bet_amount-json[key].won).toFixed(0));

                            }
                        }

                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },


            getVirtualBonusData: function (start,end) {


                var vm = this;


                $.get({
                    url: config.baseURL+'virtual/bonus?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.virtual_bonus_total=formatNumber(json[key].total);
                                vm.virtual_bonus_unclaimed=formatNumber(json[key].unclaimed);
                                vm.virtual_bonus_claimed=formatNumber(json[key].claimed);

                            }
                        }


                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },

            getstpData: function (start,end) {


                var vm = this;

                $.get({
                    url: config.baseURL+'stp/summary?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.stp_bets=formatNumber(json[key].bets);
                                vm.stp_stake=formatNumber(json[key].stake);
                                vm.stp_winnings=formatNumber(json[key].winnings);

                                var b = Math.floor(json[key].stake); //floor aut
                                var p = Math.floor(json[key].winnings); //floor aut
                                var r= b-p;

                                vm.stp_pnl=formatNumber(r);

                            }
                        }

                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },

            getbetshareablebetstakeData: function (start,end) {

                var vm = this;
                $.get({
                    url: config.baseURL+'dash/shareable/stake?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.shareable_stake=formatNumber(json[key].stake);


                            }
                        }


                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },

            getmbogiData: function (start,end) {


                var vm = this;


                $.get({
                    url: config.baseURL+'mbogi/summary?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {




                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.total_referal=formatNumber(json[key].total);
                                vm.total_redeemed=formatNumber(json[key].redeamed);
                                vm.total_stake=formatNumber(json[key].stake);



                            }
                        }


                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },


            getmasterbetsData: function (start,end) {


                var vm = this;


                $.get({
                    url: config.baseURL+'portal/tickets/summary?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.total_tickets=json[key].total;
                                vm.total_closed=json[key].closed_ticket;
                                vm.total_open=json[key].open_ticket;



                            }
                        }


                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },


            getEuroJackpotData: function (start,end) {

                var vm = this;

                this.loading1=true;


                $.get({
                    url: config.baseURL+'euro/summary?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {
                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.midweek_bets=formatNumber(json[key].bets);
                                vm.midweek_stake=formatNumber(json[key].stake);
                                vm.midweek_profiles=formatNumber(json[key].profiles);

                                this.loading1=false;

                            }
                        }


                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },


            getdailyJackpotData: function (start,end) {


                var vm = this;

                this.loading1=true;


                $.get({
                    url: config.baseURL+'daily/summary?start='+start+'&end='+end+'&token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.summary = response;

                        var json=vm.summary


                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {

                                vm.loading1=false;
                                vm.daily_bets=formatNumber(json[key].bets);
                                vm.daily_stake=formatNumber(json[key].stake);
                                vm.daily_profiles=formatNumber(json[key].profiles);

                                this.loading1=false;

                            }
                        }


                    },
                    error: function () {

                        vm.profile_loader = '';
                        vm.profile_data = '';

                    }
                })

            },


            getliability: function () {


                var vm = this;


                var data = {

                };
                $.get({
                    url: config.baseURLWEB+'dash/api/total/liability?token='+ vm.token,
                    type: "GET",
                    data: JSON.stringify(data),
                    success: function (response) {

                        vm.liability= response;


                        var json=vm.liability

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.balance=formatNumber(json[key].balance);

                            }
                        }

                    },
                    error: function () {


                    }
                })

            },
            getpointsliability: function () {
                var vm = this;

                $.get({
                    url: config.baseURLWEB+'dash/api/total/points?token='+vm.token,
                    type: "GET",
                    success: function (response) {

                        var points= response;


                        var json=points

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.points=formatNumber(json[key].balance);

                            }
                        }

                    },
                    error: function () {


                    }
                })

            },


            getliabilitysta: function () {
                var vm = this;


                $.get({
                    url: config.baseURLWEB+'dash/api/liability?token='+vm.token,
                    type: "GET",
                    success: function (response) {
                        vm.liabilityy= response;


                        var json=vm.liabilityy

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.active=formatNumber(json[key].active);
                                vm.last_3=formatNumber(json[key].last_3);
                                vm.last_5=formatNumber(json[key].last_5);
                                vm.last_year=formatNumber(json[key].last_year);

                            }
                        }
                    },
                    error: function () {


                    }
                })

            },


            getliability1: function () {
                var vm = this;

                $.get({
                    url: config.baseURLWEB+'dash/api/liability1?token='+vm.token,
                    type: "GET",
                    success: function (response) {

                        vm.liability1= response;


                        var json=vm.liability1

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.total1=formatNumber(json[key].total);
                                vm.balance1=formatNumber(json[key].numbers);


                            }
                        }
                    },
                    error: function () {


                    }
                })

            },


            getliability2: function () {
                var vm = this;

                $.get({
                    url: config.baseURLWEB+'dash/api/liability2?token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.liability2= response;


                        var json=vm.liability2

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.total2=formatNumber(json[key].total);
                                vm.balance2=formatNumber(json[key].numbers);


                            }
                        }

                    },
                    error: function () {


                    }
                })

            },


            getliability3: function () {
                var vm = this;

                $.get({
                    url: config.baseURLWEB+'dash/api/liability3?token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.liability3= response;


                        var json=vm.liability3

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.total3=formatNumber(json[key].total);
                                vm.balance3=formatNumber(json[key].numbers);


                            }
                        }

                    },
                    error: function () {


                    }
                })

            },


            getliability4: function () {
                var vm = this;

                $.get({
                    url: config.baseURLWEB+'dash/api/liability4?token='+vm.token,
                    type: "GET",
                    success: function (response) {


                        vm.liability4= response;


                        var json=vm.liability4

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.total4=formatNumber(json[key].total);
                                vm.balance4=formatNumber(json[key].numbers);


                            }
                        }

                    },
                    error: function () {


                    }
                })

            },


            getliability5: function () {
                var vm = this;
                $.get({
                    url: config.baseURLWEB+'dash/api/liability5?token=' + vm.token,
                    type: "GET",
                    success: function (response) {
                        vm.liability5= response;


                        var json=vm.liability5

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.total5=formatNumber(json[key].total);
                                vm.balance5=formatNumber(json[key].numbers);


                            }
                        }

                    },
                    error: function () {


                    }
                })

            },


            getliability6: function () {
                var vm = this;
                $.get({
                    url: config.baseURLWEB+'dash/api/liability?token=' + vm.token,
                    type: "GET",
                    success: function (response) {
                        vm.liability6= response;


                        var json=vm.liability6

                        for (var key in json) {
                            if (json.hasOwnProperty(key)) {
                                vm.total6=formatNumber(json[key].total);
                                vm.balance6=formatNumber(json[key].numbers);


                            }
                        }

                    },
                    error: function () {


                    }
                })

            },

            onPaginationData(paginationData) {
                this.$refs.pagination.setPaginationData(paginationData);
                this.$refs.paginationInfo.setPaginationData(paginationData);

            },
            onChangePage(page) {
                this.$refs.vuetable.changePage(page)

            },




            onPaginationDataa(paginationData) {
                this.$refs.paginationn.setPaginationData(paginationData);
                this.$refs.paginationInfoo.setPaginationData(paginationData);

            },
            onChangePagee(page) {
                this.$refs.vuetableq.changePage(page)

            },
            onLoading() {
                this.loading = true

            },
            onLoaded() {
                this.loading = false

            },

        },


        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.reload();
            },
            range(){

                var start = this.range[0];
                var end = this.range[1];
                start= moment(start).format('YYYY-MM-DD');
                end= moment(end).format('YYYY-MM-DD');
                this.moreParams.start = start;
                this.moreParams.end = end;

                this.moreParams1.start = start;
                this.moreParams1.end = end;
                this.moreParams.token = this.token;
                this.moreParams1.token = this.token;
                this.getsummaryData(start,end);
                this.getVirtualBonusData(start,end);
                this.getmbogiData(start,end);
                this.getEuroJackpotData(start,end);
                this.getdailyJackpotData(start,end);
                this.getcasinoData(start,end);
                this.getzozaData(start,end);
                this.getecasinoData(start,end);
                this.getpcasinoData(start,end);
                this.getappsData(start,end);

                this.getbetshareablebetsData(start,end);
                this.getgoldenraceData(start,end);
                this.getInstantData(start,end);
                this.getEvolutionData(start,end);
                this.getImoonData(start,end);
                this.getVirtualNewData(start,end);
                this.getAviatrixData(start,end);
                this.getSmartsoftData(start,end);
                this.getbetshareablebetstakeData(start,end);
                this.getmasterbetsData(start,end);
                this.getstpData(start,end);


            },
        },


    }
</script>